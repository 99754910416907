import { API_URL } from "../sharedService/constants";
import { http } from "../sharedService/httpService";

export function createAdminSetting(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/AdminSetting/Create`,
    reqBody
  );
}

export function getOrganizationList() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/Organization/List`);
}

export function getSettingList() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/AdminSetting/List`);
}

export function updateSetting(id: string, reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/AdminSetting/${id}`,
    reqBody
  );
}

export function getDasboard(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/GetEmployeeDashboard?startDate=${reqBody.startDate}&endDate=${reqBody.endDate}`
  );
}

export function getProject(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/AdminProjectsDashboard?project=${reqBody.project}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`
  );
}

export function createProject(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/Create`,
    reqBody
  );
}
export function deleteProject(Id: string) {
  return http.delete(`${API_URL.INTEGRATION_API_URL}api/CustomProject/${Id}`);
}

export function updateProject(id: string, reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/${id}`,
    reqBody
  );
}
export function getProjectById(SearchString: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/List?SearchString=${SearchString}`
  );
}

export function createTask(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/CustomTask/Create`,
    reqBody
  );
}

export function getTask(reqBody: any) {
  var nevUrl;
  if (reqBody.searchString === undefined || reqBody.searchString === "") {
    nevUrl = `api/CustomTask/List?Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&ProjectId=${reqBody.ProjectId}&ListType=${reqBody.listType}`;
  } else {
    nevUrl = `api/CustomTask/List?SearchString=${reqBody.searchString}&ListType=${reqBody.listType}&ProjectId=${reqBody.ProjectId}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function updateTask(id: string, reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/CustomTask/${id}`,
    reqBody
  );
}

export function closeTask(id: string) {
  return http.put(`${API_URL.INTEGRATION_API_URL}api/CustomTask/Close/${id}`);
}

export function deleteTask(Id: string) {
  return http.delete(`${API_URL.INTEGRATION_API_URL}api/CustomTask/${Id}`);
}

export function checkUniqueness(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomTask/CheckDuplicacy?propertyName=${reqBody.propertyName}&checkValue=${reqBody.checkValue}&projectId=${reqBody.projectId}`
  );
}

export function checkUniquenessProject(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/CheckDuplicacy?propertyName=${reqBody.propertyName}&checkValue=${reqBody.checkValue}`
  );
}

export function keySuggesstion(projectName: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/KeySuggestion/${projectName}`
  );
}

export function getTeamMemberList(reqBody: any) {
  var nevUrl;
  if (reqBody.SearchString === undefined || reqBody.SearchString === "") {
    nevUrl = `api/TeamMember/GetTaskMembers?TaskId=${reqBody.TaskId}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&Team=${reqBody.Team}&IsOrderByDesc=${reqBody.IsOrderByDesc}`;
  } else {
    nevUrl = `api/TeamMember/GetTaskMembers?TaskId=${reqBody.TaskId}&Team=${reqBody.Team}&SearchString=${reqBody.SearchString}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getTaskById(taskId: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomTask/GetTask?taskId=${taskId}`
  );
}

export function getProjects(projectId: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/GetProject?projectId=${projectId}`
  );
}

export function getProjectTeamMemberList(reqBody: any) {
  var nevUrl;
  if (reqBody.SearchString === undefined || reqBody.SearchString === "") {
    nevUrl = `api/TeamMember/GetProjectMembers?ProjectId=${reqBody.ProjectId}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&Team=${reqBody.Team}&IsOrderByDesc=${reqBody.IsOrderByDesc}`;
  } else {
    nevUrl = `api/TeamMember/GetProjectMembers?ProjectId=${reqBody.ProjectId}&Team=${reqBody.Team}&SearchString=${reqBody.SearchString}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function lockProject(id: string) {
  return http.put(`${API_URL.INTEGRATION_API_URL}api/CustomProject/Lock/${id}`);
}

export function getReport(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/AdminSetting/Report?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}`
  );
}

export function unLockProject(id: string) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/CustomProject/Unlock/${id}`
  );
}

export function getEnumList(value: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/AdminSetting/${value}/Options`
  );
}
