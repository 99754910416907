import React from "react";
import emptyOtherImage from "../../images/emptyOther.png";
import Typography from "../../components/Typography/Typography";

const IntegrationEmpty = () => {
  return (
    <div className="border border-gray-100 shadow-300 p-8 bg-white rounded-md relative h-[calc(100vh-11.75rem)] overflow-auto flex justify-center items-center text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={emptyOtherImage}
            alt="emptyOtherImage"
            className="w-[6.563rem] h-[6.563rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          No Integrations Yet.Your <br></br>Integrations will be listed here.
        </Typography>
      </div>
    </div>
  );
};

export default IntegrationEmpty;
