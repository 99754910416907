import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "../../components/DropDown/DropDown";
import TextInput from "../../components/TextInput/TextInput";
import {
  createIntegrationAsync,
  getIntegrationKeyListAsync,
} from "../../services/reducers/appReducer";
import { useAppDispatch } from "../../hooks";
import { useDebounce } from "../../utils/hooks/useDebounce";

type Props = {
  onClose: () => void;
  setAddIntegration: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => void;
  userDetail: any;
};

type IntegrationRequest = {
  baseUrl: string;
  apiValue: string;
  platformId: number;
  organizationId: string;
};

const AddIntegrationModal: React.FC<Props> = ({
  onClose,
  setAddIntegration,
  getList,
  userDetail,
}) => {
  const dispatch = useAppDispatch();
  const [isValidate, setIsValidate] = useState(false);
  const [dynamicBaseUrl, setDynamicBaseUrl] = useState("");

  const validationSchema = Yup.object({
    apiValue: Yup.string().required("API Value is required"),
    platformId: Yup.number().required("Platform ID is required"),
    organizationId: Yup.string().required("Organization ID is required"),
  });

  const validateKey = async (formValues: any) => {
    const jsonData = {
      BaseUrl: encodeURIComponent(dynamicBaseUrl || formValues.baseUrl),
      ApiValue: formValues.apiValue,
      PlatformId: formValues.platformId,
    };

    await dispatch(getIntegrationKeyListAsync(jsonData)).then((res) => {
      setIsValidate(res?.payload);
    });
  };

  const formik = useFormik({
    initialValues: {
      baseUrl: "",
      apiValue: "",
      platformId: 0,
      organizationId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (isValidate) {
        const formValues = {
          ...values,
          baseUrl: dynamicBaseUrl || values.baseUrl,
        };

        const request: IntegrationRequest = {
          baseUrl: formValues.baseUrl,
          apiValue: formValues.apiValue,
          platformId: formValues.platformId,
          organizationId: formValues.organizationId,
        };
        dispatch(createIntegrationAsync(request)).then((res) => {
          getList();
        });
        setAddIntegration(false);
      }
    },
  });

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values]);

  const debouncedValidate = useDebounce(validateKey, 1000);
  useEffect(() => {
    if (formik.values.platformId === 1) {
      setDynamicBaseUrl("https://api.clickup.com/api/v2/");
    } else {
      setDynamicBaseUrl("");
    }
  }, [formik.values.platformId]);

  const handlePlatformChange = (selectedOption: any) => {
    formik.setFieldValue("platformId", Number(selectedOption.value));
  };

  return (
    <>
      <Modal isOpen onClose={onClose} size="medium" placement="right">
        <ModalHeader className="bg-gray-200">
          <Typography className="font-bold" variant="h3">
            Complete Integration
          </Typography>
        </ModalHeader>
        <ModalBody className="px-20 py-12">
          <div className="grid grid-cols-2 gap-x-5 gap-y-8">
            <div className="w-[25.313rem]">
              <label className="text-400 font-medium text-gray-300 block mb-2">
                Platform Name
              </label>
              <Dropdown
                onChange={handlePlatformChange}
                options={[
                  { label: "Jira", value: 0 },
                  { label: "Click Up", value: 1 },
                ]}
                type="box"
                isSearchable={true}
                placeholder="Select Platform"
              />
              {formik.touched.platformId && formik.errors.platformId && (
                <div className="text-primary-100 text-300 font-medium">
                  {formik.errors.platformId}
                </div>
              )}
            </div>
            <div className="w-[25.313rem]">
              <label className="text-400 font-medium text-gray-300 block mb-2">
                Organization Id
              </label>
              <Dropdown
                onChange={(selectedOption) => {
                  if (Array.isArray(selectedOption)) {
                    const selectedValues = selectedOption.map(
                      (option) => option.value
                    );
                    formik.setFieldValue("organizationId", selectedValues);
                  } else {
                    formik.setFieldValue(
                      "organizationId",
                      selectedOption?.value || ""
                    );
                  }
                }}
                options={userDetail?.map((org: any) => ({
                  label: org.name,
                  value: org.id,
                }))}
                type="box"
                isSearchable={true}
                placeholder="Select Organization Name"
              />
              {formik.touched.organizationId &&
                formik.errors.organizationId && (
                  <div className="text-primary-100 text-300 font-medium">
                    {formik.errors.organizationId}
                  </div>
                )}
            </div>
            <div className="w-[25.313rem]">
              <label className="text-400 font-medium text-gray-300 block mb-2">
                Base URL
              </label>
              <TextInput
                name="baseUrl"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Enter base Url"
                type="text"
                value={dynamicBaseUrl || formik.values.baseUrl}
              />
              {formik.touched.baseUrl && formik.errors.baseUrl && (
                <div className="text-primary-100 text-300 font-medium">
                  {formik.errors.baseUrl}
                </div>
              )}
            </div>
            <div className="w-[25.313rem]">
              <label className="text-400 font-medium text-gray-300 block mb-2">
                API Token
              </label>
              <TextInput
                name="apiValue"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Enter API Token"
                type="text"
                value={formik.values.apiValue}
              />
              {formik.touched.apiValue && formik.errors.apiValue && (
                <div className="text-primary-100 text-300 font-medium mt-2">
                  {formik.errors.apiValue}
                </div>
              )}
            </div>
          </div>
          {isValidate === false && (
            <div className="text-primary-100 text-300 font-medium mt-8">
              Please Double-Check Platform Name, Base URL, and API Token for
              Accuracy.
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-start items-center gap-5">
            <Button
              label="Integrate"
              isDisabled={!isValidate}
              onClick={formik.handleSubmit}
              variant="primary"
              size="large"
            />
            <Button
              label="Cancel"
              onClick={() => setAddIntegration(false)}
              variant="outline"
              size="large"
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddIntegrationModal;
