import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { convertMinutesToHoursAndMinutes } from "../../utils/common";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getNoteListAsync,
  removeNoteAsync,
} from "../../services/reducers/timeReducer";

type Props = {
  onClose: () => void;
  deleteItemId: string;
  getNote: string;
  getTimeLoged: number;
  timeSheetIds: string;
  getDailyList: () => void;
};

const ClearNoteConfirmModal: React.FC<Props> = ({
  onClose,
  deleteItemId,
  getNote,
  getTimeLoged,
  timeSheetIds,
  getDailyList,
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    if (deleteItemId) {
      await dispatch(removeNoteAsync(deleteItemId));
      dispatch(getNoteListAsync(timeSheetIds));
    }
    onClose();
    getDailyList();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="normal"
      placement="center"
      className="z-[9999]"
    >
      <ModalBody className="!pt-10 !pb-14 !ps-10 !pe-8">
        <div>
          <Typography variant="h3" className="font-bold text-gray-300">
            Are you sure you want to delete this note?
          </Typography>
          <div className="mt-5">
            <div className="flex justify-between items-center mt-2.5">
              <div className="text-500 text-gray-700 font-normal w-[24.375rem] line-clamp-2">
                {getNote}{" "}
              </div>
              <div className="h-[2.813rem] w-[5rem] border border-gray-400 rounded-md flex justify-center items-center bg-gray-1000 hover:border-secondary-100 cursor-pointer">
                <div className="text-400 font-bold text-gray-300 outline-0">
                  {convertMinutesToHoursAndMinutes(getTimeLoged)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="bg-gray-200 !px-10">
        <div className="flex justify-end items-center w-full gap-3">
          <Button
            label="Cancel"
            onClick={onClose}
            variant="outline"
            size="large"
          />
          <Button
            label="Yes, I am sure"
            onClick={handleDelete}
            variant="primary"
            size="large"
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ClearNoteConfirmModal;
