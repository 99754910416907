import moment from "moment";

export function convertHourMinuteToMinutes(timeString: any): number {
  // Convert the timeString to lowercase for case-insensitivity
  const ts = timeString as String;
  const lowercaseTimeString = ts.toLowerCase();

  // Use regular expressions to extract hours and minutes allowing spaces
  const matches = lowercaseTimeString.match(/(\d+\s*h)?(\d+\s*m)?/);

  if (!matches || matches.length !== 3) {
    //throw new Error('Invalid time format. Use the format 1h20m.');
    return 0;
  }

  // Extract hours and minutes from the matched groups
  const hours = matches[1] ? parseInt(matches[1]) : 0;
  const minutes = matches[2] ? parseInt(matches[2]) : 0;

  // Convert everything to minutes and return the total
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
}

export function convertMinutesToHoursAndMinutes(minutes: any) {
  if (minutes < 0) {
    const hours = Math.floor(Math.abs(minutes) / 60);
    const remainingMinutes = Math.abs(minutes) % 60;
    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(remainingMinutes).padStart(2, "0");
    return `-${paddedHours}:${paddedMinutes}`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(remainingMinutes).padStart(2, "0");
  return `${paddedHours}:${paddedMinutes}`;
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function convertMinutesToHoursAndMinutes2(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const remainingMinutes = Math.ceil(totalMinutes % 60);

  let result = "";
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (remainingMinutes > 0) {
    result += `${remainingMinutes}m`;
  }

  return result.trim();
}
export function formatTime(hours: number) {
  const roundedHours = Math.floor(hours);
  const minutes = Math.round((hours - roundedHours) * 60);

  let timeString = "";

  if (roundedHours > 0) {
    timeString += `${roundedHours}h`;
  }

  if (minutes > 0) {
    timeString += ` ${minutes}m`;
  }

  return timeString;
}

export const getActiveMenu = (path: string) => {
  switch (path) {
    case "":
      return "Dashboard";
    case "teams":
      return "Team Dashboard";
    case "timeTracker":
      return "Time Tracker";
    case "projects":
      return "Projects";
    case "projects/:id":
      return "Tasks";
    case "screenshots":
      return "Screenshots";
    case "timeoff":
      return "Time Off";
    case "settings":
      return "Settings";

    default:
      return "Dashboard";
  }
};

export const convertToAMPM = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(":");
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  return `${hour}:${minutes} ${ampm}`;
};

export const formatAMPM = (hour: number) => {
  const meridiem = hour >= 12 ? "PM" : "AM";
  const formattedHours = hour % 12 === 0 ? 12 : hour % 12;
  const formattedTime = `${formattedHours}:00 ${meridiem}`;

  return formattedTime;
};

export const addTimeAndFormat = (
  timeString: string,
  hoursToAdd: number,
  minutesToAdd: number
) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  let newHours = hours + hoursToAdd;
  let newMinutes = minutes + minutesToAdd;
  if (newMinutes >= 60) {
    newHours += Math.floor(newMinutes / 60);
    newMinutes %= 60;
  }
  newHours %= 24;
  const meridiem = newHours >= 12 ? "PM" : "AM";
  const formattedHours = newHours % 12 === 0 ? 12 : newHours % 12;
  const formattedTime = `${formattedHours
    .toString()
    .padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")} ${meridiem}`;

  return formattedTime;
};

export const dateConverter = (dateString: string) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);

  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const providedDay = date.getDate();
  const providedMonth = date.getMonth();
  const providedYear = date.getFullYear();

  if (
    currentDay === providedDay &&
    currentMonth === providedMonth &&
    currentYear === providedYear
  ) {
    return `Today, ${providedDay} ${months[providedMonth]} ${providedYear} `;
  } else {
    const dayOfWeek = days[date.getDay()];
    return `${dayOfWeek}, ${providedDay} ${months[providedMonth]} ${providedYear}`;
  }
};

export const getDayAndMonth = (dateString: string) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthName = monthNames[monthIndex];

  return { day, month: monthName };
};

export const formatDateMonth = (
  monthIndex: number,
  isEndMonth: boolean = false
): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = monthIndex % 12;
  const nextMonth = (monthIndex + 1) % 12;
  const lastDayOfMonth = new Date(year, nextMonth, 0).getDate();
  const paddedMonth = (month + 1).toString().padStart(2, "0");
  const day = isEndMonth ? lastDayOfMonth : "01";
  return `${year}-${paddedMonth}-${day}`;
};

export const formatDateMonthYear = (
  monthIndex: number,
  year: number,
  isEndMonth: boolean = false
): string => {
  const month = monthIndex % 12;
  const nextMonth = (monthIndex + 1) % 12;
  const lastDayOfMonth = new Date(year, nextMonth, 0).getDate();
  const paddedMonth = (month + 1).toString().padStart(2, "0");
  const day = isEndMonth ? lastDayOfMonth : "01";
  return `${year}-${paddedMonth}-${day}`;
};

export const formatRelativeTime = (timestamp: string): string => {
  const now = moment(); // Current time in local timezone
  const utcTimestamp = moment.utc(timestamp); // Convert input timestamp to UTC

  // Calculate the difference in seconds
  const secondsAgo = now.diff(utcTimestamp, "seconds");

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} minutes ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} hours ago`;
  } else if (secondsAgo < 2592000) {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} days ago`;
  } else {
    const monthsAgo = Math.floor(secondsAgo / 2592000);
    return `${monthsAgo} months ago`;
  }
};

export const TimeConverter = (utcTime:string) => {

  const date = new Date(`1970-01-01T${utcTime}Z`);

  // Get the time offset in milliseconds (IST is UTC+5:30)
  const istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;

  const istDate = new Date(date.getTime() + istOffset);

  let hours = istDate.getUTCHours();
  const minutes = istDate.getUTCMinutes();
  
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; 
  
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${hours}:${formattedMinutes} ${ampm}`;
}

export const  getItemPath = (identityId:string, notificationType : number, startDate:string, endDate:string, notificationId:string, notificationHolder:string, activeTab:string)=> {
  const defaultPath = '';

  const contentTypeToPath: { [key: number]: string } = {
    0: '/timeoff',
    1: notificationHolder === "E" ? '/timeTracker' : `/teams/${identityId}`,
    //0:'/timeTracker'
  };

  const path = contentTypeToPath[notificationType] || defaultPath;
  let calculatedActiveTab = '';
  if (path !== '') {
    if (path === '/timeoff' && notificationHolder === "E") {
      calculatedActiveTab = 'tab1';
    } else if (path === '/timeoff' && (notificationHolder === "A" || notificationHolder === "R" || notificationHolder === "ARM")) {
      calculatedActiveTab = 'tab3';
    } else if (path === '/timeTracker') {
      calculatedActiveTab = 'tab2';
    }
    return {
      pathname: path,
      state: {
        startDate: startDate,
        endDate: endDate,
        notificationId: notificationId,
        //activeTab:"tab3"
        activeTab: calculatedActiveTab
      },
    };
  } else {
    return {
      pathname: path,
      state: {}
    };
  }
};

export const convertUTCtoIST = (utcDateStr: string) => {
  const utcDate = new Date(utcDateStr);

  // IST offset in minutes (IST is UTC+5:30)
  const istOffset = 5.5 * 60;

  // Create a new Date object by adding the IST offset
  const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000);

  // Format the IST date
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Kolkata',
    hour12: true,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  const istDateString = istDate.toLocaleString('en-IN', options);

  return istDateString;
}

export const formatPushDate = (inputDateStr:string) => {
  // Create a Date object from the given input date string
  const date = new Date(inputDateStr);

  // Define month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Return formatted date string
  return `${month} ${day}, ${year}`;
}

