import React from "react";
import customProjectEmpty from "../../../images/customProjectEmpty.svg";
import Typography from "../../../components/Typography/Typography";

const CustomProjectEmpty = () => {
  return (
    <div className="flex justify-center items-center text-center w-full h-[calc(100vh-16rem)]">
      <div className="w-[18rem]">
        <div className="flex justify-center items-center">
          <img
            src={customProjectEmpty}
            alt="customProjectEmpty"
            className="w-[8.75rem] h-[8.75rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          Kickstart Your First Custom Project Here!
        </Typography>
      </div>
    </div>
  );
};

export default CustomProjectEmpty;
