import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../sharedService/constants";
import { updateTimeCard, createTimeCard, createTimesheet, deleteTimesheet, getJiraProject, getJiraTask, getList, getRecentProject, getRecentTask, getTimecardList, updateTimesheet, getNoteList, updateNote, removeNote, timeSheetSubmission } from "../api/timeSheetApis";

const initialState = {
  isLoading: false,
  isError: false,
  createTimesheet:{},
  getJira:{},
  getProject:{},
  getRecentTask:[],
  getRecentProj:[],
  getList:[],
  createTimecard:"",
  updateTimeCard:"",
  getNotes:[]
};

export const getJiraTaskAsync = createAsyncThunk(
    "timeSheet/getJiraTask",
    async (reqBody: any) => {
      const response = await getJiraTask(reqBody);
      return response.data;
    }
  );

  export const getJiraProjectAsync = createAsyncThunk(
    "timeSheet/getJiraTask",
    async () => {
      const response = await getJiraProject();
      return response.data;
    }
  );

  export const getRecentTaskAsync = createAsyncThunk(
    "timeSheet/getJiraTask",
    async (request: any) => {
      const response = await getRecentTask(request);
      return response.data;
    }
  );

  export const getRecentProjectAsync = createAsyncThunk(
    "timeSheet/getJiraTask",
    async (request: any) => {
      const response = await getRecentProject(request);
      return response.data;
    }
  );

  export const getListAsync = createAsyncThunk(
    "timeSheet/getJiraTask",
    async (request: any) => {
      const response = await getList(request);
      return response.data;
    }
  );
  
export const createTimesheetAsync = createAsyncThunk(
  "appintegration/createTimesheet",
  async (request: any) => {
      const response = await createTimesheet(request);
      return response.data;
  }
);

// export const deleteTimesheetAsync = createAsyncThunk(
//     "timesheet/delete",
//     async ({ identifier, weekStartDate, weekEndDate, memberId }: { identifier: string, weekStartDate: string, weekEndDate: string, memberId:string }) => {
//       const response = await deleteTimesheet(identifier, weekStartDate, weekEndDate, memberId);
//       return response.data;
//     }
//   );
export const deleteTimesheetAsync = createAsyncThunk(
  "timesheet/delete",
  async (request:any) => {
    const response = await deleteTimesheet(request);
    return response.data;
  }
);
  export const updateTimesheetAsync = createAsyncThunk(
    'timesheet/update',
    async (reqBody: any) => {
      const response = await updateTimesheet(reqBody);
      // console.log(response.data, "data");
      return response.data;
    }
  );

export const createTimeCardAsync = createAsyncThunk(
  "timeCard/createTimeCard",
  async () => {
      const response = await createTimeCard();
      return response.data;
  }
);

export const updateTimesCardAsync = createAsyncThunk(
  'timecard/update',
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await updateTimeCard(id, reqBody);
    // console.log(response.data, "data");
    return response.data;
  }
);

export const getTimecardListAsync = createAsyncThunk(
  "timeCard/getTimecardList",
  async () => {
    const response = await getTimecardList();
    return response.data;
  }
);

export const getNoteListAsync = createAsyncThunk(
  "timeSheet/getNoteList",
  async (timeSheetId: any) => {
    const response = await getNoteList(timeSheetId);
    return response.data;
  }
);

export const updateNoteAsync = createAsyncThunk(
  'timesheet/updateNote',
  async (reqBody: any) => {
    const response = await updateNote(reqBody);
    return response.data;
  }
);

export const removeNoteAsync = createAsyncThunk(
  'timesheet/removeNote',
  async (noteId: string) => {
    const response = await removeNote(noteId);
    return response.data;
  }
);

export const timeSheetSubmissionAsync = createAsyncThunk(
  'timesheet/timeSheetSubmission',
  async (reqBody: any) => {
    const response = await timeSheetSubmission(reqBody);
    return response.data;
  }
);
const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTimesheetAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createTimesheetAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createTimesheet = action.payload
        toast.success('Add Timesheet has been successfully!', TOASTIFY_CONF);
      })
      .addCase(createTimesheetAsync.rejected, (state, action) => {
        // console.log('Rejected Action:', action);
        //toast.error(`Add Timesheet, Something went wrong: ${action.payload}`, TOASTIFY_CONF);
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getJiraTaskAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getJiraTaskAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getJira = action.payload
        
      })
      .addCase(getJiraTaskAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getRecentTaskAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRecentTaskAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getRecentTask = action.payload
        
      })
      .addCase(getRecentTaskAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getJiraProjectAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getJiraProjectAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getProject = action.payload
        
      })
      .addCase(getJiraProjectAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getRecentProjectAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRecentProjectAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getRecentProj = action.payload
        
      })
      .addCase(getRecentProjectAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getListAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getList = action.payload
        
      })
      .addCase(getListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(createTimeCardAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createTimeCardAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createTimecard = action.payload
        toast.success('Add Integration has been successfully!', TOASTIFY_CONF);
      })
      .addCase(createTimeCardAsync.rejected, (state, action) => {
        toast.error(`Add Integration, Something went wrong: ${action.payload}`, TOASTIFY_CONF);
        state.isLoading = false;
        state.isError = true;
        
      });

      builder
      .addCase(getNoteListAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getNoteListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getNotes = action.payload
      })
      .addCase(getNoteListAsync.rejected, (state, action) => {
        toast.error(`Add Integration, Something went wrong: ${action.payload}`, TOASTIFY_CONF);
        state.isLoading = false;
        state.isError = true;
        
      });

  },
});

export default timesheetSlice.reducer;
