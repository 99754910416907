import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./views/dashboard/Dashboard";
import Settings from "./views/Settings/Settings";
import TimeTracker from "./views/TimeTracker/TimeTracker";
import Projects from "./views/admin/projects/Projects";
import Teams from "./views/admin/teams/Teams";
import { AuthService } from "./services/sharedService/AuthService";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useNavigate } from "react-router-dom";
import { getUserProfileAsync } from "./services/reducers/appReducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewProject from "./views/admin/projects/ViewProject";
import MenuEchoPulse from "./views/MenuEchoPulse/MenuEchoPulse";
import SingleMemberTaskView from "./views/admin/teams/SingleMemberTaskView";
import Screenshots from "./views/Screenshots/Screenshots";
import TeamScreenshotsView from "./views/admin/teams/TeamScreenshotsView";
import TimeOff from "./views/TimeOff/TimeOff";

const AppContainer = () => {
  const authService = new AuthService();
  const [user, setUser] = useState<any>();
  const [subscriptionName, setSubscriptionName] = useState<string>("");
  const [curUser, setCurUser] = useState();
  const dispatch = useAppDispatch();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [sideNav, setSideNav] = useState<boolean>(false);
  const isFetching = useRef(false);
  
  useEffect(() => {
    const getUser = () => {
      authService.getUser().then((user) => {
        if (user) {
          setUser(user.profile);
          const token = localStorage.getItem("accessToken");
          localStorage.setItem("accessToken", user.access_token);
          localStorage.setItem("name", user.profile.name ?? "");
          const redirecturl = localStorage.getItem("RedirectURL");
          setSubscriptionName(user.profile.SubscriptionName ?? "");
          if (redirecturl) {
            localStorage.removeItem("RedirectURL");
            window.location.replace(redirecturl);
          }

          if (!token) window.location.reload();
        } else {
          authService.login();
          setUser(null);
        }
      });
    };
    getUser();
    getUserProfile();
  }, []);

  const login = () => {
    authService.login();
  };

  const renewToken = () => {
    authService
      .renewToken()
      .then((user) => {})
      .catch((error) => {});
  };

  const logout = () => {
    authService.logout();
  };

  const getUsers = () => {
    authService.getUser().then((user) => {
      if (user) {
        // setTimeout(currentUser().then((res) => {}), 3000);
        setUser(user.profile);
        const token = localStorage.getItem("accessToken");
        localStorage.setItem("accessToken", user.access_token);
        setSubscriptionName(user.profile.SubscriptionName ?? "");
        const redirecturl = localStorage.getItem("RedirectURL");
        if (redirecturl) {
          localStorage.removeItem("RedirectURL");
          window.location.replace(redirecturl);
        }

        if (!token) window.location.reload();
      } else {
        authService.login();
        setUser(null);
      }
    });
  };

  const getUserProfile = () => {
    if (isFetching.current) return; 

    isFetching.current = true;
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
        isFetching.current = false;
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <MenuEchoPulse>
        {user && (
          <>
            <Routes>
              {userProfile?.roleName === "Super Admin" ||
              userProfile?.roleName === "Admin" ||
              userProfile?.isReportingManager ? (
                <>
                  <Route path="/" element={<Dashboard />} exact />
                  <Route path="/teams" element={<Teams />} exact />
                  <Route path="/timeTracker" element={<TimeTracker />} exact />
                  <Route path="/projects" element={<Projects />} exact />
                  <Route path="/settings" element={<Settings />} exact />
                  <Route path="/projects/:id" element={<ViewProject />} exact />
                  <Route
                    path="/teams/:id"
                    element={<SingleMemberTaskView />}
                    exact
                  />
                  <Route path="/screenshots" element={<Screenshots />} exact />
                  <Route
                    path="/teams/:identityId/:date/screenshots"
                    element={<TeamScreenshotsView />}
                    exact
                  />
                  <Route path="/timeoff" element={<TimeOff />} exact />
                </>
              ) : (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/timeTracker" element={<TimeTracker />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/projects/:id" element={<ViewProject />} />
                  <Route path="/screenshots" element={<Screenshots />} />
                  <Route path="/timeoff" element={<TimeOff />} />
                </>
              )}
            </Routes>
          </>
        )}
      </MenuEchoPulse>
    </>
  );
};

export default AppContainer;
