import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Tabs from "../../../components/Tabs/Tab";
import { Dropdown } from "../../../components/DropDown/DropDown";
import myTeamImage from "../../../images/Profile.png";
import { getNoteListAsync } from "../../../services/reducers/timeReducer";
import { useAppDispatch } from "../../../hooks";
import { convertMinutesToHoursAndMinutes } from "../../../utils/common";

type Props = {
  onClose: () => void;
  summary: string;
  projectKey: string;
  projectId: string;
  projectName: string;
  taskKey: string;
  timesheetId: string;
  userDetail: any;
};

const SingleMemberTaskViewNoteModal: React.FC<Props> = ({
  onClose,
  summary,
  projectKey,
  projectId,
  projectName,
  taskKey,
  timesheetId,
  userDetail,
}) => {
  const [noteDetail, setNoteDetail] = useState<any>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getNotes();
  }, [timesheetId]);

  const getNotes = () => {
    dispatch(getNoteListAsync(timesheetId)).then((res) => {
      if (res.payload) {
        setNoteDetail(res.payload);
      }
    });
  };

  const getTotalLoggedTime = (noteDetail: any) => {
    return noteDetail.reduce(
      (total: any, item: any) => total + (item.LogedTime || 0),
      0
    );
  };

  const totalLoggedTime = getTotalLoggedTime(noteDetail);

  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="bg-gray-200">
        <Typography className="font-bold text-gray-300" variant="h3">
          Notes added
        </Typography>
      </ModalHeader>
      <ModalBody className="!px-20 !py-12">
        <Typography className="font-bold text-gray-700" variant="h6">
          {userDetail?.name} Notes
        </Typography>
        <div className="mt-2">
          <div className="h-[4.875rem]">
            <div className="flex justify-between border-b border-gray-400 gap-2 h-full">
              <div className="flex items-center justify-start h-full py-4">
                <div className="">
                  <div className="text-400 font-bold text-gray-300 flex items-center">
                    <span className="line-clamp-2">{summary}</span>
                    <span className="ms-2.5 cursor-pointer invisible group-hover:visible"></span>
                  </div>
                  <div className="text-200 font-normal text-gray-700 flex mt-1">
                    <span className="max-w-[70%] line-clamp-1">
                      {" "}
                      {projectKey || projectId} - {projectName}
                    </span>
                    <span className="ms-2.5 ps-2.5 border border-y-0 border-e-0 border-s-gray-700 line-clamp-1">
                      {" "}
                      #{taskKey}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-start text-center gap-[0.75rem] py-4">
                <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md overflow-hidden">
                  <div
                    id="test099c845b-b3af-43e5-47fd-08dc6f2b996f"
                    className="text-400 font-bold text-gray-300 outline-0 "
                  >
                    {convertMinutesToHoursAndMinutes(totalLoggedTime)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {noteDetail &&
            noteDetail.map((t1: any, index: number) => (
              <div className="h-[4.875rem] ps-4">
                <div className="flex justify-between border-b border-gray-400 gap-2 h-full">
                  <div className="flex items-center justify-start h-full py-4">
                    <div className="">
                      <div
                        className={`text-400 flex items-center ${
                          t1?.notes === "No Note Added"
                            ? "font-bold text-primary-100"
                            : "font-normal text-gray-300"
                        }`}
                      >
                        <span className="line-clamp-2">{t1?.notes}</span>
                        <span className="ms-2.5 cursor-pointer invisible group-hover:visible"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start text-center gap-[0.75rem] py-4">
                    <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md overflow-hidden">
                      <div
                        id="test099c845b-b3af-43e5-47fd-08dc6f2b996f"
                        className="text-400 font-normal text-gray-300 outline-0"
                      >
                        {convertMinutesToHoursAndMinutes(t1?.LogedTime)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="h-[4.875rem] ps-4">
            <div className="flex justify-between border-b border-gray-400 gap-2 h-full">
              <div className="flex items-center justify-start h-full py-4">
                <div className="">
                  <div className="text-400 font-normal text-gray-300 flex items-center">
                    <span className="line-clamp-1">
                      UX discussion & updates for tonight’s call with Garlic
                      Media
                    </span>
                    <span className="ms-2.5 cursor-pointer invisible group-hover:visible"></span>
                  </div>
                </div>
              </div>
              <div className="flex justify-start text-center gap-[0.75rem] py-4">
                <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md overflow-hidden">
                  <div
                    id="test099c845b-b3af-43e5-47fd-08dc6f2b996f"
                    className="text-400 font-normal text-gray-300 outline-0"
                  >
                    01:00
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[4.875rem] ps-4">
            <div className="flex justify-between border-b border-gray-400 gap-2 h-full">
              <div className="flex items-center justify-start h-full py-4">
                <div className="">
                  <div className="text-400 font-normal text-gray-300 flex items-center">
                    <span className="line-clamp-1">
                      UI discussion with team
                    </span>
                    <span className="ms-2.5 cursor-pointer invisible group-hover:visible"></span>
                  </div>
                </div>
              </div>
              <div className="flex justify-start text-center gap-[0.75rem] py-4">
                <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md overflow-hidden">
                  <div
                    id="test099c845b-b3af-43e5-47fd-08dc6f2b996f"
                    className="text-400 font-normal text-gray-300 outline-0"
                  >
                    00:10
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[4.875rem] ps-4">
            <div className="flex justify-between border-b border-gray-400 gap-2 h-full">
              <div className="flex items-center justify-start h-full py-4">
                <div className="">
                  <div className="text-400 font-normal text-gray-300 flex items-center">
                    <span className="line-clamp-1">Sprint Call</span>
                    <span className="ms-2.5 cursor-pointer invisible group-hover:visible"></span>
                  </div>
                </div>
              </div>
              <div className="flex justify-start text-center gap-[0.75rem] py-4">
                <div className="flex justify-center items-center w-[5rem] h-full border border-gray-400 rounded-md overflow-hidden">
                  <div
                    id="test099c845b-b3af-43e5-47fd-08dc6f2b996f"
                    className="text-400 font-normal text-gray-300 outline-0"
                  >
                    00:50
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default SingleMemberTaskViewNoteModal;
