import { API_URL } from "../sharedService/constants";
import { http } from "../sharedService/httpService";

export function getTeamList(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/GetTeamTimeSheets?team=${reqBody.team}&startDate=${reqBody.startDate}&endDate=${reqBody.endDate}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&memberName=${reqBody.memberName}`
  );
}

export function getTimeSpentList(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/GetTeamTimeSpentOnProjects?startDate=${reqBody.startDate}&endDate=${reqBody.endDate}&project=${reqBody.project}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`
  );
}

export function getTimesheetById(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/GetTimeSheetByMemberId?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}&MemberId=${reqBody.MemberId}&PageSize=${reqBody.PageSize}&Page=${reqBody.Page}`
  );
}

export function updateTimesheetByAdmin(reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/UpdateByAdmin`,
    reqBody
  );
}

export function addTimeByAdmin(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/AddTaskByAdmin`,
    reqBody
  );
}

export function getTeamClockInOut(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/GetTeamClockInOuts?startDate=${reqBody.startDate}&endDate=${reqBody.endDate}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&team=${reqBody.team}&memberName=${reqBody.memberName}`
  );
}

export function getMyScreenShots(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/ScreenShot/GetMyScreenShots?startDate=${reqBody.startDate}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&memberId=${reqBody.memberId}`
  );
}

export function deleteScreenshot(
  id: string,
  date: string,
  checkSession: boolean
) {
  return http.delete(
    `${API_URL.INTEGRATION_API_URL}api/ScreenShot/${id}/${date}/${checkSession}`
  );
}

export function getTeamScreenShots(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/ScreenShot/GetTeamScreenShots?startDate=${reqBody.startDate}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&memberName=${reqBody.memberName}&isRM=${reqBody.isRM}`
  );
}

export function getTeamLists(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/Dashboard/GetTeamTimeSheet?team=${reqBody.team}&startDate=${reqBody.startDate}&endDate=${reqBody.endDate}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&memberName=${reqBody.memberName}&memberStatus=${reqBody.memberStatus}&isRM=${reqBody.isRM}`
  );
}

export function timeSheetApproval(reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/TimeSheetApproval`,
    reqBody
  );
}

export function timeSheetPushback(reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/TimeSheetPushback`,
    reqBody
  );
}

export function deleteComment(Id: string) {
  return http.delete(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/DeleteComment/${Id}`
  );
}
