import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";

type Props = {
  onClose: () => void;
  teamPanel: any;
  activeTab: string;
};

const MemberReportingMeModal: React.FC<Props> = ({
  onClose,
  teamPanel,
  activeTab,
}) => {
  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="bg-gray-200 !ps-16">
        <Typography className="font-bold text-gray-300" variant="h3">
          {activeTab === "tab1" ? "Team Members" : "Members Reporting to Me"}
        </Typography>
      </ModalHeader>
      <ModalBody className="!ps-0 !pt-0 !pe-10">
        <div className="flex justify-start items-center bg-white ps-16 pt-10 pb-3 sticky left-0 top-0 z-10 w-full">
          <div className="text-gray-700 font-bold text-400 w-1/2">Name</div>
          <div className="text-gray-700 font-bold text-400 w-1/4">Team</div>
          <div className="text-gray-700 font-bold text-400 w-1/4">
            Designation
          </div>
        </div>
        <div className="ps-16">
          {teamPanel?.records?.map((t: any) => (
            <div className="flex justify-start items-center w-full py-3 border-b border-gray-400">
              <div className="w-1/2 flex justify-start items-center gap-2">
                <div
                  className="h-10 w-100 rounded-full relative"
                  style={{ boxShadow: "0 0 0.625rem 0 #00000033" }}
                >
                  {t?.profilePic ? (
                    <UserImage
                      imgKey={t?.profilePic}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <img
                      src={getInitialsInName(t?.name ?? "N A", 24)}
                      alt={t?.name}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  )}
                  <span
                    className={`absolute bottom-1 -right-0.5 h-2 w-2 ${
                      t?.isOnLeave === false ? "bg-green-100" : "bg-primary-100"
                    } rounded-full`}
                  ></span>
                </div>
                <div className="text-400 font-medium text-gray-300">
                  {t?.name}
                </div>
              </div>
              <div className="w-1/4 text-400 font-medium text-gray-300">
                {t?.team}
              </div>
              <div className="w-1/4 text-400 font-medium text-gray-300">
                {t?.designation}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default MemberReportingMeModal;
