import React from "react";
import Typography from "../../components/Typography/Typography";
import TimeSpentEmptyImage from "../../images/timespentEmpty.svg";

const TimeSpentProjectEmpty = () => {
  return (
    // <div className="border border-gray-100 shadow-300 ps-6 pe-2 py-8 bg-white rounded-md relative h-[23.5rem]">
    //   <div className="mb-3">
    //     <Typography variant="h5" className="text-gray-300 font-bold">
    //       Time Spent on Projects
    //     </Typography>
    //   </div>
    <div className="pe-4 w-full h-[calc(100%-2.5rem)] overflow-auto flex justify-center items-center text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={TimeSpentEmptyImage}
            alt="TimeSpentEmptyImage"
            className="w-[6.938rem] h-[6.938rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5 w-[20.688rem]"
          size="lg"
          variant="p"
        >
          No Time Logged Yet for This Week. Start Tracking Your Project Hours to
          See Them Here.
        </Typography>
      </div>
    </div>
    // </div>
  );
};

export default TimeSpentProjectEmpty;
