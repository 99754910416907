import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../sharedService/constants";
import { createAdminTimeoff, createTimeoff, deleteTimeOff, getAdminTimeoffList, getDetailTimeoff, getFinancialYearList, getSummaryTimeoff, getLeaveBalance, getLeaveStatistics, getLeaveType, updateTimeOff, getMyTimeOff, getCalenderTimeOff, getTeamTimeOffs, getTeamLeaveTimeoff, getTeamTimeOffCalenderView, approveTimeoff, getOrganisationsOffs, cancelTimeoff, getMyTeamPanel, getTimeOffByDate, getEnumList } from "../api/leaveApis";

const initialState = {
  isLoading: false,
  isError: false,
  createTimeoff: {},
  listTimeoff:{},
  financialyearList:{},
  leaveType:{},
  crTimeoff:{},
  historyTimeoff:{},
  leaveStatistics:{},
  detailTimeoff:{},
  myTimeoff:{},
  calenderTimeoff:{},
  teamCalenderTimeoff:{},
  teamTimeoff:{},
  teamLeave:{},
  orgTimeoff:{},
  teamPanel:{},
  timeOffBydate:{},
  occurrenceList:[]
};

export const createAdminTimeoffAsync = createAsyncThunk(
  "timeoff/create",
  async (request: any) => {
    const response = await createAdminTimeoff(request);
    return response.data;
  }
);

export const getAdminTimeoffListAsync = createAsyncThunk(
  "timeoff/list",
  async (request: any) => {
    const response = await getAdminTimeoffList(request);
    return response.data;
  }
);

export const deleteTimeOffAsync = createAsyncThunk(
  "timeoff/delete",
  async (Id:string) => {
    const response = await deleteTimeOff(Id);
    return response.data;
  }
);

export const updateTimeOffAsync = createAsyncThunk(
  'timeoff/update',
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await updateTimeOff(id, reqBody);
    return response.data;
  }
);

export const getFinancialYearListAsync = createAsyncThunk(
  "timeOff/getFinancialYearList",
  async () => {
      const response = await getFinancialYearList();
      return response.data;
  }
);

export const getLeaveTypeAsync = createAsyncThunk(
  "timeOff/leaveType",
  async () => {
      const response = await getLeaveType();
      return response.data;
  }
);

export const getLeaveBalanceAsync = createAsyncThunk(
  'timeoff/get',
  async (request: any) => {
    const response = await getLeaveBalance(request);
    return response.data;
  }
);

export const createTimeoffAsync = createAsyncThunk(
  "timeoff/createEmployee",
  async (request: any) => {
    const response = await createTimeoff(request);
    return response.data;
  }
);

export const getSummaryTimeoffAsync = createAsyncThunk(
  "timeOff/history",
  async (request: any) => {
      const response = await getSummaryTimeoff(request);
      return response.data;
  }
);

export const getLeaveStatisticsAsync = createAsyncThunk(
  "timeoff/leavestatistics",
  async () => {
    const response = await getLeaveStatistics();
    return response.data;
  }
);

export const getDetailTimeoffAsync = createAsyncThunk(
  "timeoff/detailTimeOff",
  async (timeOffId:string) => {
    const response = await getDetailTimeoff(timeOffId);
    return response.data;
  }
);

export const getMyTimeOffAsync = createAsyncThunk(
  "timeoff/myTimeoff",
  async (request: any) => {
    const response = await getMyTimeOff(request);
    return response.data;
  }
);

export const getCalenderTimeOffAsync = createAsyncThunk(
  "timeoff/calender",
  async (request: any) => {
    const response = await getCalenderTimeOff(request);
    return response.data;
  }
);

export const getTeamTimeOffsAsync = createAsyncThunk(
  "timeOff/teamtimeoff",
  async (reqBody:any) => {
      const response = await getTeamTimeOffs(reqBody);
      return response.data;
  }
);

export const getTeamLeaveTimeoffAsync = createAsyncThunk(
  "timeOff/teamLeave",
  async (request: any) => {
      const response = await getTeamLeaveTimeoff(request);
      return response.data;
  }
);

export const getTeamTimeOffCalenderViewAsync = createAsyncThunk(
  "timeoff/teamcalender",
  async (request: any) => {
    const response = await getTeamTimeOffCalenderView(request);
    return response.data;
  }
);

export const approveTimeoffAsync = createAsyncThunk(
  'timesheet/approveTimeoff',
  async ({ id, isARM, reqBody }: { id: string, isARM: boolean, reqBody: any }) => {
    const response = await approveTimeoff(id, isARM, reqBody);
    // console.log(response.data, "data");
    return response.data;
  }
);

export const cancelTimeoffAsync = createAsyncThunk(
  'timesheet/cancelTimeoff',
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await cancelTimeoff(id, reqBody);
    return response.data;
  }
);

export const getOrganisationsOffsAsync = createAsyncThunk(
  "timeOff/orgTimeoff",
  async (reqBody:any) => {
      const response = await getOrganisationsOffs(reqBody);
      return response.data;
  }
);

export const getMyTeamPanelAsync = createAsyncThunk(
  "timeOff/getMyTeamPanel",
  async (reqBody:any) => {
      const response = await getMyTeamPanel(reqBody);
      return response.data;
  }
);

export const getTimeOffByDateAsync = createAsyncThunk(
  "timeOff/getTimeOffByDate",
  async (reqBody:any) => {
      const response = await getTimeOffByDate(reqBody);
      return response.data;
  }
);

export const getOccurrenceModeAsync = createAsyncThunk(
  "timeOff/OccurrenceMode",
  async () => {
    const response = await getEnumList("OccurrenceMode");
    return response.data;
  }
);

const leaveManagentSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAdminTimeoffAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createAdminTimeoffAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createTimeoff = action.payload;
      })
      .addCase(createAdminTimeoffAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

      builder
      .addCase(getAdminTimeoffListAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAdminTimeoffListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listTimeoff = action.payload;
      })
      .addCase(getAdminTimeoffListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

      builder
        .addCase(getFinancialYearListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getFinancialYearListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.financialyearList = action.payload     
        })
        .addCase(getFinancialYearListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getLeaveTypeAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getLeaveTypeAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.leaveType = action.payload     
        })
        .addCase(getLeaveTypeAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(createTimeoffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(createTimeoffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.crTimeoff = action.payload;
        })
        .addCase(createTimeoffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getSummaryTimeoffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getSummaryTimeoffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.historyTimeoff = action.payload;
        })
        .addCase(getSummaryTimeoffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getLeaveStatisticsAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getLeaveStatisticsAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.leaveStatistics = action.payload;
        })
        .addCase(getLeaveStatisticsAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getDetailTimeoffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getDetailTimeoffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.detailTimeoff = action.payload;
        })
        .addCase(getDetailTimeoffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(deleteTimeOffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(deleteTimeOffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.detailTimeoff = action.payload;
          toast.success(`Time Off Type Deleted Successfully!`,TOASTIFY_CONF);
        })
        .addCase(deleteTimeOffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          // toast.error(
          //   `This Leave cannot be deleted as it is in use.`,
          //   TOASTIFY_CONF
          // );
          // console.log(action, "action")
        });

        builder
        .addCase(getMyTimeOffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getMyTimeOffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.myTimeoff = action.payload;
        })
        .addCase(getMyTimeOffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getCalenderTimeOffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getCalenderTimeOffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.calenderTimeoff = action.payload;
        })
        .addCase(getCalenderTimeOffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getTeamTimeOffsAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamTimeOffsAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamTimeoff = action.payload;
        })
        .addCase(getTeamTimeOffsAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getTeamLeaveTimeoffAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamLeaveTimeoffAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamLeave = action.payload;
        })
        .addCase(getTeamLeaveTimeoffAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getTeamTimeOffCalenderViewAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamTimeOffCalenderViewAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamCalenderTimeoff = action.payload;
        })
        .addCase(getTeamTimeOffCalenderViewAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getOrganisationsOffsAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getOrganisationsOffsAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.orgTimeoff = action.payload;
        })
        .addCase(getOrganisationsOffsAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getMyTeamPanelAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getMyTeamPanelAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamPanel = action.payload;
        })
        .addCase(getMyTeamPanelAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getTimeOffByDateAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTimeOffByDateAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.timeOffBydate = action.payload;
        })
        .addCase(getTimeOffByDateAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getOccurrenceModeAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getOccurrenceModeAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.occurrenceList = action.payload     
        })
        .addCase(getOccurrenceModeAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });
  },
});

export default leaveManagentSlice.reducer;
