import React, { useEffect, useRef, useState } from "react";
import Tabs from "../../../components/Tabs/Tab";
import TimeOffConfiguration from "./TimeOffConfiguration";
import Typography from "../../../components/Typography/Typography";
import AdminMyTimeOff from "./AdminMyTimeOff";
import MyTeamTimeOff from "../ReportingManergerTimeOff/MyTeamTimeOff";
import OrganizationTimeOff from "./OrganizationTimeOff";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getCalenderTimeOffAsync,
  getFinancialYearListAsync,
  getMyTeamPanelAsync,
  getTeamTimeOffCalenderViewAsync,
  getTimeOffByDateAsync,
} from "../../../services/reducers/leaveReducer";
import {
  formatDate,
  formatDateMonth,
  formatDateMonthYear,
} from "../../../utils/common";
import { getUserProfileAsync } from "../../../services/reducers/appReducer";
import MemberReportingMeModal from "./MemberReportingMeModal";
import styled from "styled-components";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { getSettingListAsync } from "../../../services/reducers/adminReducer";
import { useLocation } from "react-router-dom";

export const Tooltip = styled.div`
  .tooltip {
    height: 2.5rem;
    width: 100%;
    box-shadow: 0 0 0.625rem 0 #00000033;
    position: relative;
    border-radius: 50%;
    .tooltiptext {
      visibility: hidden;
      width: 10rem;
      background-color: white;
      color: #fff;
      border-radius: 0.375rem;
      padding: 0.5rem 0.5rem;
      position: absolute;
      z-index: 999;
      top: -0.563rem;
      left: 130%;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -0.625rem;
        border-width: 0.625rem;
        border-style: solid;
        border-color: transparent white transparent transparent;
      }
    }
    &:hover .tooltiptext {
      visibility: visible;
    }
  }
`;

const AdminTimeOff = () => {
  const location = useLocation();
  let initialTab = location.state?.activeTab || "tab1";
  const [activeTab, setActiveTab] = useState(initialTab);
  const dispatch = useAppDispatch();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [startMonth, setStartMonth] = useState<string>(
    formatDateMonthYear(currentMonth, currentYear)
  );
  const nextMonthIndex = currentMonth === 11 ? 0 : currentMonth + 1;
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
  const [endMonth, setEndMonth] = useState<string>(
    formatDateMonthYear(nextMonthIndex, nextYear, true)
  );
  const [startMonthTeam, setStartMonthTeam] = useState<string>(
    formatDateMonthYear(currentMonth, currentYear)
  );
  const [endMonthTeam, setEndMonthTeam] = useState<string>(
    formatDateMonthYear(nextMonthIndex, nextYear, true)
  );
  const [calenderDetail, setCalenderDetail] = useState<any>([]);
  const { userProfile } = useAppSelector((state: any) => state.app);
  const [memberReportingMe, setMemberReportingMe] = useState<boolean>(false);
  const { teamPanel } = useAppSelector((state: any) => state.leave);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [calenderDate, setCalenderDate] = useState<any>([]);
  const currentDate = new Date();
  const [teamCalenderDate, seTeamCalenderDate] = useState<any>([]);
  const [teamCalenderDetail, setTeamCalenderDetail] = useState<any>([]);
  const getSettingList = useAppSelector(
    (state: any) => state.admin.getSettingList?.records
  );
  const isFetching = useRef(false);
  const financialyearList = useAppSelector(
    (state: any) => state.leave.financialyearList
  );
  const [currentSlide, setCurrentSlide] = useState(currentDate.getMonth());
  const [currentSlideTeam, setCurrentSlideTeam] = useState(
    currentDate.getMonth()
  );
  const [current, setCurrent] = useState(currentDate.getMonth() - 1);

  useEffect(() => {
    const request: any = {
      IsEmployee: activeTab === "tab1" ? true : false,
    };
    dispatch(getMyTeamPanelAsync(request));
  }, [activeTab]);

  useEffect(() => {
    dispatch(getFinancialYearListAsync());
    dispatch(getSettingListAsync());
    dispatch(getUserProfileAsync());
  }, []);

  useEffect(() => {
    if (activeTab === "tab1") {
      getCalenderTimeoff();
    }
  }, [currentSlide]);

  const getCalenderTimeoff = () => {
    // if (isFetching.current) return;

    // isFetching.current = true;
    const request: any = {
      StartDate: startMonth,
      EndDate: endMonth,
    };

    dispatch(getCalenderTimeOffAsync(request)).then((res) => {
      if (res.payload) {
        setCalenderDetail(res.payload);
        // isFetching.current = false;
      }
    });
  };

  useEffect(() => {
    if (activeTab === "tab3") {
      getTeamCalenderTimeoff();
    }
  }, [currentSlideTeam, activeTab]);

  const getTeamCalenderTimeoff = () => {
    const request: any = {
      StartDate: startMonthTeam,
      EndDate: endMonthTeam,
    };

    dispatch(getTeamTimeOffCalenderViewAsync(request)).then((res) => {
      if (res.payload) {
        setTeamCalenderDetail(res.payload);
      }
    });
  };

  useEffect(() => {
    if (activeTab === "tab1" || activeTab === "tab3") {
      getCalenderTimeoffByDate();
    }
  }, [selectedDate, activeTab]);

  const getCalenderTimeoffByDate = () => {
    if (isFetching.current) return;

    isFetching.current = true;
    if (activeTab === "tab1") {
      const request: any = {
        CalenderDate: selectedDate
          ? formatDate(selectedDate)
          : formatDate(currentDate),
        IsEmployee: true,
      };
      dispatch(getTimeOffByDateAsync(request)).then((res) => {
        if (res.payload) {
          setCalenderDate(res.payload);
          isFetching.current = false;
        }
      });
    } else {
      const request: any = {
        CalenderDate: selectedDate
          ? formatDate(selectedDate)
          : formatDate(currentDate),
        IsEmployee: false,
      };
      dispatch(getTimeOffByDateAsync(request)).then((res) => {
        if (res.payload) {
          seTeamCalenderDate(res.payload);
          isFetching.current = false;
        }
      });
    }
  };

  return (
    <div>
      <div className="pt-4 px-10 h-[6.5rem]">
        {(activeTab === "tab1" || activeTab === "tab3") && (
          <>
            <Typography variant="h6" className="text-gray-300 font-bold">
              My Team
              <span className="text-200 font-light ms-2">
                {teamPanel?.totalRecords}
              </span>
            </Typography>

            <div className="overflow-x-auto">
              <div className="flex justify-start items-center mb-5 mt-2 ps-1.5 gap-2.5">
                {teamPanel?.records?.slice(0, 6).map((t: any) => (
                  <>
                    <Tooltip>
                      <div className="tooltip">
                        {t?.profilePic ? (
                          <UserImage
                            imgKey={t?.profilePic}
                            className="w-10 h-10 rounded-full object-cover"
                          />
                        ) : (
                          <img
                            src={getInitialsInName(t?.name ?? "N A", 24)}
                            alt={t?.name}
                            className="w-10 h-10 rounded-full object-cover"
                          />
                        )}

                        <span
                          className={`absolute bottom-1 -right-0.5 h-2 w-2 ${
                            t?.isOnLeave === false
                              ? "bg-green-100"
                              : "bg-primary-100"
                          } rounded-full`}
                        ></span>
                        <div className="tooltiptext">
                          <div className="text-gray-300 text-200 font-bold line-clamp-1">
                            {t?.name}
                          </div>
                          <div className="text-gray-700 text-200 font-normal line-clamp-1 mt-0.5">
                            {t?.designation} | {t?.team}
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </>
                ))}

                {teamPanel?.totalRecords > 6 ? (
                  <div
                    className="bg-primary-100 bg-opacity-10 w-10 h-10 rounded-full text-100 text-primary-100 font-semibold flex justify-center items-center cursor-pointer"
                    onClick={() => setMemberReportingMe(true)}
                  >
                    <span>{`+ ${teamPanel?.totalRecords - 6}`}</span>
                  </div>
                ) : teamPanel?.totalRecords === 0 ? (
                  <div
                    className="bg-primary-100 bg-opacity-10 w-10 h-10 rounded-full text-100 text-primary-100 font-semibold flex justify-center items-center cursor-pointer"
                    onClick={() => setMemberReportingMe(true)}
                  >
                    <span>0</span>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>

      {(userProfile?.roleName === "Super Admin" ||
        userProfile?.roleName === "Admin") &&
        userProfile?.isReportingManager === false && (
          <div className="pt-3">
            <Tabs
              onClick={(id) => setActiveTab(id)}
              defaultActiveTab={activeTab}
              size="md"
              className="[&>.addPadding]:ps-10"
              tabs={[
                {
                  content: (
                    <AdminMyTimeOff
                      financialyearList={financialyearList}
                      setStartMonth={setStartMonth}
                      setEndMonth={setEndMonth}
                      calenderDetail={calenderDetail}
                      role={userProfile}
                      activeTab={activeTab}
                      type="Admin"
                      setSelectedDate={setSelectedDate}
                      calenderDate={calenderDate}
                      tab="tab1"
                      setCurrentSlide={setCurrentSlide}
                      currentSlide={currentSlide}
                      setCurrent={setCurrent}
                      getSettingList={getSettingList}
                      current={current}
                    />
                  ),
                  id: "tab1",
                  label: "My Time Off",
                },
                {
                  content: (
                    <OrganizationTimeOff
                      activeTab={activeTab}
                      type="Admin"
                      getSettingList={getSettingList}
                      userProfile={userProfile}
                    />
                  ),
                  id: "tab2",
                  label: "Organisation’s Time Off",
                },
                {
                  content: (
                    <TimeOffConfiguration
                      activeTab={activeTab}
                      financialyearList={financialyearList}
                    />
                  ),
                  id: "tab4",
                  label: "Time Off Configuration",
                },
              ]}
            />
          </div>
        )}
      {userProfile?.roleName === "Employee" &&
        userProfile?.isReportingManager === false && (
          <div className="pt-3">
            <Tabs
              onClick={(id) => setActiveTab(id)}
              size="md"
              className="[&>.addPadding]:ps-10"
              tabs={[
                {
                  content: (
                    <AdminMyTimeOff
                      financialyearList={financialyearList}
                      setStartMonth={setStartMonth}
                      setEndMonth={setEndMonth}
                      calenderDetail={calenderDetail}
                      role={userProfile}
                      activeTab={activeTab}
                      type="Employee"
                      setSelectedDate={setSelectedDate}
                      calenderDate={calenderDate}
                      tab="tab1"
                      setCurrentSlide={setCurrentSlide}
                      currentSlide={currentSlide}
                      setCurrent={setCurrent}
                      getSettingList={getSettingList}
                      current={current}
                    />
                  ),
                  id: "tab1",
                  label: "My Time Off",
                },
              ]}
            />
          </div>
        )}
      {userProfile?.roleName === "Employee" &&
        userProfile?.isReportingManager === true && (
          <Tabs
            onClick={(id) => setActiveTab(id)}
            defaultActiveTab={activeTab}
            size="md"
            className="[&>.addPadding]:ps-10"
            tabs={[
              {
                content: (
                  <AdminMyTimeOff
                    financialyearList={financialyearList}
                    setStartMonth={setStartMonth}
                    setEndMonth={setEndMonth}
                    calenderDetail={calenderDetail}
                    role={userProfile}
                    activeTab={activeTab}
                    type="RM"
                    setSelectedDate={setSelectedDate}
                    calenderDate={calenderDate}
                    tab="tab1"
                    setCurrentSlide={setCurrentSlide}
                    currentSlide={currentSlide}
                    setCurrent={setCurrent}
                    getSettingList={getSettingList}
                    current={current}
                  />
                ),
                id: "tab1",
                label: "My Time Off",
              },

              {
                content: (
                  <MyTeamTimeOff
                    financialyearList={financialyearList}
                    setStartMonthTeam={setStartMonthTeam}
                    setEndMonthTeam={setEndMonthTeam}
                    calenderDetail={teamCalenderDetail}
                    role={userProfile}
                    activeTab={activeTab}
                    setSelectedDate={setSelectedDate}
                    calenderDate={teamCalenderDate}
                    type="RM"
                    tab="tab3"
                    setCurrentSlideTeam={setCurrentSlideTeam}
                    currentSlideTeam={currentSlideTeam}
                    setCurrent={setCurrent}
                    current={current}
                  />
                ),
                id: "tab3",
                label: "My Team’s Time Off",
              },
            ]}
          />
        )}
      {(userProfile?.roleName === "Super Admin" ||
        userProfile?.roleName === "Admin") &&
        userProfile?.isReportingManager && (
          <Tabs
            onClick={(id) => setActiveTab(id)}
            defaultActiveTab={activeTab}
            size="md"
            className="[&>.addPadding]:ps-10"
            tabs={[
              {
                content: (
                  <AdminMyTimeOff
                    financialyearList={financialyearList}
                    setStartMonth={setStartMonth}
                    setEndMonth={setEndMonth}
                    calenderDetail={calenderDetail}
                    role={userProfile}
                    activeTab={activeTab}
                    type="Admin & RM"
                    setSelectedDate={setSelectedDate}
                    calenderDate={calenderDate}
                    tab="tab1"
                    setCurrentSlide={setCurrentSlide}
                    currentSlide={currentSlide}
                    setCurrent={setCurrent}
                    getSettingList={getSettingList}
                    current={current}
                  />
                ),
                id: "tab1",
                label: "My Time Off",
              },
              {
                content: (
                  <OrganizationTimeOff
                    activeTab={activeTab}
                    type="Admin & RM"
                    getSettingList={getSettingList}
                    userProfile={userProfile}
                  />
                ),
                id: "tab2",
                label: "Organisation’s Time Off",
              },
              {
                content: (
                  <MyTeamTimeOff
                    financialyearList={financialyearList}
                    setStartMonthTeam={setStartMonthTeam}
                    setEndMonthTeam={setEndMonthTeam}
                    calenderDetail={teamCalenderDetail}
                    role={userProfile}
                    activeTab={activeTab}
                    setSelectedDate={setSelectedDate}
                    calenderDate={teamCalenderDate}
                    type="Admin & RM"
                    tab="tab3"
                    setCurrentSlideTeam={setCurrentSlideTeam}
                    currentSlideTeam={currentSlideTeam}
                    setCurrent={setCurrent}
                    current={current}
                  />
                ),
                id: "tab3",
                label: "My Team’s Time Off",
              },
              {
                content: (
                  <TimeOffConfiguration
                    activeTab={activeTab}
                    financialyearList={financialyearList}
                  />
                ),
                id: "tab4",
                label: "Time Off Configuration",
              },
            ]}
          />
        )}
      {memberReportingMe && (
        <MemberReportingMeModal
          onClose={() => setMemberReportingMe(false)}
          teamPanel={teamPanel}
          activeTab={activeTab}
        />
      )}
    </div>
  );
};

export default AdminTimeOff;
