import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "../../../components/DropDown/DropDown";
import AdminTimeOffDetailsModal from "./AdminTimeOffDetailsModal";
import { getTeamsAsync } from "../../../services/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getLeaveStatisticsAsync,
  getOrganisationsOffsAsync,
} from "../../../services/reducers/leaveReducer";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import EchopulseLoader from "../../loader/EchopulseLoader";
import OrganizationTimeOffEmpty from "./OrganizationTimeOffEmpty";

type TeamOption = {
  value: string;
  label: string;
};

type Props = {
  activeTab: string;
  type: string;
  getSettingList: any;
  userProfile: any;
};

const OrganizationTimeOff: React.FC<Props> = ({
  activeTab,
  type,
  getSettingList,
  userProfile,
}) => {
  const [timeOffDetails, setTimeOffDetails] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { teamsList } = useAppSelector((state: any) => state.app);
  const [optionsTeam, setOptionsTeam] = useState<TeamOption[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const [loading, setLoading] = useState(true);
  const [leavebalance, setLeavebalance] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState({
    label: "All",
    value: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    label: "All",
    value: "",
  });
  const [orgDetail, setOrgDetail] = useState<any>([]);
  const [timeOffApprove, setTimeOffApprove] = useState<boolean>(false);
  const [timeoffId, setTimeoffId] = useState("");
  const [timeOffCancel, setTimeOffCancel] = useState<boolean>(false);
  const isFetching = useRef(false);

  const handleDropdownChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
  };

  const handleStatusChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
  };

  useEffect(() => {
    if (isFetching.current) return;

    isFetching.current = true;
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item: any) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: "all",
            label: "All",
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
          setSelectedTeam(optionsWithAll[0]);
          isFetching.current = false;
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item: any) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: "all",
          label: "All",
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
        setSelectedTeam(optionsWithAll[0]);
        isFetching.current = false;
      }
    }
  }, []);

  useEffect(() => {
    if (activeTab === "tab2") {
      dispatch(getLeaveStatisticsAsync()).then((res) => {
        if (res.payload) {
          setLeavebalance(res.payload);
        }
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "tab2") {
      orgnisationList();
    }
  }, [selectedValue.value, selectedStatus.value, selectedTeam.value]);

  const orgnisationList = () => {
    // if (isFetching.current) return;

    // isFetching.current = true;
    setLoading(true);
    const request: any = {
      LeaveCode: selectedValue.value,
      LeaveStatus: selectedStatus.value,
      Team:
        selectedTeam?.label && selectedTeam?.label !== "All"
          ? selectedTeam?.label
          : "",
      Page: 1,
      PageSize: 100,
    };
    dispatch(getOrganisationsOffsAsync(request)).then((res) => {
      setOrgDetail(res.payload.records);
      setLoading(false);
      // isFetching.current = false;
    });
  };

  const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthAbbr = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    return `${formattedDay} ${monthAbbr} ${year}`;
  };

  const handleView = (approve: boolean, cancel: boolean, id: string) => {
    setTimeoffId(id);
    setTimeOffCancel(cancel);
    setTimeOffApprove(approve);
    setTimeOffDetails(true);
  };
  useEffect(() => {
    orgnisationList();
  }, []);

  return (
    <div className="ps-10 pe-10">
      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-16rem)]">
        <div className="border-b border-gray-100 py-4 px-5 flex justify-start items-center gap-7">
          <div className="flex justify-start items-center gap-2">
            <div className="text-400 text-gray-300 font-medium">Team</div>
            <div className="w-[10.875rem]">
              <Dropdown
                className=""
                onChange={(selectedOption) => {
                  setSelectedTeam(selectedOption);
                }}
                placeholder="Select"
                options={optionsTeam}
                type="mdbox"
                value={selectedTeam}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-2">
            <div className="text-400 text-gray-300 font-medium">Leave Type</div>
            <div className="w-[10.875rem]">
              <Dropdown
                onChange={handleDropdownChange}
                options={[
                  { label: "All", value: "" },
                  ...(leavebalance?.map((org: any) => ({
                    label: org.leaves.title,
                    value: org.leaves.leaveCode,
                  })) || []),
                ]}
                type="smbox"
                isSearchable={false}
                value={selectedValue}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-2">
            <div className="text-400 text-gray-300 font-medium">
              Administrator Status
            </div>
            <div className="w-[10.875rem]">
              <Dropdown
                onChange={handleStatusChange}
                options={[
                  { label: "All", value: "" },
                  { label: "Approved", value: "a" },
                  { label: "Denied", value: "d" },
                  { label: "Pending", value: "p" },
                  { label: "Cancelled", value: "c" },
                ]}
                type="box"
                isSearchable={false}
                value={selectedStatus}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="[&>div>.loader-container]:h-[calc(100vh-20rem)]">
            <EchopulseLoader />
          </div>
        ) : (
          <>
            <div className="overflow-auto h-[calc(100%-81px)]">
              {orgDetail && orgDetail.length > 0 ? (
                <table className="w-full">
                  <thead className="bg-gray-200 sticky left-0 top-0 z-10">
                    <tr>
                      <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left ps-8">
                        EMPLOYEE NAME
                      </th>
                      <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left">
                        LEAVE PERIOD
                      </th>
                      <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left">
                        LEAVE TYPE
                      </th>
                      {getSettingList[0]?.timeOffApproval === 2 && (
                        <>
                          <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left">
                            MANAGER STATUS
                          </th>
                          <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left">
                            ADMINISTRATOR RESPONSE
                          </th>
                        </>
                      )}
                      {(getSettingList[0]?.timeOffApproval === 0 ||
                        getSettingList[0]?.timeOffApproval === 1) && (
                        <>
                          <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left">
                            LEAVE STATUS
                          </th>
                        </>
                      )}
                      <th className="px-5 py-4 text-gray-700 text-200 font-bold text-left pe-8">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {orgDetail &&
                      orgDetail.map((t: any) => (
                        <tr>
                          <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left ps-8">
                            <div className="flex justify-start items-center gap-3">
                              <div>
                                {t?.memberProfile?.profilePic ? (
                                  <UserImage
                                    imgKey={t?.memberProfile?.profilePic}
                                    className="w-8 h-8 rounded-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t?.memberProfile?.name ?? "N A",
                                      24
                                    )}
                                    alt={t?.memberProfile?.name}
                                    className="w-8 h-8 rounded-full object-cover"
                                  />
                                )}
                              </div>
                              <div>
                                <div className="text-400 text-gray-300 font-medium">
                                  {t?.memberProfile?.name}
                                </div>
                                <div className="text-200 text-gray-700 font-normal line-clamp-1">
                                  {t?.memberProfile?.designation} |{" "}
                                  {t?.memberProfile?.team}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                            {formatDate(t?.startAt)} - {formatDate(t?.endAt)}
                          </td>
                          <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                            {t?.leave?.title}
                          </td>
                          {getSettingList[0]?.timeOffApproval === 2 && (
                            <>
                              {t?.isRMApproved === null && (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-gray-100 rounded-full text-200 font-normal text-gray-700 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M15.9949 13.8478C15.9742 13.6153 15.8932 13.3734 15.7516 13.1288L9.14024 1.67739C8.99942 1.43272 8.83087 1.24142 8.63955 1.107C8.44183 0.96902 8.22063 0.896484 8.00016 0.896484C7.77969 0.896484 7.55849 0.969029 7.36078 1.107C7.16946 1.24071 7.0009 1.43274 6.86009 1.67739L0.247964 13.1288C0.107143 13.3735 0.0253453 13.6153 0.00473477 13.8478C-0.0158903 14.0875 0.0310496 14.3158 0.142001 14.5071C0.25224 14.6985 0.425778 14.8535 0.644118 14.9552C0.855358 15.0541 1.10571 15.1038 1.38805 15.1038H14.611C14.8934 15.1038 15.1437 15.0541 15.3549 14.9552C15.5733 14.8535 15.7468 14.6984 15.8571 14.5071C15.9687 14.3158 16.0162 14.0875 15.9949 13.8478ZM8.00009 13.0406C7.55558 13.0406 7.19499 12.68 7.19499 12.2355C7.19499 11.791 7.55558 11.4311 8.00009 11.4311C8.4446 11.4311 8.80519 11.791 8.80519 12.2355C8.80519 12.68 8.4446 13.0406 8.00009 13.0406ZM8.96735 5.87298L8.70917 10.136C8.69423 10.392 8.47091 10.6019 8.21415 10.6019H7.78457C7.52781 10.6019 7.30521 10.3921 7.28956 10.136L7.03138 5.87298C7.01573 5.61695 7.21274 5.40713 7.46948 5.40713H8.52706C8.78524 5.40784 8.98299 5.61693 8.96735 5.87298Z"
                                          fill="#888888"
                                        />
                                      </svg>
                                    </span>
                                    Pending
                                  </button>
                                </td>
                              )}
                              {t?.isRMApproved === true && (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-green-100 bg-opacity-10 rounded-full text-200 font-normal text-green-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16Z"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Approved
                                  </button>
                                </td>
                              )}
                              {t?.isRMApproved === false && (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-4 bg-primary-100 bg-opacity-10 rounded-full text-200 font-normal text-primary-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 14.25C11.45 14.25 14.25 11.45 14.25 8C14.25 4.55 11.45 1.75 8 1.75C4.55 1.75 1.75 4.55 1.75 8C1.75 11.45 4.55 14.25 8 14.25Z"
                                          stroke="#E01E1E"
                                          stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.3125 3.625L3.5625 12.375"
                                          stroke="#E01E1E"
                                          stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Denied
                                  </button>
                                </td>
                              )}
                              {t?.isLeaveCancelled === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-4 bg-pastel-600 rounded-full text-200 font-normal text-secondary-300 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                          fill="#ECB22E"
                                        />
                                      </svg>
                                    </span>
                                    Cancelled
                                  </button>
                                </td>
                              ) : t?.isAdminApproved === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-green-100 bg-opacity-10 rounded-full text-200 font-normal text-green-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16Z"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Approved
                                  </button>
                                </td>
                              ) : t?.isAdminApproved === null ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-gray-100 rounded-full text-200 font-normal text-gray-700 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M15.9949 13.8478C15.9742 13.6153 15.8932 13.3734 15.7516 13.1288L9.14024 1.67739C8.99942 1.43272 8.83087 1.24142 8.63955 1.107C8.44183 0.96902 8.22063 0.896484 8.00016 0.896484C7.77969 0.896484 7.55849 0.969029 7.36078 1.107C7.16946 1.24071 7.0009 1.43274 6.86009 1.67739L0.247964 13.1288C0.107143 13.3735 0.0253453 13.6153 0.00473477 13.8478C-0.0158903 14.0875 0.0310496 14.3158 0.142001 14.5071C0.25224 14.6985 0.425778 14.8535 0.644118 14.9552C0.855358 15.0541 1.10571 15.1038 1.38805 15.1038H14.611C14.8934 15.1038 15.1437 15.0541 15.3549 14.9552C15.5733 14.8535 15.7468 14.6984 15.8571 14.5071C15.9687 14.3158 16.0162 14.0875 15.9949 13.8478ZM8.00009 13.0406C7.55558 13.0406 7.19499 12.68 7.19499 12.2355C7.19499 11.791 7.55558 11.4311 8.00009 11.4311C8.4446 11.4311 8.80519 11.791 8.80519 12.2355C8.80519 12.68 8.4446 13.0406 8.00009 13.0406ZM8.96735 5.87298L8.70917 10.136C8.69423 10.392 8.47091 10.6019 8.21415 10.6019H7.78457C7.52781 10.6019 7.30521 10.3921 7.28956 10.136L7.03138 5.87298C7.01573 5.61695 7.21274 5.40713 7.46948 5.40713H8.52706C8.78524 5.40784 8.98299 5.61693 8.96735 5.87298Z"
                                          fill="#888888"
                                        />
                                      </svg>
                                    </span>
                                    Pending
                                  </button>
                                </td>
                              ) : (
                                t?.isAdminApproved === false && (
                                  <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                    <button className="flex justify-center items-center py-1 px-4 bg-primary-100 bg-opacity-10 rounded-full text-200 font-normal text-primary-100 cursor-text">
                                      <span className="pe-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M8 14.25C11.45 14.25 14.25 11.45 14.25 8C14.25 4.55 11.45 1.75 8 1.75C4.55 1.75 1.75 4.55 1.75 8C1.75 11.45 4.55 14.25 8 14.25Z"
                                            stroke="#E01E1E"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12.3125 3.625L3.5625 12.375"
                                            stroke="#E01E1E"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      Denied
                                    </button>
                                  </td>
                                )
                              )}
                            </>
                          )}
                          {getSettingList[0]?.timeOffApproval === 0 && (
                            <>
                              {t?.isLeaveCancelled === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-4 bg-pastel-600 rounded-full text-200 font-normal text-secondary-300 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                          fill="#ECB22E"
                                        />
                                      </svg>
                                    </span>
                                    Cancelled
                                  </button>
                                </td>
                              ) : t?.isAdminApproved === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-green-100 bg-opacity-10 rounded-full text-200 font-normal text-green-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16Z"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Approved
                                  </button>
                                </td>
                              ) : t?.isAdminApproved === null ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-gray-100 rounded-full text-200 font-normal text-gray-700 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M15.9949 13.8478C15.9742 13.6153 15.8932 13.3734 15.7516 13.1288L9.14024 1.67739C8.99942 1.43272 8.83087 1.24142 8.63955 1.107C8.44183 0.96902 8.22063 0.896484 8.00016 0.896484C7.77969 0.896484 7.55849 0.969029 7.36078 1.107C7.16946 1.24071 7.0009 1.43274 6.86009 1.67739L0.247964 13.1288C0.107143 13.3735 0.0253453 13.6153 0.00473477 13.8478C-0.0158903 14.0875 0.0310496 14.3158 0.142001 14.5071C0.25224 14.6985 0.425778 14.8535 0.644118 14.9552C0.855358 15.0541 1.10571 15.1038 1.38805 15.1038H14.611C14.8934 15.1038 15.1437 15.0541 15.3549 14.9552C15.5733 14.8535 15.7468 14.6984 15.8571 14.5071C15.9687 14.3158 16.0162 14.0875 15.9949 13.8478ZM8.00009 13.0406C7.55558 13.0406 7.19499 12.68 7.19499 12.2355C7.19499 11.791 7.55558 11.4311 8.00009 11.4311C8.4446 11.4311 8.80519 11.791 8.80519 12.2355C8.80519 12.68 8.4446 13.0406 8.00009 13.0406ZM8.96735 5.87298L8.70917 10.136C8.69423 10.392 8.47091 10.6019 8.21415 10.6019H7.78457C7.52781 10.6019 7.30521 10.3921 7.28956 10.136L7.03138 5.87298C7.01573 5.61695 7.21274 5.40713 7.46948 5.40713H8.52706C8.78524 5.40784 8.98299 5.61693 8.96735 5.87298Z"
                                          fill="#888888"
                                        />
                                      </svg>
                                    </span>
                                    Pending
                                  </button>
                                </td>
                              ) : (
                                t?.isAdminApproved === false && (
                                  <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                    <button className="flex justify-center items-center py-1 px-4 bg-primary-100 bg-opacity-10 rounded-full text-200 font-normal text-primary-100 cursor-text">
                                      <span className="pe-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M8 14.25C11.45 14.25 14.25 11.45 14.25 8C14.25 4.55 11.45 1.75 8 1.75C4.55 1.75 1.75 4.55 1.75 8C1.75 11.45 4.55 14.25 8 14.25Z"
                                            stroke="#E01E1E"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12.3125 3.625L3.5625 12.375"
                                            stroke="#E01E1E"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      Denied
                                    </button>
                                  </td>
                                )
                              )}
                            </>
                          )}

                          {getSettingList[0]?.timeOffApproval === 1 && (
                            <>
                              {t?.isLeaveCancelled === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-4 bg-pastel-600 rounded-full text-200 font-normal text-secondary-300 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                          fill="#ECB22E"
                                        />
                                      </svg>
                                    </span>
                                    Cancelled
                                  </button>
                                </td>
                              ) : t?.isRMApproved === true ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-3 bg-green-100 bg-opacity-10 rounded-full text-200 font-normal text-green-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16Z"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          fill="#2EB67D"
                                        />
                                        <path
                                          d="M4.60025 7.99984L6.86425 10.2638L11.4003 5.73584"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Approved
                                  </button>
                                </td>
                              ) : t?.isRMApproved === false ? (
                                <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                  <button className="flex justify-center items-center py-1 px-4 bg-primary-100 bg-opacity-10 rounded-full text-200 font-normal text-primary-100 cursor-text">
                                    <span className="pe-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 14.25C11.45 14.25 14.25 11.45 14.25 8C14.25 4.55 11.45 1.75 8 1.75C4.55 1.75 1.75 4.55 1.75 8C1.75 11.45 4.55 14.25 8 14.25Z"
                                          stroke="#E01E1E"
                                          stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.3125 3.625L3.5625 12.375"
                                          stroke="#E01E1E"
                                          stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Denied
                                  </button>
                                </td>
                              ) : (
                                t?.isRMApproved === null && (
                                  <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                                    <button className="flex justify-center items-center py-1 px-3 bg-gray-100 rounded-full text-200 font-normal text-gray-700 cursor-text">
                                      <span className="pe-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M15.9949 13.8478C15.9742 13.6153 15.8932 13.3734 15.7516 13.1288L9.14024 1.67739C8.99942 1.43272 8.83087 1.24142 8.63955 1.107C8.44183 0.96902 8.22063 0.896484 8.00016 0.896484C7.77969 0.896484 7.55849 0.969029 7.36078 1.107C7.16946 1.24071 7.0009 1.43274 6.86009 1.67739L0.247964 13.1288C0.107143 13.3735 0.0253453 13.6153 0.00473477 13.8478C-0.0158903 14.0875 0.0310496 14.3158 0.142001 14.5071C0.25224 14.6985 0.425778 14.8535 0.644118 14.9552C0.855358 15.0541 1.10571 15.1038 1.38805 15.1038H14.611C14.8934 15.1038 15.1437 15.0541 15.3549 14.9552C15.5733 14.8535 15.7468 14.6984 15.8571 14.5071C15.9687 14.3158 16.0162 14.0875 15.9949 13.8478ZM8.00009 13.0406C7.55558 13.0406 7.19499 12.68 7.19499 12.2355C7.19499 11.791 7.55558 11.4311 8.00009 11.4311C8.4446 11.4311 8.80519 11.791 8.80519 12.2355C8.80519 12.68 8.4446 13.0406 8.00009 13.0406ZM8.96735 5.87298L8.70917 10.136C8.69423 10.392 8.47091 10.6019 8.21415 10.6019H7.78457C7.52781 10.6019 7.30521 10.3921 7.28956 10.136L7.03138 5.87298C7.01573 5.61695 7.21274 5.40713 7.46948 5.40713H8.52706C8.78524 5.40784 8.98299 5.61693 8.96735 5.87298Z"
                                            fill="#888888"
                                          />
                                        </svg>
                                      </span>
                                      Pending
                                    </button>
                                  </td>
                                )
                              )}
                            </>
                          )}
                          <td className="px-5 py-4 text-gray-300 text-300 font-normal text-left">
                            <button
                              onClick={() => {
                                if (
                                  getSettingList[0]?.timeOffApproval === 2 ||
                                  getSettingList[0]?.timeOffApproval === 0
                                ) {
                                  handleView(
                                    t?.isAdminApproved,
                                    t?.isLeaveCancelled,
                                    t?.timeOffId
                                  );
                                } else {
                                  handleView(
                                    t?.isRMApproved,
                                    t?.isLeaveCancelled,
                                    t?.timeOffId
                                  );
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                              >
                                <path
                                  d="M11 1C5.48 1 1 5.48 1 11C1 16.52 5.48 21 11 21C16.52 21 21 16.52 21 11"
                                  stroke="#292D32"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 9.9998L20.2 1.7998"
                                  stroke="#292D32"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21 5.83V1H16.17"
                                  stroke="#292D32"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <OrganizationTimeOffEmpty />
              )}
            </div>
            {timeOffDetails && (
              <AdminTimeOffDetailsModal
                onClose={() => setTimeOffDetails(false)}
                timeoffId={timeoffId}
                timeOffApprove={timeOffApprove}
                setTimeOffDetails={setTimeOffDetails}
                orgnisationList={orgnisationList}
                timeOffCancel={timeOffCancel}
                type={type}
                userProfile={userProfile}
                getSettingList={getSettingList}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationTimeOff;
