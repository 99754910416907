import { API_URL } from "../sharedService/constants";
import { http, httpAuth, httpEmployee } from "../sharedService/httpService";

export function createAdminTimeoff(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/TimeOffSetting/Create`,
    reqBody
  );
}

export function getAdminTimeoffList(reqBody: any) {
  var nevUrl;
  if (reqBody.SearchString === undefined || reqBody.SearchString === "") {
    nevUrl = `api/TimeOffSetting/List?Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`;
  } else {
    nevUrl = `api/TimeOffSetting/List?Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&SearchString=${reqBody.SearchString}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function deleteTimeOff(Id: string) {
  return http.delete(`${API_URL.INTEGRATION_API_URL}api/TimeOffSetting/${Id}`);
}

export function updateTimeOff(id: string, reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeOffSetting/${id}`,
    reqBody
  );
}

export function getFinancialYearList() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/FinancialYear/List`);
}

export function getLeaveType() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/TimeOffSetting/Leaves`);
}

export function getLeaveBalance(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetLeaveBalance?leaveCode=${reqBody.leaveCode}&identityId=${reqBody.identityId}&fyId=${reqBody.fyId}`
  );
}

export function createTimeoff(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}api/TimeOff/Create`, reqBody);
}

export function getSummaryTimeoff(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetSummarizedTimeOffRequests?IsEmployee=${reqBody.IsEmployee}`
  );
}

export function getLeaveStatistics() {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetLeaveStatistics`
  );
}

export function getDetailTimeoff(timeOffId: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetDetailedTimeOffRequest/${timeOffId}`
  );
}

export function getMyTimeOff(reqBody: any) {
  var nevUrl;
  if (reqBody.SearchString === undefined || reqBody.SearchString === "") {
    nevUrl = `api/TimeOff/GetMyTimeOffs`;
  } else {
    nevUrl = `api/TimeOff/GetMyTimeOffs?SearchString=${reqBody.SearchString}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getCalenderTimeOff(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetCalenderViewTimeOffs?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}`
  );
}

export function getTeamLeaveTimeoff(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetLeaveApplicationsForApprover?page=${reqBody.page}&pageSize=${reqBody.pageSize}&IsEmployee=${reqBody.IsEmployee}`
  );
}

export function getTeamTimeOffs(reqBody: any) {
  var nevUrl;
  if (reqBody.LeaveStatus === "") {
    nevUrl = `api/TimeOff/GetTeamTimeOffs?Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&LeaveId=${reqBody.LeaveId}&ViewType=${reqBody.ViewType}`;
  } else {
    nevUrl = `api/TimeOff/GetTeamTimeOffs?LeaveId=${reqBody.LeaveId}&LeaveStatus=${reqBody.LeaveStatus}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&ViewType=${reqBody.ViewType}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getTeamTimeOffCalenderView(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetTeamTimeOffCalenderView?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}`
  );
}

export function approveTimeoff(id: string, isARM: boolean, reqBody: any) {
  return http.patch(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/ApproveTimeOffRequest/${id}/${isARM}`,
    reqBody
  );
}

export function getOrganisationsOffs(reqBody: any) {
  var nevUrl;
  if (reqBody.LeaveStatus === "") {
    nevUrl = `api/TimeOff/GetOrganisationsOffs?Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&Team=${reqBody.Team}&LeaveCode=${reqBody.LeaveCode}`;
  } else {
    nevUrl = `api/TimeOff/GetOrganisationsOffs?Team=${reqBody.Team}&LeaveCode=${reqBody.LeaveCode}&LeaveStatus=${reqBody.LeaveStatus}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function cancelTimeoff(id: string, reqBody: any) {
  return http.patch(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/CancelTimeOffRequest/${id}`,
    reqBody
  );
}

export function getMyTeamPanel(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetMyTeamPanel?IsEmployee=${reqBody.IsEmployee}`
  );
}

export function getTimeOffByDate(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOff/GetTimeOffsByDate?CalenderDate=${reqBody.CalenderDate}&IsEmployee=${reqBody.IsEmployee}`
  );
}

export function getEnumList(value: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeOffSetting/${value}/Options`
  );
}
