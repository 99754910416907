import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { Dropdown } from "../../../components/DropDown/DropDown";
import SearchBoxNormal from "../SearchBoxNormal";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { getDayAndMonth } from "../../../utils/common";
import {
  getLeaveStatisticsAsync,
  getTeamTimeOffsAsync,
} from "../../../services/reducers/leaveReducer";
import { useAppDispatch } from "../../../hooks";
import { Dispatch, SetStateAction } from "react";
import EchopulseLoader from "../../loader/EchopulseLoader";
import { useDebounce } from "../../../utils/hooks/useDebounce";

type Props = {
  onClose: () => void;
  timeHistory?: any;
  selectedValue: any;
  setSelectedValue: Dispatch<SetStateAction<{ label: string; value: string }>>;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchString: string;
};

const TeamTimeOffHistoryModal: React.FC<Props> = ({
  onClose,
  setSelectedValue,
  selectedValue,
  setSearchString,
  searchString,
}) => {
  const dispatch = useAppDispatch();
  const [leavebalance, setLeavebalance] = useState<any>([]);
  const [timeHistory, setTimeHistory] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const getMonthName = (monthNumber: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };

  const getTimeoffList = () => {
    setLoading(true);
    const request: any = {
      Page: 1,
      PageSize: 100,
      MemberName: searchString,
      LeaveId: selectedValue ? selectedValue.value : "",
    };
    dispatch(getTeamTimeOffsAsync(request)).then((res) => {
      if (res.payload) {
        setTimeHistory(res.payload?.records);
        setLoading(false);
      }
    });
  };

  const debouncedSearch = useDebounce(getTimeoffList, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [selectedValue.value, searchString]);

  useEffect(() => {
    dispatch(getLeaveStatisticsAsync()).then((res) => {
      if (res.payload) {
        setLeavebalance(res.payload);
      }
    });
  }, []);

  const handleDropdownChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
  };

  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="!ps-20 bg-gray-200">
        <Typography className="font-bold" variant="h3">
          Time off History
        </Typography>
      </ModalHeader>
      <ModalBody className="!ps-20 !pe-10 !pt-0 !pb-6">
        <div className="flex justify-between items-center sticky left-0 top-0 z-10 bg-white pt-10 pb-3">
          <div className="flex justify-end items-center gap-7">
            <div className="w-[13.75rem]">
              <SearchBoxNormal
                searchString={searchString}
                setSearchString={setSearchString}
              />
            </div>
            <div className="flex justify-end items-center gap-2">
              <div className="text-400 font-medium text-gray-300">
                Leave Type
              </div>
              <div className="w-[10.875rem]">
                <Dropdown
                  onChange={handleDropdownChange}
                  options={[
                    { label: "All", value: "" },
                    ...(leavebalance?.map((org: any) => ({
                      label: org.leaves.title,
                      value: org.leaves.leaveId,
                    })) || []),
                  ]}
                  type="smbox"
                  isSearchable={false}
                  value={selectedValue}
                />
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <tr>
            <td colSpan={3}>
              <EchopulseLoader />
            </td>
          </tr>
        )}
        {!loading &&
          timeHistory?.length > 0 &&
          timeHistory.map((t: any) => (
            <>
              <div className="pt-3" key={`${t.year}-${t.month}`}>
                <div className="text-200 font-bold text-gray-300">
                  {" "}
                  {getMonthName(t?.month)} {t?.year}
                </div>
                {t.details &&
                  t.details.map((item: any) => (
                    <div
                      className="flex justify-start items-center py-3 border-b border-black border-opacity-10"
                      key={item.id}
                    >
                      <div className="w-[40%] flex justify-start items-center gap-3">
                        <div>
                          {item.myProfile?.profilePic ? (
                            <UserImage
                              imgKey={item?.myProfile?.profilePic}
                              className="w-9 h-9 rounded-full object-cover"
                            />
                          ) : (
                            <img
                              src={getInitialsInName(
                                item?.myProfile?.name ?? "N A",
                                24
                              )}
                              alt={item?.myProfile?.name}
                              className="w-9 h-9 rounded-full object-cover"
                            />
                          )}
                        </div>
                        <div>
                          <div className="text-200 text-gray-300 font-bold">
                            {item?.myProfile?.name}
                          </div>
                          <div className="text-100 text-gray-700 font-normal">
                            {item?.myProfile?.designation} |{" "}
                            {item?.myProfile?.team}
                          </div>
                        </div>
                      </div>
                      <div className="w-[20%]">
                        <div className="text-200 text-gray-300 font-bold">
                          {item?.requestFor}d
                        </div>
                        <div className="text-100 text-gray-700 font-normal">
                          {getDayAndMonth(item?.startDate).month}{" "}
                          {getDayAndMonth(item?.startDate).day} -{" "}
                          {getDayAndMonth(item?.endDate).month}{" "}
                          {getDayAndMonth(item?.endDate).day}
                        </div>
                      </div>
                      <div className="w-[20%]">
                        <div className="text-200 text-gray-300 font-bold">
                          {t?.myProfile?.team}
                        </div>
                      </div>
                      <div className="w-[20%] flex justify-end items-center">
                        <div className="bg-green-100 text-100 font-normal px-2.5 py-1 rounded-full text-white">
                          {item?.leave?.title}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ))}
      </ModalBody>
      <ModalFooter className="!ps-20">
        <Button label="Okay" variant="primary" size="large" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};

export default TeamTimeOffHistoryModal;
