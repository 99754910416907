import React, { useState } from 'react';

export interface Tab {
  id: string;
  label: string;
  content: React.ReactNode;
}

export interface TabsProps {
  tabs: Tab[];
  className?: string;
  onClick: (tabId: string) => void;
  size:"sm" | "md";
  classNameNav?: string;
  classNamePane?: string;
  defaultActiveTab?: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, className, onClick, size, classNameNav, classNamePane, defaultActiveTab}) => {
  // const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0]?.id);
  const handleTabClick = (id: string) => {
    setActiveTab(id);
    onClick(id); 
  };

  return (
    <div className={` ${className ? className : ""} ${size === "md" ? "" : ""}`}>
      <div className={`flex gap-5 text-gray-300 text-200 addPadding ${size === "md" ? "text-400" : ""} ${classNameNav ? classNameNav : ""} `}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`cursor-pointer border-b-2 border-b-none tab ${size === "md" ? "pb-5" : "pb-3"} ${activeTab === tab.id ? 'active font-bold border-b-primary-100' : ''}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={`${size === "md" ? "pt-0" : "pt-3"} ${classNamePane ? classNamePane : ""}`}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
          >
            {activeTab === tab.id && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
