import React from "react";
import Typography from "../../../components/Typography/Typography";

const TimeOffCalenderEmpty = () => {
  return (
    <div className="flex justify-center items-center h-full w-full text-center">
      <div>
        <div className="w-[12.875rem] flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
          >
            <circle cx="40" cy="40" r="40" fill="#F4F4F4" />
            <mask
              id="mask0_8713_7497"
              mask-type="alpha"
              mask-t
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="80"
              height="80"
            >
              <circle cx="40" cy="40" r="40" fill="#F4F4F4" />
            </mask>
            <g mask="url(#mask0_8713_7497)">
              <path
                d="M58.59 31.4417V47.8559C57.0655 46.1226 54.831 45.0158 52.325 45.0158C47.7098 45.0158 43.9717 48.7539 43.9717 53.3691C43.9717 54.9354 44.4102 56.4181 45.1829 57.6711C45.6214 58.4229 46.1853 59.0912 46.8327 59.6341H31.4417C24.1325 59.6341 21 55.4575 21 49.1925V31.4417C21 25.1767 24.1325 21 31.4417 21H48.1483C55.4575 21 58.59 25.1767 58.59 31.4417Z"
                fill="white"
                stroke="#EFEFEF"
                stroke-width="1.78527"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect x="28" y="40" width="3" height="3" rx="1" fill="#EFEFEF" />
              <rect x="28" y="47" width="3" height="3" rx="1" fill="#EFEFEF" />
              <rect x="37" y="40" width="3" height="3" rx="1" fill="#2EB67D" />
              <rect x="37" y="47" width="3" height="3" rx="1" fill="#EFEFEF" />
              <rect x="46" y="40" width="3" height="3" rx="1" fill="#EFEFEF" />
              <g opacity="0.5">
                <path
                  d="M60.9435 54.6824C60.9435 56.2487 60.505 57.7314 59.7323 58.9844C58.2914 61.4069 55.6392 63.0358 52.5902 63.0358C50.481 63.0358 48.5597 62.2631 47.0979 60.9474C46.4505 60.4045 45.8867 59.7362 45.4481 58.9844C44.6754 57.7314 44.2369 56.2487 44.2369 54.6824C44.2369 50.0672 47.975 46.3291 52.5902 46.3291C55.0962 46.3291 57.3307 47.4359 58.8552 49.1692C60.15 50.6519 60.9435 52.5732 60.9435 54.6824Z"
                  fill="white"
                  stroke="#2EB67D"
                  stroke-width="1.78527"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M49.3324 54.6794L51.3998 56.7468L55.848 52.6328"
                  stroke="#2EB67D"
                  stroke-width="1.78527"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <path
                d="M31.7068 19.1768V25.4418"
                stroke="#EFEFEF"
                stroke-width="1.78527"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M48.4135 19.1768V25.4418"
                stroke="#EFEFEF"
                stroke-width="1.78527"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.3094 33.9844H57.811"
                stroke="#EFEFEF"
                stroke-width="1.78527"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          All of your team time off data will<br></br>
          be shown here
          {/* Your team’s time calendar will<br></br> be shown here. */}
        </Typography>
      </div>
    </div>
  );
};

export default TimeOffCalenderEmpty;
