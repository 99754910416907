import React, { useState } from "react";

export interface TooltipProps {
  /**
   * Content which will be shown inside the tooltip.
   */
  content: string;
  /**
   * A component which will trigger the tooltip..
   */
  children: React.ReactNode;
  /**
   * Defines which action will trigger the tooltip: "hover", "click" or "focus".
   */
  trigger?: boolean;
  /**
   * Can be "left","right","top","bottom", "topLeft", "topRight", "bottomLeft" or "bottomRight".
   */
  placement?: string;
  
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  trigger,
  placement= "top",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsClicked(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    setIsHovered(false);
  };
  
  const ToolTipData = () => {
    return (
      <div className={`absolute  min-w-full w-auto max-w-xs z-[30] 
      ${placement === "top" ? "pb-2 bottom-full" : ""}
      ${placement === "bottom" ? "bottom-auto pt-2 top-full" : ""}
      ${placement === "right" ? "top-2/4 -translate-y-2/4 bottom-auto pl-2 left-full" : ""}
      ${placement === "left" ? "top-2/4 -translate-y-2/4 bottom-auto pr-2 right-full" : ""}
      `}>
        <div className="bg-white text-gray-300 py-3 rounded-md min-w-[12rem] max-w-[12rem] text-200 font-medium text-center mx-auto drop-shadow-lg">
          {content}
        </div>
        <div className={`h-2 w-2 bg-white -rotate-45  absolute mx-auto 
        ${placement === "top" ? "-translate-y-1/2 bottom-0 left-0 right-0" : ""}
        ${placement === "bottom" ? "bottom-auto top-0 translate-y-1/2 left-0 right-0" : ""}
        ${placement === "right" ? "top-2/4 -translate-y-2/4 left-0 translate-x-2/4" : ""}
        ${placement === "left" ? "top-2/4 -translate-y-2/4 right-0 -translate-x-2/4" : ""}
        `}>
        </div>
      </div>
    )
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={`relative inline-block w-full`}
    >
      {children}
      {trigger
        ? isHovered && <ToolTipData/>
        : isClicked && <ToolTipData/>}
    </div>
  );
};

export default Tooltip;
