import React from "react";
import TimeTrackerTab from "./TimeTrackerTab";

const TimeTracker = () => {
  return (
    <div>
      <div className="pt-1.5">
        <TimeTrackerTab />
      </div>
    </div>
  );
};

export default TimeTracker;
