import React, { useEffect, useRef, useState } from "react";
import Tabs from "../../components/Tabs/Tab";
import MyProfile from "./MyProfile";
import Integration from "./Integration";
import TeamSettings from "./TeamSettings";
import Button from "../../components/Button/Button";
import AddIntegrationModal from "./AddIntegrationModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getIntegrationListAsync,
  getUserDetailsAsync,
  getUserProfileAsync,
} from "../../services/reducers/appReducer";
import { getOrganizationListAsync } from "../../services/reducers/adminReducer";
import IntegrationEmpty from "./IntegrationEmpty";

const SettingsTab = () => {
  const [addIntegration, setAddIntegration] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [userDetail, setUserDetail] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("tab1");
  const { userProfile } = useAppSelector((state: any) => state.app);
  const [list, setList] = useState<any>([]);
  const email = userProfile?.companyEmail;
  const isFetching = useRef(false);
  
  useEffect(() => {
    if (activeTab === "tab2") {
    getOrgList();
    }
    dispatch(getUserProfileAsync());
  }, [activeTab]);

  const getOrgList = () => {
    if (isFetching.current) return; 

    isFetching.current = true;
    dispatch(getOrganizationListAsync()).then((res) => {
      if (res.payload) {
        setUserDetail(res.payload.records);
        isFetching.current = false;
      }
    });
  };

  useEffect(() => {
    if (activeTab === "tab2") {
      getList();
    }
  }, [activeTab, userProfile]);

  const getList = () => {
    // if (isFetching.current) return; 

    // isFetching.current = true;
    dispatch(getIntegrationListAsync()).then((res) => {
      if (res.payload) {
        setList(res.payload.records);
        // isFetching.current = false;
      }
    });
  };

  return (
    <div className="w-full">
      <Tabs
        className="[&>div.gap-5]:ps-1"
        onClick={(id) => setActiveTab(id)}
        size="md"
        tabs={[
          {
            content: (
              <div>
                <MyProfile userProfile={userProfile} loading={loading} />
              </div>
            ),
            id: "tab1",
            label: "My Profile",
          },
          {
            content: (
              <div className="relative">
                {list?.length > 0 ? (
                  <Integration
                    userProfile={email}
                    list={list}
                    roleName={userProfile?.roleName}
                    getList={getList}
                  />
                ) : (
                  <IntegrationEmpty />
                )}
                <div className="absolute right-0 -top-[3.375rem]">
                  <Button
                    onClick={() => setAddIntegration(true)}
                    label="Add Integration"
                    variant="primary"
                    size="extramedium"
                  />
                  {addIntegration && (
                    <AddIntegrationModal
                      onClose={() => setAddIntegration(false)}
                      setAddIntegration={setAddIntegration}
                      getList={getList}
                      userDetail={userDetail}
                    />
                  )}
                </div>
              </div>
            ),
            id: "tab2",
            label: "Integrations",
          },
          {
            content:
              userProfile?.roleName === "Super Admin" ||
              userProfile?.roleName === "Admin" ? (
                <div>
                  <TeamSettings list={list} />
                </div>
              ) : null,
            id: "tab3",
            label:
              userProfile?.roleName === "Super Admin" ||
              userProfile?.roleName === "Admin"
                ? "Team Settings"
                : "",
          },
        ]}
      />
    </div>
  );
};

export default SettingsTab;
