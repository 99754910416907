import React from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  isSubmitting:any;
  isValid:any;
};
const SaveChanges: React.FC<Props> = ({isSubmitting, isValid}) => {
  const navigate = useNavigate();

  return (
    <div className="px-8 py-5 bg-white rounded-b-md flex justify-start items-center gap-5 border-t border-gray-100">
      <Button label="Save" variant="primary" size="large" isDisabled={!isValid || isSubmitting}/>
    </div>
  );
};

export default SaveChanges;
