"use client";
import React from "react";
import Select, { OptionProps } from "react-select";

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  className?: string;
  value?: string;
  defaultValue?: string;
  background?: boolean;
  isLastOptionRed?: boolean; // New prop
}

const ActionDropdownSm: React.FC<DropdownProps> = ({
  options,
  onSelect,
  className,
  value,
  defaultValue,
  background,
  isLastOptionRed,
}) => {
  const handleSelect = (selectedOption: DropdownOption | null) => {
    const value = selectedOption ? selectedOption.value : "";
    onSelect(value);
  };

  const selectedOption = options.find(
    (option) => option.value === defaultValue
  );

  const customSingleValue = () => (
    <div
      className={`input-select p-0 py-1 rounded-md cursor-pointer ${
        background ? "bg-gray-200" : ""
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="2"
        viewBox="0 0 10 2"
        fill="none"
      >
        <circle cx="1" cy="1" r="1" fill="#3C3C3C" />
        <circle cx="5" cy="1" r="1" fill="#3C3C3C" />
        <circle cx="9" cy="1" r="1" fill="#3C3C3C" />
      </svg>
    </div>
  );

  return (
    <div className="flex justify-end">
      <div className={`w-auto inline-flex ms-auto ${className || ""}`}>
        <Select
          options={options}
          onChange={handleSelect}
          value={selectedOption}
          unstyled
          classNames={{
            input: () => "py-2",
            control: (state) =>
              `bg-none p-0 rounded-md border-0 border-gray-100 text-200 text-gray-100 font-medium 
              ${state.isFocused ? "" : ""} ${state.menuIsOpen ? "" : ""}`,
            placeholder: () => "text-gray-500 pl-1 py-0.5",
            dropdownIndicator: () => "text-gray-500 pl-1 py-0.5",
            valueContainer: () => "!flex",
            menu: () =>
              "bg-white border border-gray-400 rounded-md -mt-2.5 min-w-[10rem] right-0 absolute top-0 right-0 z-20 shadow-700 extra text-200",
            option: (props: OptionProps<DropdownOption, false>) => {
              const { isFocused, isSelected, data, options } = props;
              const isLastOption = options.indexOf(data) === options.length - 1;
              return `px-5 py-3 relative text-gray-300 font-medium hover:cursor-pointer hover:bg-gray-400 hover:text-gray-300 
              ${isSelected ? "" : ""}
              ${isFocused ? "" : ""}
              ${
                isLastOption && isLastOptionRed
                  ? "text-primary-100 hover:text-primary-100"
                  : ""
              }`;
            },
            noOptionsMessage: () =>
              "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
          }}
          isSearchable={false}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            SingleValue: customSingleValue,
          }}
        />
      </div>
    </div>
  );
};

export default ActionDropdownSm;
