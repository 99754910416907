import React from "react";
import Typography from "../../components/Typography/Typography";
import screenshotEmpty from "../../../src/images/screenshotEmpty.svg";

type Props = {
  type: string;
};

const ScreenshotsEmpty: React.FC<Props> = ({ type }) => {
  return (
    <div className="flex justify-center items-center mt-8 h-[calc(100%-5rem)] text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={screenshotEmpty}
            alt="screenshotEmpty"
            className="w-[6.938rem] h-[6.938rem]"
          />
        </div>
        {type === "Employee" ? (
          <Typography
            className="text-gray-700 font-normal mt-2.5"
            size="lg"
            variant="p"
          >
            Captured Screenshots for your<br></br> profile will be presented
            here.
          </Typography>
        ) : type === "Teams" ? (
          <Typography
            className="text-gray-700 font-normal mt-2.5"
            size="lg"
            variant="p"
          >
            Selected employees<br></br> will be listed here.
          </Typography>
        ) : (
          <Typography
            className="text-gray-700 font-normal mt-2.5"
            size="lg"
            variant="p"
          >
            Captured Screenshots <br></br> will be presented here.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ScreenshotsEmpty;
