import React from "react";
import Typography from "../../components/Typography/Typography";
import { Dropdown } from "../../components/DropDown/DropDown";
import {
  convertMinutesToHoursAndMinutes2,
  formatTime,
} from "../../utils/common";
import TimeSpentProjectEmpty from "./TimeSpentProjectEmpty";

type Props = {
  timeSpentProject: any;
};
const TimeSpentProject: React.FC<Props> = ({ timeSpentProject }) => {
  const calculateMaxMinutes = (timeSpentProject: any) => {
    let maxMinutes = 0;
    timeSpentProject &&
      timeSpentProject.forEach((item: any) => {
        if (item.spentHours > maxMinutes) {
          maxMinutes = item.spentHours;
        }
      });
    return maxMinutes;
  };

  const minutesToPercentage = (minutes: number, maxMinutes: number) => {
    return (minutes / maxMinutes) * 100;
  };
  const maxMinutes = calculateMaxMinutes(timeSpentProject);
  
  return (
    <div className="border border-gray-100 shadow-300 py-8 bg-white rounded-md relative h-[23.5rem]">
      <div className="mb-3">
        <Typography variant="h5" className="text-gray-300 font-bold px-6">
          Time Spent on Projects
        </Typography>
      </div>
      <div className="pe-4 w-full h-[calc(100%-2.5rem)] px-6 overflow-auto">
        {timeSpentProject?.length > 0 ? (
          <>
            {timeSpentProject.some((t: any) => t.spentHours > 0) ? (
              timeSpentProject.map(
                (t: any, index: number) =>
                  t.spentHours > 0 && (
                    <div className="flex items-center mt-5" key={index}>
                      <div className="w-[6rem] me-6 text-left text-500 font-normal text-gray-300 line-clamp-2 break-words">
                        {t.projectName}
                      </div>
                      <div
                        className="h-5 min-w-[0.5rem] w-full max-w-[calc(100%-13.875rem)] bg-gray-100"
                        style={{
                          width: `${minutesToPercentage(
                            t?.spentHours,
                            maxMinutes
                          )}%`,
                        }}
                      ></div>
                      <div className="ps-1.5 w-[6.2rem] text-left text-400 font-medium text-gray-300">
                        {convertMinutesToHoursAndMinutes2(t?.spentHours)}
                      </div>
                    </div>
                  )
              )
            ) : (
              <TimeSpentProjectEmpty />
            )}
          </>
        ) : (
          <TimeSpentProjectEmpty />
        )}
      </div>
    </div>
  );
};

export default TimeSpentProject;
