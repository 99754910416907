import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
} from "../../../../components/Modal/Modal";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import PushBackDoneModal from "./PushBackDoneModal";
import { formatDate } from "../../../../utils/common";
import { useAppDispatch } from "../../../../hooks";
import { TOASTIFY_CONF } from "../../../../services/sharedService/constants";
import { toast } from "react-toastify";
import { timeSheetPushbackAsync } from "../../../../services/reducers/teamsReducer";
type Props = {
  onClose: () => void;
  getteambyList: () => void;
  startDate: any;
  endDate: any;
  id: string;
  setPushBackConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  comments: any;
  timesheetId: string;
  userLists: any;
  setIsPushback: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClickShow:React.Dispatch<React.SetStateAction<any>>;
};
const PushBackConfirmation: React.FC<Props> = ({
  onClose,
  getteambyList,
  startDate,
  endDate,
  id,
  setPushBackConfirm,
  comments,
  timesheetId,
  userLists,
  setIsPushback,
  setShowClickShow
}) => {
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handlePushback = () => {
    // const flattenedComments = Object.entries(comments).flatMap(
    //   ([timeSheetId, commentsArray]) =>
    //     (commentsArray as string[]).map((comment) => ({ timeSheetId, comment }))
    // );
    const flattenedComments = Object.entries(comments).flatMap(
      ([timeSheetId, commentsArray]) =>
        (commentsArray as { text: string; time: string }[]).map((commentObj) => ({
          timeSheetId,
          comment: commentObj.text,
        }))
    );

    const values = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      actionOnTimeSheet: 1,
      comments: flattenedComments,
      memberId: id,
    };
    dispatch(timeSheetPushbackAsync(values)).then(() => {
      toast.success(`Timesheet Pushed back Successfully`, TOASTIFY_CONF);
      setPushBackConfirm(false);
      getteambyList();
      setIsPushback(true);
      setShowClickShow({ taskId: "", date: "" });
    });
  };
  const identityUser =
    userLists && userLists.find((user: any) => user.identityId === id);
  return (
    <>
      <Modal isOpen onClose={onClose} placement="center" size="normal">
        <ModalBody className="ps-10 pt-10 pb-20 pe-16">
          <Typography className="font-bold text-gray-300" variant="h3">
            {/* Are you sure you want to push back {identityUser?.name} timesheet? */}
            Are you sure you want to push back this timesheet?
          </Typography>
          <Typography
            size="xxl"
            variant="p"
            className="text-gray-700 font-normal mt-1"
          >
            Pushing back this timesheet will return it to the employee for revisions and re-submission. Please confirm if you want to proceed.
          </Typography>
        </ModalBody>
        <ModalFooter className="!px-10 bg-gray-200">
          <div className="flex justify-end gap-3 w-full">
            <Button
              label="Cancel"
              onClick={onClose}
              variant="outline"
              size="large"
            />
            <Button
              label="Yes, I am sure"
              onClick={() => handlePushback()}
              variant="primary"
              size="large"
            />
          </div>
        </ModalFooter>
      </Modal>
      {/* {submitConfirm && (
        <PushBackDoneModal onClose={() => setSubmitConfirm(false)} />
      )} */}
    </>
  );
};

export default PushBackConfirmation;
