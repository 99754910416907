const getRandomIndex = (maxIndex: number): number => {
    let newIndex: number;
    let previousRandomIndex: number | null = null;
    do {
      newIndex = Math.floor(Math.random() * maxIndex);
    } while (newIndex === previousRandomIndex);
    previousRandomIndex = newIndex;
    return newIndex;
  };
  
  const generateProfilePicture = (initials: string, size: number): string => {
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");
  
    // List of available text colors
    const colors: string[] = [
      "#F15845", // Orange Red
      "#0080FF", // Secondary/Blue
      "#2EB67D", // Secondary/Green
      "#4A154B", // Primary/Dark Magenta
      "#36C5F0", // Secondary/Aqua
      "#ECB22E", // Secondary/Yellow
      "#E3828D", // Secondary/Watermelon
      // "#888888", // Gray Shades/Gray60
    ];
  
    // List of available bg colors
    const bgcolors: string[] = [
      "#F6EFE8", // Orange Red
      "#E9EFF5", // Secondary/Blue
      "#E9F3E4", // Secondary/Green
      "#E5D5E8", // Primary/Dark Magenta
      "#E2F5FF", // Secondary/Aqua
      "#FBF8DF", // Secondary/Yellow
      "#F6EFE8", // Secondary/Watermelon
      // "#F8F8F8", // Gray Shades/Gray60
    ];
  
    // Pick a random index for the colors and use the same index for bgcolors
    const randomIndex = getRandomIndex(colors.length);
    const randomColor = colors[randomIndex];
    const randomBgColor = bgcolors[randomIndex];
  
    ctx!.beginPath();
    ctx!.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    ctx!.fillStyle = randomBgColor;
    ctx!.fill();
    // Draw the initials
    ctx!.fillStyle = randomColor;
    ctx!.font = `${size / 2}px Arial`;
    ctx!.textAlign = "center";
    ctx!.textBaseline = "middle";
    ctx!.fillText(initials, size / 2, size / 2);
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL();
    return dataURL;
  };
  
  const getInitialsName = (name: string): string => {
    let parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials.toUpperCase().slice(0, 2);
  };
  
  const getInitialsInName = (name: string, size: number): string => {
    const initials = getInitialsName(name);
    const profilePictureUrl = generateProfilePicture(initials, size);
    return profilePictureUrl;
  };
  
  export default getInitialsInName;
  