import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
} from "../../../../components/Modal/Modal";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import ApprovedDoneModal from "./ApprovedDoneModal";
import { formatDate } from "../../../../utils/common";
import { useAppDispatch } from "../../../../hooks";
import { timeSheetApprovalAsync } from "../../../../services/reducers/teamsReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../../services/sharedService/constants";

type Props = {
  onClose: () => void;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  getteambyList: () => void;
  startDate: any;
  endDate: any;
  id: string;
  setApproveConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  userLists: any;
  setShowClickShow:React.Dispatch<React.SetStateAction<any>>;
};
const ApproveConfirmation: React.FC<Props> = ({
  onClose,
  setIsSubmitted,
  getteambyList,
  startDate,
  endDate,
  id,
  setApproveConfirm,
  userLists,
  setShowClickShow
}) => {
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleApprove = () => {
    const values = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      actionOnTimeSheet: 2,
      memberId: id,
    };
    dispatch(timeSheetApprovalAsync(values)).then(() => {
      toast.success(`Timesheet Approved Successfully`, TOASTIFY_CONF);
      setApproveConfirm(false);
      getteambyList();
      setIsSubmitted(true);
      setShowClickShow({ taskId: "", date: "" });
    });
  };
  const identityUser =
    userLists && userLists.find((user: any) => user.identityId === id);
  return (
    <>
      <Modal isOpen onClose={onClose} placement="center" size="normal">
        <ModalBody className="ps-10 pt-10 pb-20 pe-16">
          <Typography className="font-bold text-gray-300" variant="h3">
            {/* Are you sure you want to approve {identityUser?.name} timesheet? */}
            Are you sure you want to approve this timesheet?
          </Typography>
          <Typography
            size="xxl"
            variant="p"
            className="text-gray-700 font-normal mt-1"
          >
           Please ensure all entries are accurate before confirming.
          </Typography>
        </ModalBody>
        <ModalFooter className="!px-10 bg-gray-200">
          <div className="flex justify-end gap-3 w-full">
            <Button
              label="Cancel"
              onClick={onClose}
              variant="outline"
              size="large"
            />
            <Button
              label="Yes, I am sure"
              onClick={() => handleApprove()}
              variant="primary"
              size="large"
            />
          </div>
        </ModalFooter>
      </Modal>
      {/* {submitConfirm && (
        <ApprovedDoneModal onClose={() => setSubmitConfirm(false)} />
      )} */}
    </>
  );
};

export default ApproveConfirmation;
