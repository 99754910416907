import React, { ReactNode } from "react";
interface ColorLabelProps {
  text: string; // Input string that determines the color
  className?:string;
  children?: ReactNode;
}
const stringToHexColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color;
};
const ColorLabel: React.FC<ColorLabelProps> = ({ text, className,children }) => {
  const colorCode = stringToHexColor(text);
  return (
    <div className={`colorDots ${className}`} 
      style={{
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        backgroundColor: colorCode,
        margin: "0 auto",
      }}
    >
      {children}
    </div>
  );
};
export default ColorLabel;
