import React, { useEffect, useRef, useState } from "react";
import { getJiraTaskAsync } from "../../services/reducers/timeReducer";
import { useAppDispatch } from "../../hooks";
import styled from "styled-components";
import { useDebounce } from "../../utils/hooks/useDebounce";

export const CustomDropdownSearch = styled.div`
  input {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.7489 7.23123C21.4142 6.92292 20.8715 6.92292 20.5368 7.23123L12 15.094L3.46323 7.23123C3.1285 6.92292 2.58578 6.92292 2.25105 7.23123C1.91632 7.53954 1.91632 8.03941 2.25105 8.34772L11.3939 16.7688C11.5547 16.9168 11.7727 17 12 17C12.2273 17 12.4453 16.9168 12.6061 16.7688L21.7489 8.34772C22.0837 8.03941 22.0837 7.53954 21.7489 7.23123Z' fill='%23232323' /%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 1rem 1rem;
  }
`;

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  setSelectedOption: any;
  selectedValues: any;
  task: any;
  isRecentProjectSelected: boolean;
  setIsRecentProjectSelected: React.Dispatch<React.SetStateAction<boolean>>;
  showProjects: boolean;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  isTaskLoading: boolean;
  setIsTaskLoading: React.Dispatch<React.SetStateAction<boolean>>;
  filteredOptions: any;
  setFilteredOptions: any;
  selectedProject:any;
}

export const CustomDropdown: React.FC<DropdownProps> = ({
  setSelectedOption,
  selectedValues,
  task,
  isRecentProjectSelected,
  setIsRecentProjectSelected,
  showProjects,
  searchTerm,
  setSearchTerm,
  isTaskLoading,
  setIsTaskLoading,
  setFilteredOptions,
  filteredOptions,
  selectedProject
}) => {
  //const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  //const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  // const [searchTerm, setSearchTerm] = useState<string>('');
  //const [filteredOpt, setFilteredOpt] = useState<any>([]);
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredOptions([]);
    }
  }, [searchTerm]);

  // const search = () => {
  //   setIsTaskLoading(true);

  //   if (searchTerm) {
  //     setFilteredOptions([]);
  //     const searchWords = searchTerm.split(/\s+/);
  //     const values: any = {
  //       ProjectKey: '',
  //       TaskKey: searchTerm,
  //     };

  //     Promise.all(
  //       searchWords.map((word) => {
  //         values.TaskKey = word;
  //         return dispatch(getJiraTaskAsync(values));
  //       })
  //     ).then((responses) => {
  //       const filtered = responses
  //         .map((res) => res.payload)
  //         .flat()
  //         .filter((option: any) =>
  //           option?.key?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //           option?.summary?.toLowerCase().includes(searchTerm.toLowerCase())
  //         );
  //       setIsTaskLoading(false)

  //       // setFilteredOpt(filtered);
  //       setIsRecentProjectSelected(false);
  //       setFilteredOptions(filtered);
  //     });
  //   } else if (searchTerm === '' || searchTerm === undefined) {
  //     const values: any = {
  //       ProjectKey: '',
  //       TaskKey: '',
  //     };
  //     if(isTaskLoading === true){
  //     setFilteredOptions([]);
  //     // setIsTaskLoading(true)
  //     }
  //     dispatch(getJiraTaskAsync(values)).then((res) => {
  //       if (res.payload) {
  //         setIsTaskLoading(false)
  //         //setFilteredOpt(res.payload.slice(0, 15));
  //         setIsRecentProjectSelected(false);
  //         setFilteredOptions(res.payload.slice(0, 15));
  //       }
  //       //setIsTaskLoading(false)
  //     });
  //   } else {
  //     setFilteredOptions([]);
  //     setIsTaskLoading(false)

  //   }
  // };
  const search = () => {
    setIsTaskLoading(true);

    if (searchTerm) {
      setFilteredOptions([]);
      const values: any = {
        ProjectKey: selectedProject ? selectedProject : "",
        TaskKey: searchTerm,
      };

      dispatch(getJiraTaskAsync(values)).then((res) => {
        if (res.payload) {
          setIsTaskLoading(false);
          setIsRecentProjectSelected(false);
          setFilteredOptions(res.payload.slice(0, 15));
        }else{
          setIsTaskLoading(false);
        }
      });
    } else if (searchTerm === "" || searchTerm === undefined) {
      const values: any = {
        ProjectKey: selectedProject ? selectedProject : "",
        TaskKey: "",
      };
   
      if (isTaskLoading === true) {
        setFilteredOptions([]);
        // setIsTaskLoading(true)
      }
      dispatch(getJiraTaskAsync(values)).then((res) => {
        if (res.payload) {
          setIsTaskLoading(false);
          //setFilteredOpt(res.payload.slice(0, 15));
          setIsRecentProjectSelected(false);
          setFilteredOptions(res.payload.slice(0, 15));
        }
        //setIsTaskLoading(false)
      });
    } else {
      setFilteredOptions([]);
      setIsTaskLoading(false);
    }
  };

  const debouncedSearch = useDebounce(search, 1000);
  useEffect(() => {
    debouncedSearch();
  }, [searchTerm]);
  useEffect(() => {}, [task, isRecentProjectSelected]);

  const handleSelectOption = (option: any) => {
    //debugger;
    // setSelectedOption(option);
    setSelectedOption(option);
    // if (selectedValues) {
    setIsDropdownOpen(false);
    // }
  };
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handlePaste = (e: any) => {
    const pastedText = e.clipboardData.getData("text/plain");

    // Use setTimeout to delay the state update
    setTimeout(() => {
      setSearchTerm(pastedText);
    }, 0);
  };

  return (
    <CustomDropdownSearch ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          // placeholder="Type to Search Tasks"
          placeholder={showProjects ? "Search tasks within projects" : "Search Tasks"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onPaste={(e) => handlePaste(e)}
          onFocus={handleToggleDropdown} // Open the dropdown when the text field is focused
          className="outline-none w-full bg-white border border-gray-100 px-4 py-[9px] text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
          focus:border-gray-900 focus:shadow-200
          disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700"
        />
        {/* {isTaskLoading && <div className="loader absolute left-[35%] top-1/2 -translate-y-1/2">
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#D9D9D9" stroke-width="4"></circle>
          <path className="opacity-75" fill="#4A154B" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        </div>} */}
        {isDropdownOpen && (
          <ul className="text-400 bg-white border border-gray-100 rounded-md rounded-t-0 shadow-200 min-w-full min-h-[2.5rem] max-h-[14rem] overflow-auto absolute left-0 top-[44px] z-50">
            {searchTerm && isTaskLoading && (
              <div className="loader absolute left-6 top-1/2 -translate-y-1/2 flex justify-start items-center gap-1">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#D9D9D9"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="#4A154B"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>{" "}
                Loading....
              </div>
            )}
            {isRecentProjectSelected ? (
              !searchTerm && task.length > 0 ? (
                task.map((option: any) => (
                  <li
                    className="text-400 px-3 py-2 relative font-medium text-gray-300 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-300"
                    key={option.key}
                    onClick={() => handleSelectOption(option)}
                    style={{ cursor: "pointer" }}
                  >
                    # {option.key}
                    {option.summary}
                  </li>
                ))
              ) : isTaskLoading ? null : (
                <li className="text-400 px-3 py-2 font-semibold">
                  No task found
                </li>
              )
            ) : filteredOptions.length > 0 ? (
              filteredOptions.map((option: any) => (
                <li
                  className="text-400 px-3 py-2 relative font-medium text-gray-300 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-300"
                  key={option.key}
                  onClick={() => handleSelectOption(option)}
                  style={{ cursor: "pointer" }}
                >
                  # {option.key}
                  <span className="mx-1.5 bg-gray-700 font-medium text-300 h-[14px] border-r border-gray-700 inline-block -mb-0.5"></span>
                  {option.summary}
                </li>
              ))
            ) : isTaskLoading ? null : (
              <li className="text-400 px-3 py-2 font-semibold">
                No task found
              </li>
            )}
          </ul>
        )}
      </div>
    </CustomDropdownSearch>
  );
};
