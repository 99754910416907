import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkUniqueness, checkUniquenessProject, closeTask, createAdminSetting, createProject, createTask, deleteProject, deleteTask, getDasboard, getEnumList, getOrganizationList, getProject, getProjectById, getProjectTeamMemberList, getProjects, getReport, getSettingList, getTask, getTaskById, getTeamMemberList, keySuggesstion, lockProject, unLockProject, updateProject, updateSetting, updateTask } from "../api/adminApis";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../sharedService/constants";

const initialState = {
    isLoading: false,
    isError: false,
    createSetting:{},
    getOrgnisationList:{},
    getSettingList:{},
    dasboardList:{},
    projectList:{},
    createProj:{},
    projectData:{},
    createTask:{},
    taskLists:{},
    teamMemberList:[],
    taskIdData:[],
    datas:{},
    projectTeamMemberList:[],
    getReport:[],
    restrictList:[],
    minutesList:[],
    screenShotList:[],
    timeOffApprovalList:[],
  };
export const createAdminSettingAsync = createAsyncThunk(
    "admin/createAdminSetting",
    async (request: any) => {
        const response = await createAdminSetting(request);
        return response.data;
    }
  );

  export const getOrganizationListAsync = createAsyncThunk(
    "admin/getOrganizationList",
    async () => {
        const response = await getOrganizationList();
        return response.data;
    }
  );

  export const getSettingListAsync = createAsyncThunk(
    "admin/getSettingList",
    async () => {
        const response = await getSettingList();
        return response.data;
    }
  );

  export const updateSettingAsync = createAsyncThunk(
    'timesheet/update',
    async ({ id, reqBody }: { id: string, reqBody: any }) => {
      const response = await updateSetting(id, reqBody);
      // console.log(response.data, "data");
      return response.data;
    }
  );

  export const getDasboardAsync = createAsyncThunk(
    "dashboard/getDasboard",
    async (request: any) => {
      const response = await getDasboard(request);
      return response.data;
    }
  );

  export const getProjectAsync = createAsyncThunk(
    "project/getProject",
    async (request: any) => {
      const response = await getProject(request);
      return response.data;
    }
  );

  export const createProjectAsync = createAsyncThunk(
    "project/createProject",
    async (request: any) => {
        const response = await createProject(request);
        return response.data;
    }
  ); 

  export const deleteProjectAsync = createAsyncThunk(
    "project/deleteProject",
    async (Id:string) => {
      const response = await deleteProject(Id);
      return response.data;
    }
  );

  export const updateProjectAsync = createAsyncThunk(
    'project/update',
    async ({ id, reqBody }: { id: string, reqBody: any }) => {
      const response = await updateProject(id, reqBody);
      return response.data;
    }
  );

  export const getProjectByIdAsync = createAsyncThunk(
    "project",
    async (SearchString: string) => {
      const response = await getProjectById(SearchString);
      return response.data;
    }
  );

  export const createTaskAsync = createAsyncThunk(
    "task/createTask",
    async (request: any) => {
        const response = await createTask(request);
        return response.data;
    }
  ); 

  export const getTaskAsync = createAsyncThunk(
    "task/getProject",
    async (request: any) => {
      const response = await getTask(request);
      return response.data;
    }
  );
  export const updateTaskAsync = createAsyncThunk(
    'task/update',
    async ({ id, request }: { id: string, request: any }) => {
      const response = await updateTask(id, request);
      return response.data;
    }
  );
  export const closeTaskAsync = createAsyncThunk(
    'task/close',
    async (id: string) => {
      const response = await closeTask(id);
      return response.data;
    }
  );

  export const deleteTaskAsync = createAsyncThunk(
    "task/delete",
    async (Id:string) => {
      const response = await deleteTask(Id);
      return response.data;
    }
  );

  export const checkUniquenessAsync = createAsyncThunk(
    "task/checkUniqueness",
    async (request: any) => {
      const response = await checkUniqueness(request);
      return response.data;
    }
  );

  export const checkUniquenessProjectAsync = createAsyncThunk(
    "project/checkUniqueness",
    async (request: any) => {
      const response = await checkUniquenessProject(request);
      return response.data;
    }
  );

  export const keySuggesstionAsync = createAsyncThunk(
    "project/get",
    async (projectName:string) => {
      const response = await keySuggesstion(projectName);
      return response.data;
    }
  );

  export const getTeamMemberListAsync = createAsyncThunk(
    "task/getTeamList",
    async (request: any) => {
      const response = await getTeamMemberList(request);
      return response.data;
    }
  );

  export const getTaskByIdAsync = createAsyncThunk(
    "task",
    async (taskId: string) => {
      const response = await getTaskById(taskId);
      return response.data;
    }
  );

  export const getProjectsAsync = createAsyncThunk(
    "projects",
    async (projectId: string) => {
      const response = await getProjects(projectId);
      return response.data;
    }
  );

  export const getProjectTeamMemberListAsync = createAsyncThunk(
    "project/getTeamList",
    async (request: any) => {
      const response = await getProjectTeamMemberList(request);
      return response.data;
    }
  );

  export const lockProjectAsync = createAsyncThunk(
    'project/lock',
    async (id: string) => {
      const response = await lockProject(id);
      return response.data;
    }
  );

  export const getReportAsync = createAsyncThunk(
    "admin/report",
    async (request: any) => {
        const response = await getReport(request);
        return response.data;
    }
  );

  export const unLockProjectAsync = createAsyncThunk(
    'project/unlock',
    async (id: string) => {
      const response = await unLockProject(id);
      return response.data;
    }
  );

  export const getRestrictEnumListAsync = createAsyncThunk(
    "adminSetting/restrict",
    async () => {
      const response = await getEnumList("RestrictSubmissionHour");
      return response.data;
    }
  );

  export const getMinutesEnumListAsync = createAsyncThunk(
    "adminSetting/minutes",
    async () => {
      const response = await getEnumList("MinutesOfInactivity");
      return response.data;
    }
  );

  export const getScreenshotEnumListAsync = createAsyncThunk(
    "adminSetting/screenshot",
    async () => {
      const response = await getEnumList("ScreenShotModes");
      return response.data;
    }
  );

  export const getTimeOffApprovalAsync = createAsyncThunk(
    "adminSetting/timeoffapproval",
    async () => {
      const response = await getEnumList("TimeOffApproval");
      return response.data;
    }
  );
  const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(createAdminSettingAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(createAdminSettingAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.createSetting = action.payload     
        })
        .addCase(createAdminSettingAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getOrganizationListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getOrganizationListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.getOrgnisationList = action.payload     
        })
        .addCase(getOrganizationListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getSettingListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getSettingListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.getSettingList = action.payload     
        })
        .addCase(getSettingListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });
  
        builder
        .addCase(getDasboardAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getDasboardAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.dasboardList = action.payload     
        })
        .addCase(getDasboardAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getProjectAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getProjectAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.projectList = action.payload     
        })
        .addCase(getProjectAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(createProjectAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(createProjectAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.createProj = action.payload   
        })
        .addCase(createProjectAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getProjectByIdAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getProjectByIdAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.projectData = action.payload.records   
        })
        .addCase(getProjectByIdAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(createTaskAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(createTaskAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.createTask = action.payload   
        })
        .addCase(createTaskAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTaskAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTaskAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.taskLists = action.payload     
        })
        .addCase(getTaskAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        
        builder
        .addCase(getTeamMemberListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamMemberListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamMemberList = action.payload     
        })
        .addCase(getTeamMemberListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTaskByIdAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTaskByIdAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.taskIdData = action.payload.records   
        })
        .addCase(getTaskByIdAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getProjectTeamMemberListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getProjectTeamMemberListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.projectTeamMemberList = action.payload     
        })
        .addCase(getProjectTeamMemberListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getReportAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getReportAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.getReport = action.payload     
        })
        .addCase(getReportAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getRestrictEnumListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getRestrictEnumListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.restrictList = action.payload     
        })
        .addCase(getRestrictEnumListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getMinutesEnumListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getMinutesEnumListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.minutesList = action.payload     
        })
        .addCase(getMinutesEnumListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getScreenshotEnumListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getScreenshotEnumListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.screenShotList = action.payload     
        })
        .addCase(getScreenshotEnumListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTimeOffApprovalAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTimeOffApprovalAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.timeOffApprovalList = action.payload     
        })
        .addCase(getTimeOffApprovalAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });
    },   
  });
  export default adminSlice.reducer;