import React from "react";
import allProjectEmptyImage from "../../../images/allProjectEmpty.svg";
import Typography from "../../../components/Typography/Typography";

const ProjectEmpty = () => {
  return (
    <div className="flex justify-center items-center text-center w-full h-[calc(100vh-16rem)]">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={allProjectEmptyImage}
            alt="allProjectEmptyImage"
            className="w-[8.75rem] h-[8.75rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          Your Projects Will Be Listed Here.
        </Typography>
      </div>
    </div>
  );
};

export default ProjectEmpty;
