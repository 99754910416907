import React from "react";
import Typography from "../../components/Typography/Typography";
import TimespentTaskEmptyImage from "../../images/timespentTaskEmpty.svg";

const TimeSpentTaskEmpty = () => {
  return (
    // <div className="border border-gray-100 shadow-300 py-6 pe-2 bg-white rounded-md relative mt-6 h-[24.1rem]">
    //   <div className="flex justify-between items-center relative ps-6 mb-2">
    //     <Typography variant="h5" className="text-gray-300 font-bold">
    //       Time Spent on Tasks
    //     </Typography>
    //   </div>
    <div className="h-full overflow-auto flex justify-center items-center text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={TimespentTaskEmptyImage}
            alt="TimespentTaskEmptyImage"
            className="w-[6.938rem] h-[6.938rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5 w-[20.688rem]"
          size="lg"
          variant="p"
        >
          No Task Time Logged This Week. Begin Tracking Your Tasks to See Your
          Hours Logged.
        </Typography>
      </div>
    </div>
    // </div>
  );
};

export default TimeSpentTaskEmpty;
