import React from "react";
import Typography from "../../components/Typography/Typography";
import jiraImage from "../../images/jira.png";
import clickupImage from "../../images/clickup.png";
import Icons from "../../components/Icons/Icon";
import Tooltip from "../../components/Tooltip/Tooltip";
import echoPluseImage from "../../images/echopluse.png";

type Props = {
  projects: any;
};
const OnGoingProject: React.FC<Props> = ({ projects }) => {
  return (
    <div className="border border-gray-100 shadow-300  py-6 ps-0 bg-white rounded-md relative mt-6 h-[24.1rem]">
      <div className="flex justify-between items-center relative px-6 mb-2">
        <Typography variant="h5" className="text-gray-300 font-bold">
          Ongoing Projects ({projects?.length})
        </Typography>
      </div>
      <div className="h-[calc(100%-2rem)] px-6 overflow-auto">
        {projects &&
          projects.map((t: any) => (
            <div className="border border-x-0 border-t-0 border-b-gray-400 pt-4 pb-5">
              <div className='relative after:content-[""] after:h-9 after:w-[0.188rem] after:bg-green-100 after:absolute after:-start-6 after:top-1/2 after:-translate-y-1/2 after:rounded-r-md'>
                <div className="flex justify-start items-start">
                  {t?.platform === "Jira" ? (
                    <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                      <img
                        src={jiraImage}
                        alt="jiraImage"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : t?.platform === "EchoPulse" ? (
                    <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                      <img
                        src={echoPluseImage}
                        alt="jiraImage"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                      <img
                        src={clickupImage}
                        alt="clickupImage"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                  <div className="ms-2 w-[calc(100%-1.5rem)]">
                    <div className="flex justify-start">
                      <span className="text-400 font-bold text-gray-300 line-clamp-1">
                        {t?.project}
                      </span>
                    </div>
                    <div className="text-200 font-bold text-gray-700 mt-1 flex">
                      {t?.company}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OnGoingProject;
