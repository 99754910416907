import React from "react";
import myProfileImage from "../../images/myProfile.png";
import Typography from "../../components/Typography/Typography";
import TextInput from "../../components/TextInput/TextInput";
import { Dropdown } from "../../components/DropDown/DropDown";
import ToggleButton from "../../components/ToogleButton/ToogleButton";
import SaveChanges from "./SaveChanges";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";
import EchopulseLoader from "../loader/EchopulseLoader";

type Props = {
  userProfile: any;
  loading: boolean;
};
const MyProfile: React.FC<Props> = ({ userProfile, loading }) => {
  return (
    <div className="border border-gray-100 shadow-300 p-8 bg-white rounded-md relative h-[calc(100vh-11.75rem)] overflow-auto">
      <div className="flex items-start justify-start gap-9">
        <div className="w-16 h-16 rounded-full overflow-hidden">
          {userProfile?.profileImagePath ? (
            <UserImage
              imgKey={userProfile.profileImagePath}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={getInitialsInName(userProfile?.name ?? "N A", 24)}
              alt={userProfile?.name}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        <div className="w-[calc(100%-9.75rem)] h-auto">
          <Typography variant="h3" className="text-gray-300 font-medium">
            Basic Information
          </Typography>
          <div className="grid grid-cols-2 gap-x-20 gap-y-8 mt-8">
            <div className="w-[27.5rem]">
              <div>
                <label className="text-400 font-medium text-gray-300 block mb-2">
                  Full Name
                </label>
                <TextInput
                  onBlur={()=>{}}
                  onChange={()=>{}}
                  onFocus={()=>{}}
                  onKeyPress={()=>{}}
                  placeholder=""
                  disabled
                  value={userProfile?.name}
                  type="text"
                />
              </div>
              <div className="mt-8">
                <label className="text-400 font-medium text-gray-300 block mb-2">
                  Job Title
                </label>
                <TextInput
                  onBlur={()=>{}}
                  onChange={()=>{}}
                  onFocus={()=>{}}
                  onKeyPress={()=>{}}
                  placeholder=""
                  disabled
                  value={userProfile?.title}
                  type="text"
                />
              </div>
              <div className="mt-8">
                <label className="text-400 font-medium text-gray-300 block mb-2">
                  Company Email Address
                </label>
                <TextInput
                  onBlur={()=>{}}
                  onChange={()=>{}}
                  onFocus={()=>{}}
                  onKeyPress={()=>{}}
                  placeholder=""
                  disabled
                  value={userProfile?.companyEmail}
                  type="email"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
