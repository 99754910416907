import React, { ReactElement } from "react";

export type ButtonProps = {
  /**
   * Button contents
   */
  label?: string;

  /**
  * Optional click handler
  */
  onClick?: () => void;

  variant?: "primary" | "secondary" | "outline" | "tertiary" | "quaternary" | "tertiaryYellow";
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large" | "extrasmall" | "extramedium";
  className?: string;
  /**
   * 	
  Is this the principal call to action on the page?
   */
  isDisabled?: boolean;
  type?: "button" | "submit" | "reset",
  isLoader?:boolean;
  icon?: ReactElement;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  variant = "primary",
  size = "small",
  className = "",
  isDisabled = false,
  type,
  isLoader=false,
  icon,
}) => {
  const buttonClassName = `transition-all button relative rounded-md font-bold hover:shadow-100 disabled:bg-primary-300 disabled:shadow-0 ${variant === "primary"
      ? "btnPrimary bg-primary-100 text-white hover:bg-primary-200"
      : ""
    } ${variant === "secondary"
      ? "btnSecondary bg-secondary-100 text-white hover:bg-secondary-200"
      : ""
    } ${variant === "outline"
      ? "btnOutline border border-gray-100 bg-white text-secondary-100 hover:border-secondary-200 disabled:border-gray-100 disabled:!bg-gray-200 disabled:text-gray-300 disabled:opacity-50"
      : ""
    } ${variant === "tertiary"
      ?"btnTertiary bg-green-100 rounded-full text-white font-normal hover:bg-green-200"
      : ""}
      ${variant === "tertiaryYellow"
      ? "btnTertiary bg-secondary-300 rounded-full text-white font-normal hover:bg-yellow-100"
      : ""}
      ${variant === "quaternary"
      ? "btnQuaternary bg-white text-secondary-100 hover:bg-gray-400"
      : ""}
  ${size === "large" ? "text-500 px-6 py-[0.688rem]" : ""} ${size === "extramedium" ? "text-400 px-4 py-2.5" : ""} ${size === "medium" ? "text-400 px-3.5 py-2.5" : ""} ${size === "small" ? "text-400 px-4 py-2" : ""}${size === "extrasmall" ? "text-200 px-2.5 py-1.5" : ""
    } ${className}`;

  return (
    <button className={buttonClassName} onClick={onClick} disabled={isDisabled} type={type}>
     <div className="">{icon}</div>
      <span>{label}</span>
      {isLoader && (
      <span className="absolute left-5 top-1/2 -translate-y-1/2 invisible">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#D9D9D9" stroke-width="4"></circle>
              <path className="opacity-75" fill="#4A154B" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          )}
    </button>
  );
};

export default Button;
