import React, { useEffect, useRef, useState } from "react";
import { AuthService } from "../../services/sharedService/AuthService";
import UserImage from "../../components/UserImage/UserImage";
import getInitialsInName from "../../helper/GetNameInitials";
import Icons from "../../components/Icons/Icon";

type Props = {
  userProfile: any;
};

const PersonProfile: React.FC<Props> = ({ userProfile }) => {
  const authService = new AuthService();
  const [isUlVisible, setIsUlVisible] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const handleImageClick = () => {
    setIsUlVisible(!isUlVisible);
  };

  const logout = () => {
    authService.logout();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsUlVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="absolute right-16 top-2/4 -translate-y-1/2">
      <div
        className="relative h-8 w-8 cursor-pointer rounded-full"
        onClick={handleImageClick}
      >
        {userProfile?.profileImagePath ? (
          <UserImage
            imgKey={userProfile.profileImagePath}
            className="w-full h-full object-cover rounded-full overflow-hidden"
          />
        ) : (
          <img
            src={getInitialsInName(userProfile?.name ?? "N A", 24)}
            alt={userProfile?.name}
            className="w-full h-full object-cover rounded-full overflow-hidden"
          />
        )}
        {isUlVisible && (
          <ul
            ref={dropdownRef}
            className="absolute -right-5 top-[2.25rem] z-[9999] w-[8.625rem] rounded-md"
          >
            <li
              className="bg-white flex justify-start items-center gap-2.5 text-400 px-4 py-[0.813rem] relative font-medium text-gray-300 hover:bg-gray-200 cursor-pointer border border-gray-400 rounded-md w-full"
              onClick={logout}
            >
              <Icons name="logout" variant="stroke" size={20} />
              <span className="line-clamp-1">Log Out</span>
            </li>
          </ul>
        )}
        <span className="absolute -right-5 top-1/2 -translate-y-2/4">
          <Icons name="down" variant="arrow" size={14} />
        </span>
      </div>
    </div>
  );
};

export default PersonProfile;
