import React from "react";
import MenuEchoPulse from "../MenuEchoPulse/MenuEchoPulse";
import SettingsHeaderContent from "../MenuEchoPulse/SettingsHeaderContent";
import SettingsTab from "./SettingsTab";
import HeaderEchoPulse from "../MenuEchoPulse/HeaderEchoPulse";

const Settings = () => {
  return (
    <div className="pt-2">
      {/* <MenuEchoPulse /> */}
      {/* <SettingsHeaderContent /> */}
      {/* <HeaderEchoPulse  title="Settings"/> */}
      <SettingsTab />
    </div>
  );
};

export default Settings;
