import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";

import ToggleButton from "../../components/ToogleButton/ToogleButton";
import Icons from "../../components/Icons/Icon";
import { useAppDispatch, useAppSelector } from "../../hooks";
import styled from "styled-components";
import {
  createTimesheetAsync,
  getJiraProjectAsync,
  getJiraTaskAsync,
  getRecentProjectAsync,
  getRecentTaskAsync,
} from "../../services/reducers/timeReducer";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import { Dropdown } from "../../components/DropDown/DropDown";
import { formatDate } from "../../utils/common";
import { TOASTIFY_CONF } from "../../services/sharedService/constants";
import { toast } from "react-toastify";
import { addTimeByAdminAsync } from "../../services/reducers/teamsReducer";
import { getUsersAsync } from "../../services/reducers/appReducer";

export const SingleProjectActive = styled.div`
  .active {
    background: #bdbdbd;
    color: #232323;
  }
`;

type Props = {
  onClose: () => void;
  userDetail: string;
  getLists: () => void;
  setAddTask: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: any;
  endDate?: any;
  type?: string;
};

const AddTaskModal: React.FC<Props> = ({
  onClose,
  userDetail,
  getLists,
  setAddTask,
  startDate,
  endDate,
  type,
}) => {
  const dispatch = useAppDispatch();
  const [task, setTask] = useState<any>([]);
  const [recentTask, setRecentTask] = useState<any>([]);
  const [project, setProject] = useState<any>([]);
  const [recentProject, setRecentProject] = useState<any>([]);
  const [showProjects, setShowProjects] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [isRecentProjectSelected, setIsRecentProjectSelected] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [secondProjects, setSecondProjects] = useState(false);
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [userLists, setUserLists] = useState<any>(null);

  useEffect(() => {
    getRecentList();
    getProject();
    getLists();
    userList();
  }, []);

  const userList = () => {
    const request = {
      page: 1,
      pageSize: 2000,
    };
    dispatch(getUsersAsync(request)).then((res) => {
      setUserLists(res?.payload?.users);
    });
  };

  const getRecentList = () => {
    const request = {
      StartDate: formatDate(startDate),
      EndDate: endDate ? formatDate(endDate) : "",
      MemberId: userDetail ? userDetail : "",
    };
    dispatch(getRecentTaskAsync(request)).then((res) => {
      if (res.payload) {
        setRecentTask(res.payload);
      }
    });
    dispatch(getRecentProjectAsync(request)).then((res) => {
      if (res.payload) {
        setRecentProject(res.payload);
      }
    });
  };

  const getProject = () => {
    const values: any = {
      ProjectKey: "",
    };

    dispatch(getJiraProjectAsync()).then((res) => {
      if (res.payload) {
        setProject(res.payload);
      }
    });
  };

  const dropdownOptions = project.map((t: any) => ({
    label: "# " + t.key + " " + t.name,
    value: t.key,
  }));

  const handleRemove = (value: string | number) => {
    const updatedValues = selectedValues.summary !== value;
    setSelectedValues(updatedValues);
  };
console.log(selectedValues, "selectedValues")
  const addTask = async () => {
    if (userDetail && type === "Admin") {
      const request = {
        weekStartDate: formatDate(startDate),
        weekEndDate: endDate ? formatDate(endDate) : "",
        tasks: {
          identifier: selectedValues?.identifier,
          key: selectedValues?.key,
          project: {
            id: selectedValues?.project?.id,
            externalId: selectedValues?.project?.externalId,
            key: selectedValues?.project?.key,
            name: selectedValues?.project?.name
              ? selectedValues?.project?.name.trimLeft()
              : "",
            projectStatus: selectedValues?.project?.projectStatus,
            platform: selectedValues?.project?.platform,
            companyId: selectedValues?.project?.companyId,
            deleted: selectedValues?.project?.deleted,
          },
          summary: selectedValues?.summary,
          timeEstimate: selectedValues?.timeEstimate
            ? selectedValues?.timeEstimate
            : 0,
          timeSpent: selectedValues?.timeSpent ? selectedValues?.timeSpent : 0,
        },
        platformId: selectedValues?.project?.platform,
        memberId: userDetail,
      };

      await dispatch(addTimeByAdminAsync(request)).then((res) => {
        if (res?.payload?.message === "Created") {
          toast.success(`Task Successfully Added`, TOASTIFY_CONF);
          //setAddTask(false);
          getRecentList();
          getLists();
          setSearchTerm("");
          setSelectedValues([]);
        } else if (
          res?.payload?.message ===
          "Task already exist with user identity and date!"
        ) {
          toast.error(`Task Already Exists`, TOASTIFY_CONF);
        } else if (
          res?.payload?.message === "Task is not assigned to member."
        ) {
          toast.error(
            `Task Not Assigned to Your Profile. Please Contact the Administrator for Access.`,
            TOASTIFY_CONF
          );
        } else if (
          res?.payload?.message ===
          "Project is locked. So, Can't add it to time sheet."
        ) {
          toast.error(
            `You are not allowed to add this task since the project has been locked by the admin.`,
            TOASTIFY_CONF
          );
        }
      });
    } else {
      const request = {
        weekStartDate: formatDate(startDate),
        weekEndDate: endDate ? formatDate(endDate) : "",
        tasks: {
          identifier: selectedValues?.identifier,
          key: selectedValues?.key,
          project: {
            id: selectedValues?.project?.id,
            externalId: selectedValues?.project?.externalId,
            key: selectedValues?.project?.key,
            name: selectedValues?.project?.name
              ? selectedValues?.project?.name.trimLeft()
              : "",
            projectStatus: selectedValues?.project?.projectStatus,
            platform: selectedValues?.project.platform,
            companyId: selectedValues?.project?.companyId,
            deleted: selectedValues?.project?.deleted,
          },
          summary: selectedValues?.summary,
          timeEstimate: selectedValues?.timeEstimate
            ? selectedValues?.timeEstimate
            : 0,
          timeSpent: selectedValues?.timeSpent ? selectedValues?.timeSpent : 0,
        },
        platformId: selectedValues?.project.platform,
      };
      await dispatch(createTimesheetAsync(request)).then((res) => {
        if (
          res?.payload?.message ===
          "Task already exist with user identity and date!"
        ) {
          toast.error(`Task Already Exists`, TOASTIFY_CONF);
        }
        if (res?.payload?.message === "Created") {
          toast.success(`Task Successfully Added`, TOASTIFY_CONF);
          getRecentList();
          // setAddTask(false);
          getLists();
          setSearchTerm("");
          setSelectedValues([]);
        } else if (
          res?.payload?.message ===
          "Task is not assigned to you. Please check with administrator."
        ) {
          toast.error(
            `Task Not Assigned to Your Profile. Please Contact the Administrator for Access.`,
            TOASTIFY_CONF
          );
        } else if (
          res?.payload?.message ===
          "Project is locked. So, Can't add it to time sheet."
        ) {
          toast.error(
            `You are not allowed to add this task since the project has been locked by the admin.`,
            TOASTIFY_CONF
          );
        }
      });
    }
  };

  const handleRecentTaskClick = (task: any) => {
    const newSelectedValue: any = {
      label: (
        <div className="py-4 px-7 border border-gray-100 bg-gray-200 rounded-lg text-gray-300 font-medium text-400 relative after:absolute after:w-[0.188rem] after:h-[2.5rem] after:bg-green-100 after:left-0 after:top-1.5 after:after:rounded-e-md">
          #{task.key} {task.summary}
        </div>
      ),
      value: task.summary,
    };

    setSelectedValues(task);
  };

  const handleRecentProjectClick = (project: any) => {
    setSecondProjects(true);
    setIsTaskLoading(true);
    setFilteredOptions([]);
    setTask([]);
    setSelectedProject(project);

    const values: any = {
      ProjectKey: project,
      TaskKey: searchTerm,
    };

    dispatch(getJiraTaskAsync(values)).then((res) => {
      if (res.payload) {
        // const relatedTasks = res.payload.map((t: any) => ({
        // //   label: (
        // // 	<div className="py-4 px-7 border border-gray-100 bg-gray-200 rounded-lg text-gray-300 font-medium text-400 relative after:absolute after:w-[0.188rem] after:h-[2.5rem] after:bg-green-100 after:left-0 after:top-1.5 after:after:rounded-e-md">
        // // 	  #{t.key} {t.summary}
        // // 	</div>
        // //   ),
        // label: "#" + t.key + " " + t.summary,
        //   value: t.summary,
        // }));

        setTask(res.payload);
        setIsTaskLoading(false);
        setIsRecentProjectSelected(true);
        //setSelectedValues(res.payload);
      }
    });
  };

  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <ModalHeader className="bg-gray-200">
        <Typography className="font-bold" variant="h3">
          {userDetail && userDetail ? "Add Task" : "Add Task"}
        </Typography>
      </ModalHeader>
      <ModalBody className="px-20 py-10">
        <div
          className={`flex justify-between ${
            showProjects ? `items-start` : `items-center`
          } w-full`}
        >
          <div className="">
            {showProjects && (
              <div className="w-[29.375rem] mb-3">
                <Dropdown
                  options={dropdownOptions}
                  className="[&>.css-cp01gg-control]:text-400 [&>.css-cp01gg-control]:py-[7px]"
                  type="box"
                  placeholder="Search projects"
                  onChange={(selectedOption) => {
                    if (selectedOption && "value" in selectedOption) {
                      handleRecentProjectClick(selectedOption.value);
                    }
                  }}
                  isSearchable={true}
                />
              </div>
            )}
            <div className="w-[29.375rem]">
              <CustomDropdown
                setSelectedOption={setSelectedValues}
                selectedValues={selectedValues}
                task={task}
                isRecentProjectSelected={isRecentProjectSelected}
                setIsRecentProjectSelected={setIsRecentProjectSelected}
                showProjects={showProjects}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setIsTaskLoading={setIsTaskLoading}
                isTaskLoading={isTaskLoading}
                setFilteredOptions={setFilteredOptions}
                filteredOptions={filteredOptions}
                selectedProject={selectedProject}
              />
            </div>
          
          </div>

          <div
            className={`flex justify-end items-center gap-5 ${
              showProjects ? `mt-0` : `mt-0`
            }`}
          >
            <div className="">
              <button
                className="bg-secondary-100 p-2.5 rounded-md text-400 text-white font-bold flex justify-center items-center gap-0.5 hover:bg-secondary-200 hover:shadow-100 disabled:bg-opacity-40 disabled:shadow-0 disabled:hover:bg-secondary-100 disabled:hover:bg-opacity-40"
                onClick={addTask}
                disabled={!selectedValues || selectedValues.length === 0}
              >
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10H15"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 15V5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                {userDetail ? "Add Task" : "Add Task"}
              </button>
            </div>
            <div className="-mb-1">
              <ToggleButton
                label="Search Projects"
                onChange={() => setShowProjects(!showProjects)}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 mt-5">
          {selectedValues && selectedValues.identifier && (
            <>
              <div className="col-span-11" key={selectedValues.value}>
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative">
                    <div className="py-4 ps-7 pe-8 border border-gray-100 bg-gray-200 rounded-lg text-gray-300 font-medium text-400 relative after:absolute after:w-[0.188rem] after:h-9 after:bg-green-100 after:left-0 after:top-[50%] after:-translate-y-2/4 after:rounded-e-md">
                      # {selectedValues.key} {selectedValues.summary}
                    </div>
                    <button
                      className="absolute right-3 top-4"
                      onClick={() => handleRemove(selectedValues.summary)}
                    >
                      <Icons name="cross" variant="stroke" />
                    </button>
                  </div>
                </div>
              </div>
              <div></div>
            </>
          )}
        </div>
        {showProjects ? (
          <></>
        ) : (
          <div className="mt-8">
            <Typography variant="h6" className="text-gray-700 font-bold">
              {userDetail
                ? userLists &&
                  userLists.find((user: any) => user.identityId === userDetail)
                    ?.name + " Recent Tasks"
                : "Recent Tasks"}
            </Typography>
            <div className="grid grid-cols-2">
              <div>
                <div className="pt-4">
                  {recentTask.slice(0, 10).map((t: any) => (
                    <>
                      <div
                        className={`py-4 px-7 border rounded-lg text-gray-300 font-medium text-400 mt-2 relative after:absolute after:w-[0.188rem] after:h-9 after:bg-green-100 after:left-0 after:top-[50%] after:-translate-y-2/4 after:rounded-e-md cursor-pointer w-[29.375rem] ${
                          selectedValues.identifier
                            ? `bg-gray-1200 border-gray-1300`
                            : ` bg-gray-200 border-gray-100`
                        }`}
                        key={t.key}
                        onClick={() => handleRecentTaskClick(t)}
                      >
                        #{t.key} {t.summary}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AddTaskModal;
