import React from "react";
import memberEmptyImage from "../../../images/memberEmpty.svg";
import Typography from "../../../components/Typography/Typography";

const InviteMemberEmpty = () => {
  return (
    <div className="flex justify-center items-center text-center w-full h-[calc(100vh-17rem)]">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={memberEmptyImage}
            alt="emptyOtherImage"
            className="w-[8.75rem] h-[8.75rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-6 w-[14.125rem]"
          size="lg"
          variant="p"
        >
          No members listed here.
        </Typography>
      </div>
    </div>
  );
};

export default InviteMemberEmpty;
