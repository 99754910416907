import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { useAppDispatch } from "../../hooks";
import { timeSheetSubmissionAsync } from "../../services/reducers/timeReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../services/sharedService/constants";
import { formatDate } from "../../utils/common";

type Props = {
  onClose: () => void;
  userDetail: string;
  setSubmitTime: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: any;
  endDate: any;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  getLists: () => void;
};
const SubmitTimeConfirmationModal: React.FC<Props> = ({
  onClose,
  userDetail,
  setSubmitTime,
  startDate,
  endDate,
  setIsSubmitted,
  getLists,
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const values = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      actionOnTimeSheet: 0,
      memberId: userDetail,
    };
    dispatch(timeSheetSubmissionAsync(values)).then(() => {
      getLists();
      toast.success(`Timesheet Submitted Successfully`, TOASTIFY_CONF);
      setSubmitTime(false);
      setIsSubmitted(true);
    });
  };

  return (
    <>
      <Modal isOpen onClose={onClose} placement="center" size="normal">
        <ModalBody className="ps-10 pt-10 pb-20 pe-16">
          <Typography className="font-bold text-gray-300" variant="h3">
            Are you sure you want to submit your Timesheet?
          </Typography>
          <Typography
            size="xxl"
            variant="p"
            className="text-gray-700 font-normal mt-1"
          >
          You will not be able to make changes to the entire week's timesheet after it is submitted.
          </Typography>
        </ModalBody>
        <ModalFooter className="!px-10 bg-gray-200">
          <div className="flex justify-end gap-3 w-full">
            <Button
              label="Cancel"
              onClick={onClose}
              variant="outline"
              size="large"
            />
            <Button
              label="Yes, I am sure"
              onClick={() => handleSubmit()}
              variant="primary"
              size="large"
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SubmitTimeConfirmationModal;
