import { Log, User, UserManager } from 'oidc-client';
import { Constants } from "./constants";

export class AuthService {
  public userManager: UserManager;
  constructor() {
    const settings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: window.location.origin + "/signin-callback.html",
      silent_redirect_uri: window.location.origin + "/silent-renew.html",
      post_logout_redirect_uri:
        window.location.origin + "/signout-callback-oidc",
      response_type: "code",
      scope: Constants.clientScope,
      loadUserInfo: true,
      timeoutInSeconds: 10 
    };

    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
  signinRedirectCallback = async () => {
    // Implementation for signinRedirectCallback
  };

  signoutRedirectCallback = async () => {
    // Implementation for signoutRedirectCallback
  };

  isAuthenticated = () => {
    // Implementation for isAuthenticated
  };

  signinRedirect = () => {
    // Implementation for signinRedirect
  };
}