import React from "react";
import { useField } from "formik";

interface CheckBoxInputProps {
  label: string;
  value: boolean;
  name: string;
  isRequired?: boolean;
  onChange: (isChecked: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  label,
  value,
  isRequired,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label className="relative inline-flex items-center cursor-pointer">
        {isRequired && <span style={{ color: "red" }}>*</span>}
        <input
          type="checkbox"
          className="sr-only peer appearance-none"
          {...field}
          {...props}
          checked={value}
          onChange={onChange}
          id={props.name}
        />
        <span 
          className="checkBoxCheckh h-6 w-[2.625rem] inline-block bg-gray-100 rounded-full relative 
          after:w-5 after:h-5 after:absolute after:top-0.5 after:bottom-0.5 after:left-0.5 after:bg-white after:rounded-full after:transition-all
          peer-checked:bg-secondary-100 peer-checked:after:left-full peer-checked:after:translate-x-[-1.375rem] 
          peer-disabled:opacity-70 peer-checked:peer-disabled:opacity-60
        "></span>
        {label && <span className="ml-2 text-400 font-medium text-gray-300">{label}</span>}
      </label>

    </>
  );
};

export default CheckBoxInput;
