import React from "react";
import { Dropdown } from "../../components/DropDown/DropDown";
import Icons from "../../components/Icons/Icon";
import Tooltip from "../../components/Tooltip/Tooltip";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import Typography from "../../components/Typography/Typography";
import styled from "styled-components";
import {
  convertMinutesToHoursAndMinutes2,
  formatTime,
} from "../../utils/common";

export const CustomToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    font-size: 0.875rem;
    font-weight: 700;
    color: #232323;
    cursor: default;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: #fff;
    color: #fff;
    padding: 0.313rem 0;
    position: absolute;
    z-index: 1;
    top: 190%;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
    border: 0.063rem solid #efefef;
  }
  .tooltip .tooltiptext ul li {
    font-size: 0.75rem;
    font-weight: 700;
    color: #232323;
    border-bottom: 0.063rem solid #efefef;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  .tooltip .tooltiptext ul li:last-child {
    border-bottom: none;
  }
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.313rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type Props = {
  analytics: any;
  startDate: any;
};
const DashboardOverview: React.FC<Props> = ({ analytics, startDate }) => {
  const formatDates = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
      weekday: "short",
    };

    const inputDate = new Date(dateString);
    const formattedDate = inputDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };
  const isMonday = () => {
    const today = new Date();
    return today.getDay() === 1;
  };

  const sDate = new Date(startDate);
  const todayDate = new Date();

  return (
    <div className="w-full h-[23.5rem] border border-gray-100 shadow-30 bg-white rounded-md">
      <div className="px-6 pt-8">
        <div className="grid grid-cols-2 gap-2.5 border-b border-gray-400 pb-6">
          {analytics && (
            <>
              <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
                <div
                  className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
                  style={{
                    filter:
                      "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
                  }}
                >
                  <Icons name="watch-color" variant="stroke" size={20} />
                </div>
                <div className="ms-2.5">
                  <div className="text-200 font-medium text-gray-700">
                    Weekly Time Logged
                  </div>
                  {analytics?.totalTimeRecorded > 0 ? (
                    <div className="text-500 font-bold text-gray-300">
                      {convertMinutesToHoursAndMinutes2(
                        analytics.totalTimeRecorded
                      )}
                    </div>
                  ) : (
                    <div className="text-500 font-bold text-gray-300"> - </div>
                  )}
                </div>
              </div>
              <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
                <div
                  className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
                  style={{
                    filter:
                      "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
                  }}
                >
                  <Icons name="round-small-color" variant="stroke" size={20} />
                </div>
                <div className="ms-2.5">
                  <div className="text-200 font-medium text-gray-700">
                    Average Daily Time Logged
                  </div>
                  {analytics?.averagePerDay > 0 ? (
                    <div className="text-500 font-bold text-gray-300">
                      {convertMinutesToHoursAndMinutes2(
                        analytics?.averagePerDay
                      )}
                    </div>
                  ) : (
                    <div className="text-500 font-bold text-gray-300"> - </div>
                  )}
                </div>
              </div>
              <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
                <div
                  className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
                  style={{
                    filter:
                      "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
                  }}
                >
                  <Icons name="soap-color" variant="stroke" size={20} />
                </div>
                <div className="ms-2.5">
                  <div className="text-200 font-medium text-gray-700">
                    Ongoing Projects
                  </div>
                  <div className="text-500 font-bold text-gray-300">
                    {analytics?.countOfOnGoingProject}
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 rounded-md flex justify-start items-center py-3 px-2.5">
                <div
                  className="h-9 w-9 rounded-full bg-white flex justify-center items-center"
                  style={{
                    filter:
                      "drop-shadow(0 0.5rem 1.25rem rgba(239, 239, 239, 0.90))",
                  }}
                >
                  <Icons name="document-color" variant="stroke" size={20} />
                </div>
                <div className="ms-2.5">
                  <div className="text-200 font-medium text-gray-700">
                    Maximum time spent on
                  </div>
                  {analytics?.totalTimeRecorded > 0 ? (
                    <div className="text-500 font-bold text-gray-300 line-clamp-1">
                      {analytics?.projectWithMaxHours}
                    </div>
                  ) : (
                    <div className="text-500 font-bold text-gray-300"> - </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {analytics?.timeSheetStatuses?.length === 0 ? (
        <div className="px-6 pt-6 pb-8">
          <Typography variant="h5" className="text-gray-300 font-bold">
            Timesheet Status
          </Typography>
          <div className="grid grid-cols-1 mt-4">
            {sDate.toDateString() === todayDate.toDateString() &&
            todayDate.getDay() === 1 ? (
              <div className="flex justify-center items-center h-[5.625rem]">
                <Typography variant="h6" className="text-gray-700 font-normal">
                  Your timesheet status will be represented here.
                </Typography>
              </div>
            ) : (
              <div className="border border-gray-200 rounded-md px-5 py-5 flex justify-start items-center">
                <div className="me-4 h-9 w-9 rounded-full bg-white flex justify-center items-center shadow-300">
                  <Icons name="tick-circle" variant="bold" size={22} />
                </div>
                <div>
                  <Typography
                    className="font-medium text-gray-700"
                    size="sm"
                    variant="p"
                  >
                    Timesheet Complete
                  </Typography>
                  <Typography variant="h6" className="text-gray-300 font-bold">
                    Great Job! Your Timesheet for the Week is Fully Updated.
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="px-6 pt-6 pb-8">
          <Typography variant="h5" className="text-gray-300 font-bold">
            Timesheet Status{" "}
            <span className="font-normal">
              ({analytics?.timeSheetStatuses?.length}{" "}
              {analytics?.timeSheetStatuses?.length === 1 ? "Day" : "Days"}{" "}
              Pending)
            </span>
          </Typography>

          <div className="grid grid-cols-7 gap-2.5 mt-4 items-center">
            {analytics &&
              analytics?.timeSheetStatuses
                ?.slice(0, 2)
                .map((t: any, index: number) => (
                  <>
                    {index <= 1 && (
                      <div
                        key={t.clockDate}
                        className="col-span-3 border border-gray-200 rounded-md px-4 py-5 flex justify-start items-center"
                      >
                        <div className={`me-4`}>
                          <Icons
                            name="danger-color"
                            variant="stroke"
                            size={22}
                            className="mt-1"
                          />
                        </div>
                        <Typography
                          variant="h6"
                          className="text-gray-300 font-bold"
                        >
                          {formatDates(t.clockDate)}
                        </Typography>
                      </div>
                    )}

                    {index === 1 &&
                      analytics?.timeSheetStatuses?.length > 2 && (
                        <div className="col-span-1">
                          <CustomToolTip>
                            <div className="tooltip">
                              {`+${analytics?.timeSheetStatuses.length - 2} ${
                                analytics?.timeSheetStatuses.length - 2 === 1
                                  ? "Day"
                                  : "Days"
                              }`}
                              <span className="tooltiptext">
                                <ul>
                                  {analytics?.timeSheetStatuses
                                    .slice(2)
                                    .map((t: any) => (
                                      <li>
                                        <Icons
                                          name="danger-color"
                                          variant="stroke"
                                          size={14}
                                        />
                                        <span> {formatDates(t.clockDate)}</span>
                                      </li>
                                    ))}
                                </ul>
                              </span>
                            </div>
                          </CustomToolTip>
                        </div>
                      )}
                  </>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardOverview;
