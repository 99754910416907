import React, { useState, useEffect } from "react";
import Icons from "../Icons/Icon";

import styled from "styled-components";
export const NotificationStyle = styled.div`
  &.success {}
  &.error {}
  &.warning {}
  &.info {}
`;

type NotificationType = "success" | "error" | "warning" | "info";

export interface NotificationProps {
  type: NotificationType;
  message: string;
  autoClose?: number;
  onClose?: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  type,
  message,
  autoClose,
  onClose
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosed(true);
      onClose && onClose();
    }, 1000);
  };

  useEffect(() => {
    if (autoClose && !isClosed) {
      const closeTimer = setTimeout(() => {
        handleClose();
      }, autoClose);
      return () => clearTimeout(closeTimer);
    }
  }, [autoClose, isClosed]);

  if (isClosed) {
    return null;
  }

  let notificationClassName =
    "notification w-[18.625rem] fixed top-16 right-4 mb-4 border border-gray-100 rounded-md p-4 pr-9 shadow-100 transition-all duration-300 delay-200 z-[111] bg-gray-400";
  if (type === "success") {
    notificationClassName += " success";
  } else if (type === "error") {
    notificationClassName += " error";
  } else if (type === "warning") {
    notificationClassName += " warning";
  } else if (type === "info") {
    notificationClassName += " info";
  }
  if (isClosing) {
    notificationClassName += " closing opacity-0 translate-x-full";
  }

  return (
    <NotificationStyle className={notificationClassName}>
      <div className="flex gap-3 items-start">
        <div className="h-8">
          {type === "success" ? (
            <Icons name="tick-circle" variant="bold" size={30} className="block" />
          ) : null}
          {type === "error" ? (
            <Icons name="error" variant="bold" size={30} className="block" color="#E01E1E" />
          ) : null}
          {type === "warning" ? (
            <Icons name="info-circle" variant="bold" size={30} className="block" color="#ECB22E" />
          ) : null}
          {type === "info" ? (
            <Icons name="info-circle" variant="bold" color="#0080FF" size={30} className="block" />
          ) : null}
        </div>
        <span className="text-400 text-gray-300 self-center line-clamp-3">{message}</span>
      </div>
      <button
        title="close"
        className="absolute top-3 right-3 w-4 h-4"
        onClick={handleClose}
      >
        <Icons name="cross" variant="stroke" size={16} className="block" />
      </button>
    </NotificationStyle>
  );
};

export default Notification;
