import React, { useEffect } from "react";
import Typography from "../../components/Typography/Typography";
import SearchBar from "../../components/SearchBar/SearchBar";
import Icons from "../../components/Icons/Icon";
import PersonProfile from "./PersonProfile";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserProfileAsync } from "../../services/reducers/appReducer";
import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
};

const ProjectsHeaderContent: React.FC<Props> = ({
  title,
  searchString,
  setSearchString,
}) => {
  const { userProfile } = useAppSelector((state: any) => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserProfileAsync());
  }, []);

  return (
    <div className="px-10 py-4 bg-white fixed left-[4.5rem] top-0 w-[calc(100%-4.5rem)] h-[5rem] z-[100]">
      <div className="w-full relative h-full">
        <div className="flex justify-between items-center me-[5rem] h-full">
          <div className="flex justify-start items-center">
            <button
              className="flex justify-start items-center text-600 font-normal text-gray-300"
              onClick={() => navigate(-1)}
            >
              <Icons name="left" variant="arrow" className="me-2" />
            </button>
            <div className="">
              <Typography variant="h3" className="font-bold text-gray-300">
                {title === "Projects" ? "Project" : "Project Tasks Overview"}
              </Typography>
              {title === "Projects" && (
                <Typography
                  className="font-normal text-gray-900 mt-1"
                  size="lg"
                  variant="p"
                >
                  Here’s a list of projects your teams are working towards.
                </Typography>
              )}
            </div>
          </div>
        </div>
        <PersonProfile userProfile={userProfile} />
      </div>
    </div>
  );
};

export default ProjectsHeaderContent;
