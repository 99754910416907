import axios from "axios";
import { API_URL, Constants } from "./constants";
import { AuthService } from "./AuthService";
let accessToken = window.localStorage.getItem("accessToken")

const http = axios.create({
  baseURL: API_URL.INTEGRATION_API_URL,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

const httpAuth = axios.create({
  baseURL: API_URL.AUTH_API_URL,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

const httpEmployee = axios.create({
  baseURL: API_URL.EMPLOYEE_API_URL,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

const httpBlobGetServiceApiRoot = axios.create({
  baseURL: Constants.blobServiceApiRoot,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpEmployee.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpAuth.interceptors.request.use(
  async (config) => {
    const authService = new AuthService();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpBlobGetServiceApiRoot.interceptors.request.use(
  async (config) => {
    const authService = new AuthService();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { http, httpAuth, httpEmployee, httpBlobGetServiceApiRoot };
