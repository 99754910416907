import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../sharedService/constants";
import { addTimeByAdmin, deleteComment, deleteScreenshot, getMyScreenShots, getTeamClockInOut, getTeamList, getTeamLists, getTeamScreenShots, getTimeSpentList, getTimesheetById, timeSheetApproval, timeSheetPushback, updateTimesheetByAdmin } from "../api/teamsApis";

const initialState = {
    isLoading: false,
    isError: false,
    teamDashboardList:[],
    timeSpentList:[],
    teamTimesheetList:[],
    createTime:{},
    clockInOutList:[],
    myScreenshot:{},
    teamScreenshot:[],
    deleteScreenshot:{}
  };
  
  export const getTeamListAsync = createAsyncThunk(
    "teamDashboard/list",
    async (request: any) => {
      const response = await getTeamList(request);
      return response.data;
    }
  );
    
  export const getTimeSpentListAsync = createAsyncThunk(
    "teamDashboard/timespent",
    async (request: any) => {
      const response = await getTimeSpentList(request);
      return response.data;
    }
  );

  export const getTimesheetByIdAsync = createAsyncThunk(
    "teamDashboard/timesheetList",
    async (request: any) => {
      const response = await getTimesheetById(request);
      return response.data;
    }
  );

  export const updateTimesheetByAdminAsync = createAsyncThunk(
    'teamDashboard/update',
    async (reqBody: any) => {
      const response = await updateTimesheetByAdmin(reqBody);
      return response.data;
    }
  );

  export const addTimeByAdminAsync = createAsyncThunk(
    "teamDashboard/createTimesheet",
    async (request: any) => {
        const response = await addTimeByAdmin(request);
        return response.data;
    }
  );

  export const getTeamClockInOutAsync = createAsyncThunk(
    "teamDashboard/clockInOutlist",
    async (request: any) => {
      const response = await getTeamClockInOut(request);
      return response.data;
    }
  );
  
  export const getMyScreenShotsAsync = createAsyncThunk(
    "employee/getMyScreenShots",
    async (request: any) => {
      const response = await getMyScreenShots(request);
      return response.data;
    }
  );

  export const deleteScreenshotAsync = createAsyncThunk(
    'screenshot/delete',
    async ({id, date, checkSession}:{id:string, date:string, checkSession:boolean}) => {
      const response = await deleteScreenshot(id, date, checkSession);
      return response.data;
    }
  );
  
  export const getTeamScreenShotsAsync = createAsyncThunk(
    "team/getTeamScreenShots",
    async (request: any) => {
      const response = await getTeamScreenShots(request);
      return response.data;
    }
  );

  export const getTeamListsAsync = createAsyncThunk(
    "teamDashboard/list",
    async (request: any) => {
      const response = await getTeamLists(request);
      return response.data;
    }
  );

  export const timeSheetApprovalAsync = createAsyncThunk(
    'teamTimesheet/timeSheetApproval',
    async (reqBody: any) => {
      const response = await timeSheetApproval(reqBody);
      return response.data;
    }
  );

  export const timeSheetPushbackAsync = createAsyncThunk(
    'teamTimesheet/timeSheetPushback',
    async (reqBody: any) => {
      const response = await timeSheetPushback(reqBody);
      return response.data;
    }
  );

  export const deleteCommentAsync = createAsyncThunk(
    "teamTimesheet/deleteComment",
    async (Id:string) => {
      const response = await deleteComment(Id);
      return response.data;
    }
  );

  const teamSlice = createSlice({
    name: "teamDashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getTeamListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamListAsync.fulfilled, (state, action) => {
          // console.log("full filled:",action.payload )
          state.isLoading = false;
          state.teamDashboardList = action.payload
          
        })
        .addCase(getTeamListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTimeSpentListAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTimeSpentListAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.timeSpentList = action.payload
          
        })
        .addCase(getTimeSpentListAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTimesheetByIdAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTimesheetByIdAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamTimesheetList = action.payload
          
        })
        .addCase(getTimesheetByIdAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });
        
        builder
        .addCase(addTimeByAdminAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(addTimeByAdminAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.createTime = action.payload
          //toast.success('Add Timesheet has been successfully!', TOASTIFY_CONF);
        })
        .addCase(addTimeByAdminAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
        });

        builder
        .addCase(getTeamClockInOutAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamClockInOutAsync.fulfilled, (state, action) => {
          // console.log("full filled:",action.payload )
          state.isLoading = false;
          state.clockInOutList = action.payload
          
        })
        .addCase(getTeamClockInOutAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getMyScreenShotsAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getMyScreenShotsAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.myScreenshot = action.payload;
          // console.log(state.myScreenshot , "action")
        })
        .addCase(getMyScreenShotsAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(getTeamScreenShotsAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(getTeamScreenShotsAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.teamScreenshot = action.payload
          
        })
        .addCase(getTeamScreenShotsAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });

        builder
        .addCase(deleteScreenshotAsync.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(deleteScreenshotAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.deleteScreenshot = action.payload;
        })
        .addCase(deleteScreenshotAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          
        });
        
    },
  });
  
  export default teamSlice.reducer;