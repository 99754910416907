import React from "react";
import Typography from "../../../components/Typography/Typography";
import emptyTimeConfigImage from "../../../../src/images/emptyTeam.png";

type Props = {
  selectedTimeOffType: number;
};
const TimeOffConfigurationEmpty: React.FC<Props> = ({
  selectedTimeOffType,
}) => {
  return (
    <div className="flex justify-center items-center mt-8 h-[calc(100%-5rem)] text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={emptyTimeConfigImage}
            alt="emptyTimeConfigImage"
            className="w-[6.938rem] h-[6.938rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          All {selectedTimeOffType === 0 ? "Holidays" : "Leaves"} will be listed here.
        </Typography>
      </div>
    </div>
  );
};

export default TimeOffConfigurationEmpty;
