import React from "react";
import emptyTeamImage from "../../../images/empty.png";
import Typography from "../../../components/Typography/Typography";

const ClockInOutEmpty = () => {
  return (
    <div className="bg-white rounded-md relative h-[calc(100vh-20rem)] flex justify-center items-center text-center">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={emptyTeamImage}
            alt="emptyTeamImage"
            className="w-[6.313rem] h-[6.313rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5 w-[12rem]"
          size="lg"
          variant="p"
        >
          Employee Clock In & Out Data Will Display Here.
        </Typography>
      </div>
    </div>
  );
};

export default ClockInOutEmpty;
