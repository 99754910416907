import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Tabs from "../../../components/Tabs/Tab";
import { Dropdown } from "../../../components/DropDown/DropDown";
import {
  getLeaveStatisticsAsync,
  getTeamTimeOffsAsync,
} from "../../../services/reducers/leaveReducer";
import { useAppDispatch } from "../../../hooks";
import { Dispatch, SetStateAction } from "react";
import EchopulseLoader from "../../loader/EchopulseLoader";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { getDayAndMonth } from "../../../utils/common";
import EmptyUpcomingLeavesModal from "./EmptyUpcomingLeavesModal";

type Props = {
  onClose: () => void;
  selectedValue: any;
  setSelectedValue: Dispatch<SetStateAction<{ label: string; value: string }>>;
  timeoffTab: string;
};

const UpcomingLeavesModal: React.FC<Props> = ({
  onClose,
  selectedValue,
  setSelectedValue,
  timeoffTab,
}) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(timeoffTab);
  const [leavebalance, setLeavebalance] = useState<any>([]);
  const [timeHistory, setTimeHistory] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "All",
    value: "",
  });
  const getMonthName = (monthNumber: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };

  useEffect(() => {
    dispatch(getLeaveStatisticsAsync()).then((res) => {
      if (res.payload) {
        setLeavebalance(res.payload);
      }
    });
  }, []);

  const handleDropdownChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
  };

  const handleStatusChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
  };

  useEffect(() => {
    getTimeoffList();
  }, [selectedValue.value, selectedStatus.value, activeTab]);

  const getTimeoffList = () => {
    setLoading(true);
    const request: any = {
      Page: 1,
      PageSize: 100,
      LeaveId: selectedValue ? selectedValue.value : "",
      LeaveStatus: selectedStatus.value,
      ViewType:
        activeTab === "tab1"
          ? "all"
          : activeTab === "tab2"
          ? "upcoming"
          : "history",
    };
    dispatch(getTeamTimeOffsAsync(request)).then((res) => {
      if (res.payload) {
        setTimeHistory(res.payload?.records);
        setLoading(false);
      }
    });
  };

  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="bg-gray-200">
        <Typography className="font-bold text-gray-300" variant="h3">
          Time off Calendar
        </Typography>
      </ModalHeader>
      <ModalBody className="!ps-20 !pe-0 !pt-10 !pb-10 !overflow-hidden">
        <Tabs
          className="[&>.addPadding]:mb-2 [&>.addPadding>.tab.pb-5]:pb-3"
          onClick={(tabId) => setActiveTab(tabId)}
          defaultActiveTab={activeTab}
          size="md"
          tabs={[
            {
              content: (
                <div className="relative">
                  <div className="flex justify-end items-center gap-6 absolute right-10 -top-[3.063rem]">
                    <div className="flex justify-start items-center gap-3">
                      <div className="text-400 text-gray-300 font-medium">
                        Status
                      </div>
                      <div className="w-[8rem]">
                        <Dropdown
                          onChange={handleStatusChange}
                          options={[
                            { label: "All", value: "" },
                            { label: "Approved", value: "a" },
                            { label: "Denied", value: "d" },
                            { label: "Pending", value: "p" },
                            { label: "Cancelled", value: "c" },
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedStatus}
                        />
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-5">
                      <div className="text-400 text-gray-300 font-medium">
                        Leave Type
                      </div>
                      <div className="w-[10.5rem]">
                        <Dropdown
                          onChange={handleDropdownChange}
                          options={[
                            { label: "All", value: "" },
                            ...(leavebalance?.map((org: any) => ({
                              label: org.leaves.title,
                              value: org.leaves.leaveId,
                            })) || []),
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedValue}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pe-10 overflow-auto h-[calc(100vh-264px)]">
                    {loading && (
                      <tr>
                        <td colSpan={3}>
                          <EchopulseLoader />
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      timeHistory?.length > 0 ?
                      timeHistory.map((t: any) => (
                        <>
                          <div>
                            <div
                              className="text-gray-300 font-bold text-200 pb-2 pt-4"
                              key={`${t.year}-${t.month}`}
                            >
                              {getMonthName(t?.month)} {t?.year}
                            </div>
                            <div>
                              {t.details &&
                                t.details.map((item: any) => (
                                  <div
                                    className="flex justify-start items-center py-3 border-b border-black border-opacity-10 gap-2"
                                    key={item.id}
                                  >
                                    <div className="w-[30%] flex justify-start items-center gap-3">
                                      <div>
                                        {item.myProfile?.profilePic ? (
                                          <UserImage
                                            imgKey={item?.myProfile?.profilePic}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        ) : (
                                          <img
                                            src={getInitialsInName(
                                              item?.myProfile?.name ?? "N A",
                                              24
                                            )}
                                            alt={item?.myProfile?.name}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                          {item?.myProfile?.name}
                                        </div>
                                        <div className="text-100 text-gray-700 font-normal line-clamp-1">
                                          {item?.myProfile?.designation} |{" "}
                                          {item?.myProfile?.team}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-[20%]">
                                      <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                        {item?.requestFor}d
                                      </div>
                                      <div className="text-100 text-gray-700 font-normal">
                                        {getDayAndMonth(item?.startDate).month}{" "}
                                        {getDayAndMonth(item?.startDate).day} -{" "}
                                        {getDayAndMonth(item?.endDate).month}{" "}
                                        {getDayAndMonth(item?.endDate).day}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {t?.myProfile?.team}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="bg-green-100 text-100 font-normal px-2.5 py-1 rounded-full text-white w-max">
                                        {item?.leave?.title}
                                      </div>
                                    </div>
                                    <div className="w-[14%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {item?.isLeaveCancelled
                                          ? "Cancelled"
                                          : item?.isApproved === null
                                          ? "Pending"
                                          : item?.isApproved
                                          ? "Approved"
                                          : "Denied"}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      ))
                    :(
                      <EmptyUpcomingLeavesModal/>
                    )}
                  </div>
                </div>
              ),
              id: "tab1",
              label: "All",
            },
            {
              content: (
                <div className="relative">
                  <div className="flex justify-end items-center gap-6 absolute right-10 -top-[3.063rem]">
                    <div className="flex justify-start items-center gap-3">
                      <div className="text-400 text-gray-300 font-medium">
                        Status
                      </div>
                      <div className="w-[8rem]">
                        <Dropdown
                          onChange={handleStatusChange}
                          options={[
                            { label: "All", value: "" },
                            { label: "Approved", value: "a" },
                            { label: "Denied", value: "d" },
                            { label: "Pending", value: "p" },
                            { label: "Cancelled", value: "c" },
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedStatus}
                        />
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-5">
                      <div className="text-400 text-gray-300 font-medium">
                        Leave Type
                      </div>
                      <div className="w-[10.5rem]">
                        <Dropdown
                          onChange={handleDropdownChange}
                          options={[
                            { label: "All", value: "" },
                            ...(leavebalance?.map((org: any) => ({
                              label: org.leaves.title,
                              value: org.leaves.leaveId,
                            })) || []),
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pe-10 overflow-auto h-[calc(100vh-264px)]">
                    {loading && (
                      <tr>
                        <td colSpan={3}>
                          <EchopulseLoader />
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      timeHistory?.length > 0 ?
                      timeHistory.map((t: any) => (
                        <>
                          <div>
                            <div
                              className="text-gray-300 font-bold text-200 pb-2 pt-4"
                              key={`${t.year}-${t.month}`}
                            >
                              {getMonthName(t?.month)} {t?.year}
                            </div>
                            <div>
                              {t.details &&
                                t.details.map((item: any) => (
                                  <div
                                    className="flex justify-start items-center py-3 border-b border-black border-opacity-10 gap-2"
                                    key={item.id}
                                  >
                                    <div className="w-[30%] flex justify-start items-center gap-3">
                                      <div>
                                        {item.myProfile?.profilePic ? (
                                          <UserImage
                                            imgKey={item?.myProfile?.profilePic}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        ) : (
                                          <img
                                            src={getInitialsInName(
                                              item?.myProfile?.name ?? "N A",
                                              24
                                            )}
                                            alt={item?.myProfile?.name}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                          {item?.myProfile?.name}
                                        </div>
                                        <div className="text-100 text-gray-700 font-normal line-clamp-1">
                                          {item?.myProfile?.designation} |{" "}
                                          {item?.myProfile?.team}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-[20%]">
                                      <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                        {item?.requestFor}d
                                      </div>
                                      <div className="text-100 text-gray-700 font-normal">
                                        {getDayAndMonth(item?.startDate).month}{" "}
                                        {getDayAndMonth(item?.startDate).day} -{" "}
                                        {getDayAndMonth(item?.endDate).month}{" "}
                                        {getDayAndMonth(item?.endDate).day}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {t?.myProfile?.team}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="bg-green-100 text-100 font-normal px-2.5 py-1 rounded-full text-white w-max">
                                        {item?.leave?.title}
                                      </div>
                                    </div>
                                    <div className="w-[14%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {item?.isLeaveCancelled
                                          ? "Cancelled"
                                          : item?.isApproved === null
                                          ? "Pending"
                                          : item?.isApproved
                                          ? "Approved"
                                          : "Denied"}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      )):(
                        <EmptyUpcomingLeavesModal/>
                      )}
                  </div>
                 
                </div>
              ),
              id: "tab2",
              label: "Upcoming Time Off",
            },
            {
              content: (
                <div className="relative">
                  <div className="flex justify-end items-center gap-6 absolute right-10 -top-[3.063rem]">
                    <div className="flex justify-start items-center gap-3">
                      <div className="text-400 text-gray-300 font-medium">
                        Status
                      </div>
                      <div className="w-[8rem]">
                        <Dropdown
                          onChange={handleStatusChange}
                          options={[
                            { label: "All", value: "" },
                            { label: "Approved", value: "a" },
                            { label: "Denied", value: "d" },
                            { label: "Pending", value: "p" },
                            { label: "Cancelled", value: "c" },
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedStatus}
                        />
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-5">
                      <div className="text-400 text-gray-300 font-medium">
                        Leave Type
                      </div>
                      <div className="w-[10.5rem]">
                        <Dropdown
                          onChange={handleDropdownChange}
                          options={[
                            { label: "All", value: "" },
                            ...(leavebalance?.map((org: any) => ({
                              label: org.leaves.title,
                              value: org.leaves.leaveId,
                            })) || []),
                          ]}
                          type="smbox"
                          isSearchable={false}
                          value={selectedValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pe-10 overflow-auto h-[calc(100vh-264px)]">
                    {loading && (
                      <tr>
                        <td colSpan={3}>
                          <EchopulseLoader />
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      timeHistory?.length > 0 ?
                      timeHistory.map((t: any) => (
                        <>
                          <div>
                            <div
                              className="text-gray-300 font-bold text-200 pb-2 pt-4"
                              key={`${t.year}-${t.month}`}
                            >
                              {getMonthName(t?.month)} {t?.year}
                            </div>
                            <div>
                              {t.details &&
                                t.details.map((item: any) => (
                                  <div
                                    className="flex justify-start items-center py-3 border-b border-black border-opacity-10 gap-2"
                                    key={item.id}
                                  >
                                    <div className="w-[30%] flex justify-start items-center gap-3">
                                      <div>
                                        {item.myProfile?.profilePic ? (
                                          <UserImage
                                            imgKey={item?.myProfile?.profilePic}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        ) : (
                                          <img
                                            src={getInitialsInName(
                                              item?.myProfile?.name ?? "N A",
                                              24
                                            )}
                                            alt={item?.myProfile?.name}
                                            className="w-9 h-9 rounded-full object-cover"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                          {item?.myProfile?.name}
                                        </div>
                                        <div className="text-100 text-gray-700 font-normal line-clamp-1">
                                          {item?.myProfile?.designation} |{" "}
                                          {item?.myProfile?.team}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-[20%]">
                                      <div className="text-200 text-gray-300 font-bold line-clamp-1">
                                        {item?.requestFor}d
                                      </div>
                                      <div className="text-100 text-gray-700 font-normal">
                                        {getDayAndMonth(item?.startDate).month}{" "}
                                        {getDayAndMonth(item?.startDate).day} -{" "}
                                        {getDayAndMonth(item?.endDate).month}{" "}
                                        {getDayAndMonth(item?.endDate).day}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {t?.myProfile?.team}
                                      </div>
                                    </div>
                                    <div className="w-[18%]">
                                      <div className="bg-green-100 text-100 font-normal px-2.5 py-1 rounded-full text-white w-max">
                                        {item?.leave?.title}
                                      </div>
                                    </div>
                                    <div className="w-[14%]">
                                      <div className="text-200 text-gray-300 font-bold">
                                        {item?.isLeaveCancelled
                                          ? "Cancelled"
                                          : item?.isApproved === null
                                          ? "Pending"
                                          : item?.isApproved
                                          ? "Approved"
                                          : "Denied"}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      )):(
                        <EmptyUpcomingLeavesModal/>
                      )}
                  </div>
                </div>
              ),
              id: "tab3",
              label: "Time Off History",
            },
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-start gap-5">
          <Button
            label="Okay"
            onClick={onClose}
            variant="primary"
            size="large"
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default UpcomingLeavesModal;
