import React, { useState, useEffect } from "react";
import Icons from "../../../components/Icons/Icon";

type Props = {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  pageTitle?: string;
  onPageChange: (newPage: number) => void;
  filterss: boolean;
  setFilterss: React.Dispatch<React.SetStateAction<boolean>>;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  pageTitle,
  onPageChange,
  filterss,
  setFilterss,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPageState, setCurrentPageState] = useState(1);

  useEffect(() => {
    setFilterss && setFilterss(false);
    setCurrentPageState(1);
  }, [totalItems, itemsPerPage, filterss]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPageState(newPage);
      onPageChange(newPage);
    }
  };

  const startItem = (currentPageState - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPageState * itemsPerPage, totalItems);

  return (
    <>
      {totalItems > 0 && (
        <div className="flex justify-start items-center gap-2">
          <div className="flex justify-start items-center text-400 font-normal text-gray-300">
            {pageTitle ? pageTitle : "Members"}{" "}
            <span className="text-400 font-normal text-gray-300 ms-1.5 inline-block">
              {startItem} of {endItem}
            </span>
          </div>
          <div className="flex justify-start items-center gap-1 -mb-1.5">
            <button
              className={
                currentPageState === 1
                  ? "[&>span>svg>path]:!stroke-gray-200 p-1"
                  : "cursor-pointer p-1"
              }
              onClick={() => handlePageChange(currentPageState - 1)}
              disabled={currentPageState === 1}
            >
              <Icons name="left" variant="arrow" size={16} />
            </button>
            <button
              className={
                currentPageState === totalPages
                  ? "[&>span>svg>path]:!stroke-gray-200 p-1"
                  : "cursor-pointer p-1"
              }
              onClick={() => handlePageChange(currentPageState + 1)}
              disabled={currentPageState === totalPages}
            >
              <Icons name="right" variant="arrow" size={16} />
            </button>
          </div>
        </div>
      )}
      {totalItems === 0 && (
        <>
          <div className="flex justify-start items-center gap-2">
            <div className="flex justify-start items-center text-400 font-normal text-gray-300">
              Members{" "}
              <span className="text-400 font-normal text-gray-300 ms-1.5 inline-block">
                0 of 0
              </span>
            </div>
            <div className="flex justify-start items-center gap-1 -mb-1.5">
              <button
                className="[&>span>svg>path]:!stroke-gray-200 p-1"
                onClick={() => handlePageChange(currentPageState - 1)}
                disabled={currentPageState === 1}
              >
                <Icons name="left" variant="arrow" size={16} />
              </button>
              <button
                className="[&>span>svg>path]:!stroke-gray-200 p-1"
                onClick={() => handlePageChange(currentPageState + 1)}
                disabled={currentPageState === totalPages}
              >
                <Icons name="right" variant="arrow" size={16} />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Pagination;
