import React, { useEffect, useState } from 'react';
import { getJiraTaskAsync } from '../../services/reducers/timeReducer';
import { useAppDispatch } from '../../hooks';
import Select, { Props as ActionMeta, SingleValue, MultiValue, components, DropdownIndicatorProps } from 'react-select';

export type SelectOption = {
  label: string | React.ReactNode;
  value: string | number;
};

export type ProjectDropdownProps = {
  options: SelectOption[];
  isMulti?: boolean;
  onChange: (value: SelectOption | SelectOption[] | null, actionMeta: ActionMeta<SelectOption>) => void;
  className?: string;
  type?:"circle" | "box" | "smbox";
  menuPlacement?:"top" | "bottom";
  isSearchable?:boolean
  placeholder ?:any;
  value?: SelectOption | SelectOption[] | null; 
};

export type SelectChangeHandler = (newValue: SingleValue<SelectOption> | MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;

export const ProjectDropdown: React.FC<ProjectDropdownProps> = ({
  options,
  isMulti = false,
  onChange,
  className,
  type,
  menuPlacement,
  isSearchable = false,
  placeholder,
  value
}) => {
  const handleChange: SelectChangeHandler = (newValue, actionMeta) => {
    onChange(newValue as SelectOption | SelectOption[] | null, actionMeta);
  };
  // const handleChange: SelectChangeHandler = (newValue, actionMeta) => {
  //   let value: SelectOption | SelectOption[] | null = null;
  
  //   if (newValue !== null) {
  //     if (Array.isArray(newValue)) {
  //       value = newValue.map((option) => option.value);
  //     } else {
  //       value = newValue.value;
  //     }
  //   }
  
  //   onChange(value, actionMeta);
  // };
  /*
  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8745 3.61562C10.7071 3.46146 10.4358 3.46146 10.2684 3.61562L6 7.54702L1.73162 3.61562C1.56425 3.46146 1.29289 3.46146 1.12552 3.61562C0.958158 3.76977 0.958158 4.0197 1.12552 4.17386L5.69695 8.38438C5.77733 8.45841 5.88633 8.5 6 8.5C6.11366 8.5 6.22267 8.45841 6.30305 8.38438L10.8745 4.17386C11.0418 4.0197 11.0418 3.76977 10.8745 3.61562Z" fill="#232323" />
        </svg>
      </components.DropdownIndicator>
    );
  };
  */
 

  return (
    <div className={`select inline-block min-w-full ${type === "box" ? '!block' : ''} `}>
      <Select
        unstyled
        isMulti={isMulti}
        options={options}
        onChange={handleChange}
        className={`inline-block min-w-full ${type === "box" ? '!block' : ''} ${className ? className : ""}`}
        classNamePrefix="select"
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        value={value}
        classNames={{
          input: () => "",
          control: (state) => `!min-h-0 px-0 pb-4 rounded-md border border-gray-100 text-400 text-gray-300 font-bold !cursor-pointer
          ${type === "box" ? 'rounded-md bg-white px-4 py-[0.688rem] text-500 text-gray-300 ' : ''} 
          ${type === "smbox" ? 'p-0 border-0' : ''} 
          ${state.isFocused ? 'border border-gray-900' : ''} ${state.menuIsOpen ? '' : ''}`,
          placeholder: () => "text-gray-700",
          dropdownIndicator: () => "text-gray-800 ms-2 [&>svg]:opacity-0 bg-down bg-[length:1rem_1rem] bg-no-repeat bg-center [&>svg]:w-4 [&>svg]:h-4",
          menu: () => "boxmenuMenu text-400 bg-white border-0 mt-[1.50rem] shadow-100 min-w-[9rem]",
          option: ({ isFocused, isSelected }) => `text-400 px-4 text-left py-4 font-medium text-left relative text-gray-700 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-300 
          ${isSelected ? "text-400 bg-gray-200 !text-gray-300 hover:bg-gray-200 hover:text-gray-300 active:bg-gray-200 active:text-gray-300 after:ml-2 after:absolute after:right-2 after:content-['']" : ""}
          ${isFocused ? "text-400 bg-gray-200 !text-gray-300 hover:bg-gray-200 hover:text-gray-300" : ""} 
          `,
          noOptionsMessage: () => "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
        }}
        // after:content-['✔'] 
        // components={{ DropdownIndicator }}
        isSearchable={isSearchable}
        // menuIsOpen
      />
    </div>
  );
};
