import React, { FC } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../components/Modal/Modal";
interface ModalDeleteProps {
  onCancel: (action: string) => void;
  onConfirm: (requestType: string) => void;
  headerLable?: string;
  chldLable: string;
  requestType?: string;
}

const ModalDelete: FC<ModalDeleteProps> = ({
  onCancel,
  onConfirm,
  headerLable,
  chldLable,
  requestType = "Delete",
}) => {
  return (
    <Modal isOpen={true} onClose={() => onCancel("Cancel")} size="small">
      <ModalBody className="px-10 pt-10 pb-[4rem]">
        <p className="text-gray-300 font-bold">{chldLable}</p>
        <p className="text-gray-300 font-normal text-400 mt-3">
          {" "}
          {headerLable}
        </p>
      </ModalBody>
      <ModalFooter className="!pe-10 !justify-end gap-2.5 bg-gray-200">
        {requestType !== "NotDelete" && (
          <button
            className="transition-all button rounded-md font-bold hover:shadow-100 disabled:bg-primary-300 disabled:shadow-0 btnOutline border border-gray-100 bg-white text-secondary-100 hover:border-secondary-200 disabled:border-gray-10 text-500 px-6 py-3"
            onClick={() => onCancel("Cancel")}
          >
            Cancel
          </button>
        )}
        <button
          className="bg-primary-100 px-6 py-3 rounded-md text-500 text-white font-bold flex justify-center items-center gap-0.5 hover:bg-primary-200 hover:shadow-100 disabled:bg-opacity-40 disabled:shadow-0 disabled:hover:bg-secondary-100 disabled:hover:bg-opacity-40"
          onClick={() =>
            requestType === "NotDelete" ? onCancel("Cancel") : onConfirm("Yes")
          }
        >
          {requestType === "NotDelete" ? "Okay" : "Yes, I am sure"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
