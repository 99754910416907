import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import ScreenshotDeleteCofirmModal from "./ScreenshotDeleteCofirmModal";
import { addTimeAndFormat, dateConverter } from "../../../utils/common";
import { getSettingListAsync } from "../../../services/reducers/adminReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";

export const SliderStyle = styled.div`
.sliderContainer {
  position: relative;
}
.mainSlide {
  .slick-slider {
    height: calc(100vh - 14rem);
    width: 100%;
    .mainSlideSingle {
      height: calc(100vh - 13.25rem);
      width: 100%;
      overflow: hidden;
      display: flex !important;
      justify-content: center;
      align-items: center;
      overflow:hidden;
      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}
.navSlide {
  position: absolute;
  bottom: -0.563rem;
  left: 50%;
  transform: translateX(-50%);
    .slick-list{
      width: 41.25rem !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    .slick-track{
      transform: translate3d(0, 0, 0) !important;
    }
  }
  .slick-slider {
    width: 41.25rem;
    height: 5.875rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    .navSlideSingle {
      width: 9.375rem !important;
      height: 5.875rem;
      object-fit: cover;
      border: 0.063rem solid transparent;
      position: relative;
      cursor:pointer;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-current {
      .navSlideSingle {
        border: 0.063rem solid red;
        &:after {
          display: none;
        }
      }
    }
    .slick-slide{
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
}
.navSlideMax {
  position: absolute;
  bottom: -0.563rem;
  left: 50%;
  transform: translateX(-50%);
    .slick-list{
    }
    .slick-track{
    }
  }
  .slick-slider {
    width: 41.25rem;
    height: 5.875rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    .navSlideSingle {
      width: 9.375rem !important;
      height: 5.875rem;
      object-fit: cover;
      border: 0.063rem solid transparent;
      position: relative;
      cursor:pointer;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-current {
      .navSlideSingle {
        border: 0.063rem solid red;
        &:after {
          display: none;
        }
      }
    }
    .slick-slide{
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
  .prevButton{
    position: absolute;
    left: -3.125rem;
    top: 50%;
    transform: translateY(-50%);
    &:disabled{
      opacity:0.5;
    }
  }
  .nextButton{
    position: absolute;
    right: -3.125rem;
    top: 50%;
    transform: translateY(-50%);
    &:disabled{
      opacity:0.5;
    }
  }
}
.prevButton{
  position: absolute;
  left: -3.125rem;
  top: 50%;
  transform: translateY(-50%);
  &:disabled{
    opacity:0.5;
  }
}
.nextButton{
  position: absolute;
  right: -3.125rem;
  top: 50%;
  transform: translateY(-50%);
  &:disabled{
    opacity:0.5;
  }
}
`;

type Props = {
  onClose: () => void;
  capturedList?: any;
  getMyScreenshot: () => void;
  setSingleScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
  screenshotId: string;
  type: string;
  getMyScreenshots: any;
};

type SliderType = typeof Slider;

const SingleScreenshotViewModal: React.FC<Props> = ({
  onClose,
  capturedList,
  getMyScreenshot,
  setSingleScreenshot,
  screenshotId,
  type,
  getMyScreenshots,
}) => {
  const mainSlider = useRef<SliderType>(null);
  const navSlider = useRef<SliderType>(null);
  const navSliderMax = useRef<SliderType>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [screenshotDeleteCofirm, setScreenshotDeleteCofirm] =
    useState<boolean>(false);
  const [currentImageTime, setCurrentImageTime] = useState<string>("");
  const [currentImageDate, setCurrentImageDate] = useState<string>("");
  const [currentImageDelete, setCurrentImageDelete] = useState<string>("");
  const dispatch = useAppDispatch();
  const [deleteEnable, setDeleteEnable] = useState<string>("");
  const getScreenshotList = useAppSelector(
    (state: any) => state.admin.getSettingList
  );

  useEffect(() => {
    if (mainSlider.current && capturedList) {
      let initialSlideIndex = 0;
      if (screenshotId) {
        const screenshotIndex = capturedList.findIndex(
          (screenshot: any) => screenshot.id === screenshotId
        );
        if (screenshotIndex !== -1) {
          initialSlideIndex = screenshotIndex;
        }
      }
      mainSlider.current.slickGoTo(initialSlideIndex);
      setCurrentSlide(initialSlideIndex);
      setTotalSlides(capturedList.length);
    }
    const number = capturedList?.length;
  }, [capturedList, screenshotId]);

  useEffect(() => {
    if (capturedList && capturedList[currentSlide]?.capturedAt) {
      setCurrentImageTime(capturedList[currentSlide].capturedAt);
    }
    if (capturedList && capturedList[currentSlide]?.capturedOn) {
      setCurrentImageDate(capturedList[currentSlide].capturedOn);
    }
    if (capturedList && capturedList[currentSlide]?.id) {
      setCurrentImageDelete(capturedList[currentSlide].id);
    }
  }, [currentSlide, capturedList]);

  const mainSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor:
      capturedList?.length > 4 ? navSliderMax.current : navSlider.current,
    afterChange: (index: number) => setCurrentSlide(index),
  };

  const navSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: mainSlider.current,
  };

  const navSliderMaxSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: mainSlider.current,
  };

  const handleThumbnailClick = (index: number) => {
    if (mainSlider.current) {
      mainSlider.current.slickGoTo(index);
    }
  };

  const handleNextSlide = () => {
    if (mainSlider.current && currentSlide < totalSlides - 1) {
      mainSlider.current.slickNext();
    }
  };

  const handlePrevSlide = () => {
    if (mainSlider.current && currentSlide > 0) {
      mainSlider.current.slickPrev();
    }
  };

  useEffect(() => {
    dispatch(getSettingListAsync());
  }, []);

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="extramedium"
      placement="right"
      className="z-[9999]"
    >
      <ModalHeader className="ps-[3.125rem] bg-gray-200">
        <Typography variant="h3" className="font-bold text-gray-300">
          {dateConverter(currentImageDate)} |{" "}
          {addTimeAndFormat(currentImageTime, 5, 30)}
        </Typography>
      </ModalHeader>
      <ModalBody className="!px-[3.125rem] !pb-8 !pt-5">
        <SliderStyle>
          <div className="sliderContainer">
            <div className="mainSlide">
              <Slider {...mainSliderSettings} ref={mainSlider}>
                {capturedList?.map((imageUrl: any, index: number) => (
                  <div key={index} className="mainSlideSingle">
                    <img
                      src={imageUrl.nevigationUrl}
                      alt={`Screenshot ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {capturedList?.length < 5 ? (
              <div className="navSlide">
                <Slider {...navSliderSettings} ref={navSlider}>
                  {capturedList?.map((imageUrl: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => handleThumbnailClick(index)}
                      className="navSlideSingle"
                    >
                      <img
                        src={imageUrl.nevigationUrl}
                        alt={`Thumbnail ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="navSlideMax">
                <Slider {...navSliderMaxSettings} ref={navSliderMax}>
                  {capturedList?.map((imageUrl: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => handleThumbnailClick(index)}
                      className="navSlideSingle"
                    >
                      <img
                        src={imageUrl.nevigationUrl}
                        alt={`Thumbnail ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
                <button
                  onClick={handlePrevSlide}
                  className="prevButton"
                  disabled={currentSlide === 0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <path
                      d="M25.1217 33.1998L14.255 22.3331C12.9717 21.0498 12.9717 18.9498 14.255 17.6665L25.1217 6.7998"
                      stroke="#646464"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleNextSlide}
                  className="nextButton"
                  disabled={currentSlide === totalSlides - 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <path
                      d="M15.8782 33.1998L26.7448 22.3331C28.0282 21.0498 28.0282 18.9498 26.7448 17.6665L15.8782 6.7998"
                      stroke="#646464"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </SliderStyle>
      </ModalBody>
      <ModalFooter className="!px-[3.125rem]">
        <div className="flex justify-start items-center w-full gap-4">
          <Button
            label="Close"
            onClick={onClose}
            variant="outline"
            size="large"
          />
          <Button
            label="Delete Screenshot"
            variant="primary"
            size="large"
            onClick={() => setScreenshotDeleteCofirm(true)}
          />
        </div>
      </ModalFooter>
      {screenshotDeleteCofirm && (
        <ScreenshotDeleteCofirmModal
          onClose={() => setScreenshotDeleteCofirm(false)}
          currentImageDelete={currentImageDelete}
          getMyScreenshot={getMyScreenshot}
          setScreenshotDeleteCofirm={setScreenshotDeleteCofirm}
          setSingleScreenshot={setSingleScreenshot}
          deleteEnable={getScreenshotList?.records[0]?.removeSession}
          currentImageDate={currentImageDate}
          type={type}
          getMyScreenshots={getMyScreenshots}
        />
      )}
    </Modal>
  );
};

export default SingleScreenshotViewModal;
