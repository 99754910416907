import React, { useEffect, useState } from 'react';
import { getJiraTaskAsync } from '../../services/reducers/timeReducer';
import { useAppDispatch } from '../../hooks';
import Select, { Props as ActionMeta, SingleValue, MultiValue, components, DropdownIndicatorProps } from 'react-select';

export type SelectOption = {
  label: string | React.ReactNode;
  value: string | number;
};

export type DropdownProps = {
  options: SelectOption[];
  isMulti?: boolean;
  onChange: (value: SelectOption | SelectOption[] | null, actionMeta: ActionMeta<SelectOption>) => void;
  className?: string;
  type?:"circle" | "box" | "smbox" | "mdbox";
  menuPlacement?:"top" | "bottom";
  isSearchable?:boolean
  placeholder ?:any;
  value?: SelectOption | SelectOption[] | null; 
  isDisabled?: boolean;
  error?: boolean;
};

export type SelectChangeHandler = (newValue: SingleValue<SelectOption> | MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  isMulti = false,
  onChange,
  className,
  type,
  menuPlacement,
  isSearchable = false,
  placeholder,
  value,
  isDisabled = false,
  error = false,
}) => {
  const handleChange: SelectChangeHandler = (newValue, actionMeta) => {
    onChange(newValue as SelectOption | SelectOption[] | null, actionMeta);
  };
  // const handleChange: SelectChangeHandler = (newValue, actionMeta) => {
  //   let value: SelectOption | SelectOption[] | null = null;
  
  //   if (newValue !== null) {
  //     if (Array.isArray(newValue)) {
  //       value = newValue.map((option) => option.value);
  //     } else {
  //       value = newValue.value;
  //     }
  //   }
  
  //   onChange(value, actionMeta);
  // };
  /*
  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8745 3.61562C10.7071 3.46146 10.4358 3.46146 10.2684 3.61562L6 7.54702L1.73162 3.61562C1.56425 3.46146 1.29289 3.46146 1.12552 3.61562C0.958158 3.76977 0.958158 4.0197 1.12552 4.17386L5.69695 8.38438C5.77733 8.45841 5.88633 8.5 6 8.5C6.11366 8.5 6.22267 8.45841 6.30305 8.38438L10.8745 4.17386C11.0418 4.0197 11.0418 3.76977 10.8745 3.61562Z" fill="#232323" />
        </svg>
      </components.DropdownIndicator>
    );
  };
  */
 

  return (
    <div className={`select inline-block min-w-full ${type === "box" ? '!block' : ''} `}>
      <Select
        unstyled
        isMulti={isMulti}
        options={options}
        onChange={handleChange}
        className={`inline-block min-w-full ${type === "box" ? '!block' : ''} ${className ? className : ""}`}
        classNamePrefix="select"
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        classNames={{
          input: () => "",
          control: (state) => `${isDisabled ? "bg-[#F8F8F8]" :"bg-white"} px-3 rounded-md border border-gray-100 text-400 font-normal ${isDisabled ? "text-gray-700" :"text-gray-300"} ${error ? "border-primary-100" :"border-gray-100"}
          ${type === "box" ? 'rounded-md bg-white px-4 py-[0.688rem] text-400 text-gray-300' : ''} 
          ${type === "mdbox" ? 'rounded-md bg-white px-3 py-[7px] text-400 text-gray-300' : ''} 
          ${type === "smbox" ? 'rounded-md py-[0.438rem] px-3' : ''} 
          ${state.isFocused ? 'border border-gray-900' : ''} ${state.menuIsOpen ? '' : ''}`,
          placeholder: () => "text-gray-700",
          // dropdownIndicator: () => "text-gray-800 pl-1 py-0.5 [&>svg]:opacity-0 bg-down bg-[length:1rem_1rem] bg-no-repeat bg-center",
          dropdownIndicator: () => `
            text-gray-800 pl-1 py-0.5 [&>svg]:opacity-0 bg-down bg-[length:1rem_1rem] bg-no-repeat bg-center
            ${type === "mdbox" ? "bg-[length:0.75rem_0.75rem] bg-[center_right]" : ""}
            ${type === "smbox" ? "bg-[length:0.75rem_0.75rem]" : ""}
          `,
          menu: () => "boxmenuMenu text-400 bg-white border border-gray-100 rounded-md rounded-t-0 mt-1 shadow-100 min-w-auto",
          option: ({ isFocused, isSelected }) => `text-400 px-3 py-2 relative text-black hover:cursor-pointer hover:bg-gray-200 hover:text-black 
          ${isSelected ? "text-400 bg-gray-200 text-black hover:bg-gray-200 hover:text-gray-500 active:bg-gray-200 active:text-gray-500 after:ml-2 after:absolute after:right-2 after:content-['']" : ""}
          ${isFocused ? "text-400 bg-gray-200 text-black hover:bg-gray-200" : ""} 
          `,
          noOptionsMessage: () => "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
        }}
        // after:content-['✔'] 
        // components={{ DropdownIndicator }}
        isSearchable={isSearchable}
        // menuIsOpen
      />
    </div>
  );
};
