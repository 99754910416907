import React, { useState, useEffect } from "react";

export type ToggleButtonProps = {
  label: string;
  /**
   * Specify whether the toggle should be on by default
   */
  defaultChecked?: boolean;
  /**
   * onChange event handler
   */
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  /**
   * Specify a custom className
   */
  className?: string;
  type?: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  label,
  defaultChecked = false,
  onChange,
  disabled = false,
  className,
  type,
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <>
      {/* <label className={className}>
        <input
          type="checkbox"
          disabled={disabled ? true : false}
          checked={checked}
          onChange={handleToggle}
        />
        <span className="">{label}</span>
      </label> */}
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer appearance-none"
          disabled={disabled ? true : false}
          checked={checked}
          onChange={handleToggle}
        />
        <span
          className={`h-6 w-[2.625rem] inline-block rounded-full relative
        after:w-5 after:h-5 after:absolute after:top-0.5 after:bottom-0.5 after:left-0.5 after:bg-white after:rounded-full after:transition-all
        peer-checked:after:translate-x-full peer-checked:bg-secondary-100 peer-disabled:opacity-70
        ${type === "timeOff" ? "bg-secondary-100" : ""} 
        ${type === "activityLog" ? "bg-primary-100 peer-checked:bg-primary-100" : "bg-gray-100"}`}
        ></span>
        <span className="ml-2 text-400 font-bold text-gray-300 ">{label}</span>
      </label>
    </>
  );
};

export default ToggleButton;
