import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { deleteScreenshotAsync } from "../../../services/reducers/teamsReducer";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void;
  currentImageDelete?: string;
  getMyScreenshot: () => void;
  setScreenshotDeleteCofirm: React.Dispatch<React.SetStateAction<boolean>>;
  setSingleScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
  deleteEnable: any;
  currentImageDate: string;
  type: string;
  getMyScreenshots: any;
};

const ScreenshotDeleteCofirmModal: React.FC<Props> = ({
  onClose,
  currentImageDelete,
  getMyScreenshot,
  setScreenshotDeleteCofirm,
  setSingleScreenshot,
  deleteEnable,
  currentImageDate,
  type,
  getMyScreenshots,
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = (checkSession: boolean) => {
    if (currentImageDelete) {
      dispatch(
        deleteScreenshotAsync({
          id: currentImageDelete,
          date: currentImageDate,
          checkSession: checkSession,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error("Unable to Delete with one Screenshot");
          setScreenshotDeleteCofirm(false);
          setSingleScreenshot(false);
        } else {
          getMyScreenshot();
          toast.success("Screenshot Deleted Successfully!", TOASTIFY_CONF);
          setScreenshotDeleteCofirm(false);
          setSingleScreenshot(false);
        }
      });
    }
  };
  useEffect(() => {}, [getMyScreenshots]);
  return (
    <Modal
      isOpen
      onClose={onClose}
      size="normal"
      placement="center"
      className="z-[9999]"
    >
      <ModalBody className="!px-10 !pt-10 !pb-20">
        <div className="pb-1.5">
          <Typography variant="h3" className="text-gray-300 font-bold">
            Are you sure you want to delete this screenshot?
          </Typography>
        </div>
      </ModalBody>
      <ModalFooter className="!px-10 bg-gray-200">
        <div className="flex justify-end items-center w-full gap-4">
          {type === "Admin" ? (
            deleteEnable ? (
              <>
                <Button
                  label="Cancel"
                  onClick={onClose}
                  variant="outline"
                  size="large"
                />
                <Button
                  label="Delete screenshot"
                  onClick={() => handleDelete(false)}
                  variant="primary"
                  size="medium"
                />
                <Button
                  label="Delete screenshot with time"
                  onClick={() => handleDelete(true)}
                  variant="primary"
                  size="medium"
                />
              </>
            ) : (
              <>
                <Button
                  label="Cancel"
                  onClick={onClose}
                  variant="outline"
                  size="large"
                />
                <Button
                  label="Delete screenshot"
                  onClick={() => handleDelete(false)}
                  variant="primary"
                  size="medium"
                />
              </>
            )
          ) : (
            <>
              <Button
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
              <Button
                label={
                  deleteEnable
                    ? "Delete screenshot with time"
                    : "Delete screenshot"
                }
                onClick={() => handleDelete(deleteEnable ? true : false)}
                variant="primary"
                size="medium"
              />
            </>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ScreenshotDeleteCofirmModal;
