import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useAppDispatch } from "../../../hooks";
import { deleteTimeOffAsync } from "../../../services/reducers/leaveReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";

type Props = {
  onClose: () => void;
  timeOffId: string;
  getTimeoffList: () => void;
  setDeleteTimeOff: React.Dispatch<React.SetStateAction<boolean>>;
  deleteTimeOffUsed: boolean;
};
const AdminDeleteTimeOffConfirmModal: React.FC<Props> = ({
  onClose,
  timeOffId,
  getTimeoffList,
  setDeleteTimeOff,
  deleteTimeOffUsed,
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    if (timeOffId) {
      dispatch(deleteTimeOffAsync(timeOffId)).then((res) => {
        getTimeoffList();
        //toast.success("TimeOff Deleted Successfully!", TOASTIFY_CONF);
        setDeleteTimeOff(false);
      });
    }
  };
  return (
    <Modal
      isOpen
      onClose={onClose}
      size="normal"
      placement="center"
      className="z-[9999]"
    >
      <ModalBody className="!ps-10 !py-10 !pe-16">
        <div className="pb-12">
          {deleteTimeOffUsed ? (
            <Typography variant="h3" className="font-bold text-gray-300">
              This Leave cannot be deleted as it is in use.
            </Typography>
          ) : (
            <Typography variant="h3" className="font-bold text-gray-300">
              Are you sure you want to Delete this Time Off Type?
            </Typography>
          )}
          {/* <Typography
            className="text-gray-700 font-normal mt-2"
            size="xxl"
            variant="p"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a mauris
            nec erat blandit bibendum eu sit amet metus.
          </Typography> */}
        </div>
      </ModalBody>
      <ModalFooter className="bg-gray-200">
        <div className="w-full flex justify-end items-end gap-3">
          <Button
            label={deleteTimeOffUsed ? "Okay" : "Cancel"}
            onClick={onClose}
            variant={deleteTimeOffUsed ? "primary" : "outline"}
            size="large"
          />
          {deleteTimeOffUsed === false && (
            <Button
              label="Proceed"
              onClick={() => handleDelete()}
              variant="primary"
              size="large"
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdminDeleteTimeOffConfirmModal;
