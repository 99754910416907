import React from "react";
import Typography from "../../../components/Typography/Typography";

type Props = {
  team?: string;
};
const LeaveBalanceEmpty: React.FC<Props> = ({ team }) => {
  return (
    <div className="flex justify-center items-center h-full w-full text-center">
      <div>
        <div className="w-[12.875rem] flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
          >
            <circle cx="50" cy="50" r="50" fill="#F4F4F4" />
            <path
              d="M70.678 38.7379V57.4446C68.9406 55.4692 66.394 54.2079 63.538 54.2079C58.2782 54.2079 54.018 58.4681 54.018 63.7279C54.018 65.5129 54.5178 67.2027 55.3984 68.6307C55.8982 69.4875 56.5408 70.2491 57.2786 70.8679H39.738C31.408 70.8679 27.838 66.1079 27.838 58.9679V38.7379C27.838 31.5979 31.408 26.8379 39.738 26.8379H58.778C67.108 26.8379 70.678 31.5979 70.678 38.7379Z"
              fill="white"
              stroke="#EFEFEF"
              stroke-width="2.03461"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.1676 76.9827C69.4618 76.9827 74.5642 71.8803 74.5642 65.5861C74.5642 59.2919 69.4618 54.1895 63.1676 54.1895C56.8734 54.1895 51.7709 59.2919 51.7709 65.5861C51.7709 71.8803 56.8734 76.9827 63.1676 76.9827Z"
              fill="white"
              stroke="#EFEFEF"
              stroke-width="1.7095"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.3043 66.4755C63.2246 66.4642 63.122 66.4642 63.0308 66.4755C61.025 66.4072 59.4295 64.7661 59.4295 62.7488C59.4295 60.686 61.0934 59.0107 63.1676 59.0107C65.2304 59.0107 66.9057 60.686 66.9057 62.7488C66.8943 64.7661 65.3102 66.4072 63.3043 66.4755Z"
              stroke="#EFEFEF"
              stroke-width="1.7095"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M70.8489 73.996C68.8203 75.8536 66.1307 76.9819 63.1676 76.9819C60.2045 76.9819 57.5149 75.8536 55.4863 73.996C55.6002 72.9247 56.284 71.8762 57.5035 71.0556C60.6261 68.9815 65.7318 68.9815 68.8317 71.0556C70.0512 71.8762 70.735 72.9247 70.8489 73.996Z"
              stroke="#EFEFEF"
              stroke-width="1.7095"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="35.8156"
              y="48.4922"
              width="3.41899"
              height="3.41899"
              rx="1.13966"
              fill="#EFEFEF"
            />
            <rect
              x="35.8156"
              y="56.4697"
              width="3.41899"
              height="3.41899"
              rx="1.13966"
              fill="#EFEFEF"
            />
            <rect
              x="46.0726"
              y="48.4922"
              width="3.41899"
              height="3.41899"
              rx="1.13966"
              fill="#2EB67D"
            />
            <rect
              x="46.0726"
              y="56.4697"
              width="3.41899"
              height="3.41899"
              rx="1.13966"
              fill="#EFEFEF"
            />
            <rect
              x="56.3296"
              y="48.4922"
              width="3.41899"
              height="3.41899"
              rx="1.13966"
              fill="#EFEFEF"
            />
            <path
              d="M40.0402 24.7598V31.8998"
              stroke="#EFEFEF"
              stroke-width="2.03461"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M59.0802 24.7598V31.8998"
              stroke="#EFEFEF"
              stroke-width="2.03461"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3302 41.6357H69.7902"
              stroke="#EFEFEF"
              stroke-width="2.03461"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {team === "team" ? (
          <Typography
            className="text-gray-700 font-normal mt-2.5"
            size="lg"
            variant="p"
          >
            Your team time off status will be<br></br> shown here.
          </Typography>
        ) : (
          <Typography
            className="text-gray-700 font-normal mt-2.5"
            size="lg"
            variant="p"
          >
            Your time off balances will be<br></br> shown here.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LeaveBalanceEmpty;
