import React, { useState } from "react";
import CustomLeaveTooltip from "./CustomLeaveTooltip";

export interface CustomTooltipProps {
  tooltipType?: "Holiday" | "Leave";

  children: React.ReactNode;

  trigger?: boolean;
  timeoffList: any;
  date: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  tooltipType,
  children,
  trigger,
  timeoffList,
  date,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsClicked(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    setIsHovered(false);
  };
  const filteredTimeOffList = timeoffList.filter(
    (t: any) => t.timeOffDate === date
  );
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className="inline-block w-full"
    >
      {children}
      {(trigger ? isHovered : isClicked) && filteredTimeOffList?.length > 0 && (
        <div className="z-[30] top-2 bg-white text-gray-300 px-3 pt-5 pb-2.5 mx-auto min-w-[166px] rounded-md absolute right-full border border-gray-400 text-left"  style={{
             boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.12)",
           }}>
          {filteredTimeOffList.map((t: any) => (
            <CustomLeaveTooltip data={t}/>
            // <div
            //   key={t.timeOffDate}
            //   className="bg-white text-gray-300 p-2.5 min-w-[140px] rounded-md text-200 mx-auto"
            //   style={{
            //     filter:
            //       "drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.07))",
            //   }}
            // >
            //   {t.timeOffType === "Holiday" && (
            //     <div className="fixed-holiday">
            //       <div className="">
            //         <div className="px-2 py-1 bg-secondary-100 text-white text-100 rounded-full font-normal flex justify-center items-center">
            //           {t.timeOffModeType}{" "}
            //           {/* <span className="font-bold ms-1">
            //             {t.approvedTimeOff}d
            //           </span> */}
            //         </div>
            //         <div className="uppercase font-normal text-[9px] text-gray-500 mt-1">
            //           {t.title}
            //         </div>
            //       </div>
            //     </div>
            //   )}
            //   {t.timeOffType === "Leave" && (
            //     <div className="casual-leave">
            //       <div className="px-2 py-1 bg-green-100 text-white text-100 rounded-full font-normal">
            //         {t.timeOffModeType}{" "}
            //         {/* <span className="font-bold ms-1">{t.approvedTimeOff}d</span> */}
            //       </div>
            //       <div className="uppercase font-normal text-[9px] text-gray-500 mt-1 flex justify-center items-center">
            //         <span className="me-1">
            //           <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width="12"
            //             height="12"
            //             viewBox="0 0 12 12"
            //             fill="none"
            //           >
            //             <path
            //               d="M6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12Z"
            //               fill="#2EB67D"
            //             />
            //             <path
            //               d="M3.4502 6.00006L5.1482 7.69806L8.5502 4.30206"
            //               fill="#2EB67D"
            //             />
            //             <path
            //               d="M3.4502 6.00006L5.1482 7.69806L8.5502 4.30206"
            //               stroke="white"
            //               strokeWidth="1.5"
            //               strokeLinecap="round"
            //               strokeLinejoin="round"
            //             />
            //           </svg>
            //         </span>
            //         Approved
            //       </div>
            //     </div>
            //   )}
            // </div>
          ))}
          {/* <div className="h-3 w-3 bottom-auto -top-3 translate-y-1/2 left-0 right-0 bg-white -rotate-45 absolute mx-auto"></div> */}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
