import React from "react";
import styled from "styled-components";

export const EchoLoader = styled.div`
  .loader-container {
    width: 100%;
    height: calc(100vh - 15rem);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
  }

  .spinner {
    width: 4rem;
    height: 4rem;
    border: 0.5rem solid;
    border-color: #f15845 transparent #f15845 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
  }

  @keyframes spin-anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EchopulseLoader = () => {
  return (
    <EchoLoader>
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    </EchoLoader>
  );
};

export default EchopulseLoader;
