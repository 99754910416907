import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useAppDispatch } from "../../../hooks";
import { cancelTimeoffAsync } from "../../../services/reducers/leaveReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";

type Props = {
  onClose: () => void;
  timeOffId: string;
  orgnisationList: () => void;
  setDeleteConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setTimeOffDetails: React.Dispatch<React.SetStateAction<boolean>>;
  updateNote:string;
};
const AdminDeleteConfirmModal: React.FC<Props> = ({
  onClose,
  timeOffId,
  orgnisationList,
  setDeleteConfirm,
  setTimeOffDetails,
  updateNote
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    if (timeOffId) {
      const reqBody: any = [
        {
          path: "/isLeaveCancelled",
          op: "replace",
          value: true,
        },
        {
          path: "/adminNote",
          op: "replace",
          value: updateNote
      }
      ];

      dispatch(cancelTimeoffAsync({ id: timeOffId, reqBody })).then(() => {
        orgnisationList();
        toast.success(`Leave request canceled successfully!`, TOASTIFY_CONF);
        setDeleteConfirm(false);
        setTimeOffDetails(false);
      });
    }
  };
  return (
    <Modal
      isOpen
      onClose={onClose}
      size="normal"
      placement="center"
      className="z-[9999]"
    >
      <ModalBody className="!ps-10 !py-10 !pe-16">
        <div className="pb-12">
          <Typography variant="h3" className="font-bold text-gray-300">
            Are you sure you want to Cancel this Time Off Request?
          </Typography>
          <Typography
            className="text-gray-700 font-normal mt-2"
            size="xxl"
            variant="p"
          >
           Please note that this action once taken cannot be reversed. Proceed to cancel leave request?
          </Typography>
        </div>
      </ModalBody>
      <ModalFooter className="bg-gray-200">
        <div className="w-full flex justify-end items-end gap-3">
          <Button
            label="Cancel"
            onClick={onClose}
            variant="outline"
            size="large"
          />
          <Button
            label="Yes, I am sure"
            onClick={() => handleDelete()}
            variant="primary"
            size="large"
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdminDeleteConfirmModal;
