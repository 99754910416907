import React from "react";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import jiraImage from "../../images/jira.png";
import echoPluseImage from "../../images/echopluse.png";
import clickupImage from "../../images/clickup.png";
import TimeSpentTaskEmpty from "./TimeSpentTaskEmpty";
import {
  convertMinutesToHoursAndMinutes2,
  formatTime,
} from "../../utils/common";

type Props = {
  timeSpentTasks: any;
};
const TimeSpentTask: React.FC<Props> = ({ timeSpentTasks }) => {
  return (
    <div className="border border-gray-100 shadow-300 py-6 bg-white rounded-md relative mt-6 h-[24.1rem]">
      <div className="flex justify-between items-center relative px-6 mb-2">
        <Typography variant="h5" className="text-gray-300 font-bold">
          Weekly Task Time Summary
        </Typography>
      </div>
      <div className="h-[calc(100%-2rem)] px-6 overflow-auto">
        {timeSpentTasks?.length > 0 ? (
          <>
            {timeSpentTasks.map((t: any) => {
              const availableClasses = [
                "after:bg-green-100",
                "after:bg-primary-100",
                "after:bg-yellow-100",
                "after:bg-error-200",
              ];
              const randomIndex = Math.floor(
                Math.random() * availableClasses.length
              );
              const randomClass = availableClasses[randomIndex];
              if (t.spentHours > 0) {
                return (
                  <div
                    key={t?.taskKey}
                    className="flex justify-between items-center border-b border-gray-400 pt-4 pb-[1.125rem]"
                  >
                    <div className="relative after:h-9 after:w-[0.188rem] after:bg-green-100 after:absolute after:-start-6 after:top-1/2 after:-translate-y-1/2 after:rounded-r-md w-[calc(100%-6.7rem)]">
                      {/* <div className={`relative after:h-full after:w-[0.188rem] ${randomClass} after:absolute after:-start-6 after:top-0 after:rounded-r-md`}> */}
                      <div className="flex justify-start items-start">
                        {t?.platform === "Jira" ? (
                          <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                            <img
                              src={jiraImage}
                              alt="jiraImage"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        ) : t?.platform === "EchoPulse" ? (
                          <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                            <img
                              src={echoPluseImage}
                              alt="jiraImage"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        ) : (
                          <div className="w-[1.063rem] h-[1.063rem] overflow-hidden mt-1">
                            <img
                              src={clickupImage}
                              alt="clickupImage"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}

                        <div className="ms-2 w-[calc(100%-1.5rem)]">
                          <span className="text-400 font-bold text-gray-300 line-clamp-1">
                            {t?.summary}
                          </span>
                          <div className="text-200 font-normal text-gray-700 mt-1 flex">
                            <span className="line-clamp-1 max-w-[80%]">
                              {t?.project?.key} - {t?.project?.name}
                            </span>
                            <span className="ms-2.5 ps-2.5 border-l border-gray-700 line-clamp-1">
                              #{t?.taskKey}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-400 font-bold text-gray-300 ms-2 w-[6.2rem] text-right">
                      {convertMinutesToHoursAndMinutes2(t?.spentHours)}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
            {timeSpentTasks.every((t: any) => t.spentHours === 0) && (
              <TimeSpentTaskEmpty />
            )}
          </>
        ) : (
          <TimeSpentTaskEmpty />
        )}
      </div>
    </div>
  );
};

export default TimeSpentTask;
