import React, { useEffect, useRef, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import { Dropdown } from "../../../components/DropDown/DropDown";
import { getTeamsAsync } from "../../../services/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getProjectTeamMemberListAsync,
  getTeamMemberListAsync,
} from "../../../services/reducers/adminReducer";
import EchopulseLoader from "../../loader/EchopulseLoader";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import Pagination from "./Pagination";
import SearchProjects from "./SearchProjects";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import InviteMemberEmpty from "./InviteMemberEmpty";

type TeamOption = {
  value: string;
  label: string;
};

type Props = {
  onClose: () => void;
  taskId?: string;
  projectId?: string;
};

const InvitedMemberModal: React.FC<Props> = ({
  onClose,
  taskId,
  projectId,
}) => {
  const dispatch = useAppDispatch();
  const { teamsList } = useAppSelector((state: any) => state.app);
  const [optionsTeam, setOptionsTeam] = useState<TeamOption[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(true);
  const [teamMemberList, setTeamMemberList] = useState<any>([]);
  const [teamMemberCount, setTeamMemberCount] = useState<any>([]);
  const [sortByDesc, setSortByDesc] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("1");
  const [searchString, setSearchString] = useState<string>("");
  const [filterss, setFilterss] = useState(false);
  const isFetching = useRef(false);
  
  useEffect(() => {
    if (isFetching.current) return; 

    isFetching.current = true;
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item: any) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: "all",
            label: "All Teams",
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
          setSelectedTeam(optionsWithAll[0]);
          isFetching.current = false;
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item: any) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: "all",
          label: "All Teams",
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
        setSelectedTeam(optionsWithAll[0]);
        isFetching.current = false;
      }
    }
  }, []);

  const getTeamMembers = () => {
    setLoading(true);
    if (isFetching.current) return; 

    isFetching.current = true;
    if (taskId) {
      const request = {
        TaskId: taskId,
        Page: currentPage,
        PageSize: pageSize,
        Team:
          selectedTeam?.label && selectedTeam?.label !== "All Teams"
            ? selectedTeam?.label
            : "",
        IsOrderByDesc: sortByDesc,
        SearchString: searchString,
      };

      dispatch(getTeamMemberListAsync(request)).then((res) => {
        setTeamMemberList(res?.payload?.item2);
        setTeamMemberCount(res?.payload?.item1);
        setLoading(false);
        isFetching.current = false;
      });
    } else {
      const request = {
        ProjectId: projectId,
        Page: currentPage,
        PageSize: pageSize,
        Team:
          selectedTeam?.label && selectedTeam?.label !== "All Teams"
            ? selectedTeam?.label
            : "",
        IsOrderByDesc: sortByDesc,
        SearchString: searchString,
      };

      dispatch(getProjectTeamMemberListAsync(request)).then((res) => {
        setTeamMemberList(res?.payload?.item2);
        setTeamMemberCount(res?.payload?.item1);
        setLoading(false);
        isFetching.current = false;
      });
    }
  };

  const debouncedSearch = useDebounce(getTeamMembers, 1000);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTeam, sortByDesc, searchString]);

  useEffect(() => {
    debouncedSearch();
  }, [selectedTeam, sortByDesc, searchString, currentPage, pageSize]);

  const handleSortChange = (selectedOption: any) => {
    setFilterss(true);
    const value = selectedOption.value;
    setSelectedValue(value);
    setCurrentPage(1);
    setSortByDesc(value === "2");
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <ModalHeader className="bg-gray-200 w-full">
        <div className="flex justify-between items-center w-full">
          <div className="">
            <Typography className="font-bold" variant="h3">
              Members
            </Typography>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="ps-20 pe-10 pb-10">
        <div className="">
          <div className="flex justify-between items-center pt-10 pb-6 sticky left-0 top-0 bg-white z-10">
            <div className="w-[20rem]">
              <SearchProjects
                title="teamMembers"
                searchString={searchString}
                setSearchString={setSearchString}
              />
            </div>
            <div className="flex justify-end items-center gap-3">
              <div className="w-[6.5rem]">
                <Dropdown
                  className=""
                  onChange={handleSortChange}
                  placeholder="Sort By"
                  value={{
                    label: sortByDesc ? "Z - A" : "A - Z",
                    value: selectedValue,
                  }}
                  options={[
                    {
                      label: "A - Z",
                      value: "1",
                    },
                    {
                      label: "Z - A",
                      value: "2",
                    },
                  ]}
                  type="smbox"
                />
              </div>
              <div className="w-[9rem] pe-2">
                <Dropdown
                  className=""
                  onChange={(selectedOption) => {
                    setSelectedTeam(selectedOption);
                  }}
                  placeholder="Select"
                  options={optionsTeam}
                  type="smbox"
                  value={selectedTeam}
                />
              </div>
              <Pagination
            currentPage={currentPage}
            itemsPerPage={pageSize}
            totalItems={teamMemberCount}
            onPageChange={handlePageChange}
            setFilterss={setFilterss}
            filterss={filterss}
          />
            </div>
          </div>
          <table className="w-full">
            <thead className="sticky left-0 top-[6.5rem] bg-white">
              <tr>
                <th className="w-[45%] text-left text-gray-700 text-400 font-normal pb-2 pe-4">
                  Name
                </th>
                <th className="w-[35%] text-left text-gray-700 text-400 font-normal pb-2 pe-4">
                  Team
                </th>
                <th className="w-[20%] text-left text-gray-700 text-400 font-normal pb-2 pe-4">
                  Designation
                </th>
              </tr>
            </thead>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <EchopulseLoader />
                </td>
              </tr>
            ) : teamMemberList?.length > 0 ? (
              teamMemberList.map((t: any) => (
                <tr>
                  <td className="text-gray-300 font-medium text-400 py-4 pe-4 border-b border-gray-400">
                    <div className="flex justify-start items-center gap-2">
                      <div className="w-8 h-8 overflow-hidden rounded-full">
                        {t?.profilePic ? (
                          <UserImage
                            imgKey={t?.profilePic}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <img
                            src={getInitialsInName(t?.name ?? "N A", 24)}
                            alt={t?.name}
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      <div className="line-clamp-2">{t?.name}</div>
                    </div>
                  </td>
                  <td className="text-gray-300 font-medium text-400 py-4 border-b border-gray-400 pe-4">
                    <div className="line-clamp-2">{t?.team}</div>
                  </td>
                  <td className="text-gray-300 font-medium text-400 py-4 border-b border-gray-400">
                    <div className="line-clamp-2">{t?.designation}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <InviteMemberEmpty />
                </td>
              </tr>
            )}
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvitedMemberModal;
