import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/Modal";
import { ModalFooter } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import RadioButton from "../../../components/Radio button/RadioButton";
import MultiSelectDropdown from "../../../components/MultiSelectWithCheckbox/MultiSelect";
import { SearchMember } from "./SearchMember";
import Icons from "../../../components/Icons/Icon";
import { Dropdown } from "../../../components/DropDown/DropDown";
import Textarea from "../../../components/TextArea/Textarea";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  ErrorMessage,
  FieldProps,
} from "formik";
import * as Yup from "yup";
import { getUsersAsync } from "../../../services/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  checkUniquenessAsync,
  createProjectAsync,
  createTaskAsync,
  getProjectByIdAsync,
  getProjectTeamMemberListAsync,
  getTaskAsync,
  getTeamMemberListAsync,
} from "../../../services/reducers/adminReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import { CustomDateTimePicker } from "../../../components/DateTimePicker/DatePicker";
import { convertHourMinuteToMinutes, formatDate } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import styled from "styled-components";

export const CustomRightToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    cursor: default;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 15rem;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    color: #232323;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    z-index: 1;
    top: -0.313rem;
    left: 104%;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type Values = {
  taskSummary: string;
  description: string;
  timeEstimate: string;
  dueDate: string;
  privacy: string;
  platform: number;
  project: {
    id: string;
    externalId: string;
    key: string;
    name: string;
    description: string;
    projectType: number;
    platform: number;
  };
  teamMembers: { memberId: string }[];
};
const TaskSchema = Yup.object().shape({
  taskSummary: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

type Props = {
  onClose: () => void;
  userList: any;
  setAddTask: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  getTask?: () => void;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  fetchUserListCustomRequest?: boolean;
  userProfile: any;
};


const AddProjectTaskModal: React.FC<Props> = ({
  onClose,
  userList,
  setAddTask,
  projectId,
  getTask,
  searchString,
  setSearchString,
  fetchUserListCustomRequest,
  userProfile,
}) => {
  const dispatch = useAppDispatch();
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const [projectData, setProjectData] = useState<any>({});
  const [userLists, setUserLists] = useState<any>(null);
  const [isUniqueTitle, setIsUniqueTitle] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isFetching = useRef(false);
  
  const removeSelectedMember = (memberIdToRemove: any) => {
    setSelectedMember((prevSelectedMembers: any) =>
      prevSelectedMembers.filter(
        (member: any) => member.identityId !== memberIdToRemove
      )
    );
  };

  useEffect(() => {
    if (isFetching.current) return; 

    isFetching.current = true;
    if (projectId) {
      dispatch(getProjectByIdAsync(projectId)).then((res) => {
        if (res.payload) {
          setProjectData(res.payload?.records[0]);
          isFetching.current = false;
        }
      });
    }
  }, []);

  const search = () => {
    if (isFetching.current) return; 
    isFetching.current = true;
    const request = {
      ProjectId: projectId,
      Page: currentPage,
      PageSize: 2000,
      Team: "",
      IsOrderByDesc: false,
    };
    dispatch(getProjectTeamMemberListAsync(request)).then((res) => {
      setUserLists(res?.payload?.item2);
      isFetching.current = false;
    });
  };

  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [searchString]);

  const validateTitle = async (title: string) => {
    if (title.trim() !== "") {
      const jsonData = {
        projectId: projectId,
        propertyName: "title",
        checkValue: title,
      };

      await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setTitleFocused(false);
      });
    }
  };

  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };
  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <Formik
        enableReinitialize
        initialValues={{
          taskSummary: "",
          description: "",
          dueDate: "",
          privacy: "1",
          timeEstimate: "",
          platform: 2,
          project: {
            id: "",
            externalId: "",
            key: "",
            name: "",
            description: "",
            projectType: 0,
            platform: 2,
          },
          teamMembers: [
            {
              memberId: "",
            },
          ],
        }}
        validationSchema={TaskSchema}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          setIsSubmitting(true);
          let request: any = values;
          const timeEstimateValue = convertHourMinuteToMinutes(
            values.timeEstimate
          );
          const privacyValue = values.privacy === "0" ? 0 : 1;
          const members = values.privacy === "0" ? selectedMember : userLists;
          request = {
            ...request,
            privacy: privacyValue,
            timeEstimate: timeEstimateValue,
            platform: 2,
            project: {
              id: projectData?.id,
              externalId: "",
              key: projectData?.key,
              name: projectData?.name,
              description: projectData?.description,
              projectType: projectData?.projectType,
              platform: 2,
            },
            teamMembers: members.map((member: any) => ({
              memberId: member.identityId,
            })),
          };
          const result = await dispatch(createTaskAsync(request));
          setAddTask(false);
          getTask && getTask();
          toast.success(`Task Created Successfully!`, TOASTIFY_CONF);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          setFieldTouched,
          submitForm,
          isValid,
          dirty,
        }) => {
          return (
            <Form className="w-full flex flex-col h-full">
              <ModalHeader className="bg-gray-200">
                <Typography className="font-bold" variant="h3">
                  Add Task
                </Typography>
              </ModalHeader>
              <ModalBody className="px-20 py-10">
                <div className="grid grid-cols-2 gap-x-5 ">
                  <div className="w-[25.313rem]">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Name <span className="text-primary-100">*</span>
                    </label>
                    <Field
                      name="taskSummary"
                      render={({ field }: any) => (
                        <>
                          <TextInput
                            {...field}
                            onChange={(event: any) => {
                              setFieldValue("taskSummary", event.target.value);

                              debouncedValidateTitle(event.target.value);
                            }}
                            onBlur={() => setFieldTouched("taskSummary", true)}
                            placeholder="Enter task name"
                            value={field.value}
                            type="text"
                          />
                          {isUniqueTitle === true && (
                            <div className="text-primary-100 text-200 font-normal mt-1">
                              {"Task name already exists!"}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-8 gap-x-5">
                  <div className="w-[25.313rem] [&>div>textarea]:h-[5.313rem]">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Description{" "}
                      <span className="text-primary-100">*</span>
                    </label>
                    <Field
                      name="description"
                      render={({ field }: any) => (
                        <>
                          <Textarea
                            {...field}
                            onChange={(value: string) => {
                              setFieldValue("description", value);
                            }}
                            placeholder="Describe the Task, Objectives, and Expected Outcomes"
                            value={values.description}
                            resize
                          />

                          {errors.description && touched.description && (
                            <div className="text-primary-100 text-200 font-normal mt-1">
                              {errors.description}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="grid col-span-2 mt-6">
                  <div className="">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Visibility
                    </label>
                    <div className="flex justify-start items-center gap-10">
                      <Field
                        type="radio"
                        name="privacy"
                        value="1"
                        render={({ field }: any) => (
                          <RadioButton
                            label="
                            Project-Wide Access"
                            {...field}
                            onChange={(value: string) =>
                              setFieldValue("privacy", value)
                            }
                            selectedValue={values.privacy}
                          />
                        )}
                      />
                      <Field
                        type="radio"
                        name="privacy"
                        value="0"
                        render={({ field }: any) => (
                          <RadioButton
                            label="Restricted Access"
                            {...field}
                            onChange={(value: string) =>
                              setFieldValue("privacy", value)
                            }
                            selectedValue={values.privacy}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  {values.privacy === "0" && (
                    <div className="w-[25.313rem]">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Members
                      </label>
                      <SearchMember
                        userList={userList}
                        setSelectedMember={setSelectedMember}
                        selectedMember={selectedMember}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        fetchUserListCustomRequest={fetchUserListCustomRequest}
                      />

                      <div className="">
                        {selectedMember.map((t: any, index: number) => (
                          <div
                            key={t.id}
                            className={`inline-block ${
                              index >= 4 && !showAll ? "hidden" : ""
                            }`}
                          >
                            <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                              <span className="me-1.5">{t?.name}</span>
                              <a className="cursor-pointer"
                                onClick={() =>
                                  removeSelectedMember(t.identityId)
                                }
                              >
                                <Icons
                                  name="cross"
                                  variant="stroke"
                                  size={10}
                                />
                              </a>
                            </div>
                          </div>
                        ))}
                        {selectedMember.length > 4 && (
                          <div
                            className="inline-block text-center cursor-pointer px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
                            onClick={toggleShowAllMembers}
                          >
                            {showAll
                              ? "Collapse view"
                              : `+${selectedMember.length - 4} others`}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="w-[25.313rem] mt-6">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Add Time Estimate
                    </label>
                    {userProfile?.roleName === "Super Admin" ||
                    userProfile?.roleName === "Admin" ? (
                      <Field
                        name="timeEstimate"
                        render={({ field }: any) => (
                          <>
                            <TextInput
                              {...field}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              placeholder="Enter Estimate (e.g. 2h 30m)"
                              value={field.value}
                              type="string"
                            />
                            {errors.timeEstimate && touched.timeEstimate && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {errors.timeEstimate}
                              </div>
                            )}
                          </>
                        )}
                      />
                    ) : (
                      <CustomRightToolTip>
                        <div className="tooltip">
                          <div className="w-full">
                            <Field
                              name="timeEstimate"
                              render={({ field }: any) => (
                                <>
                                  <TextInput
                                    {...field}
                                    onBlur={field.onBlur}
                                    onChange={field.onChange}
                                    placeholder="Enter Estimate (e.g., 2h 45m)"
                                    value={field.value}
                                    type="string"
                                    className="!bg-gray-200 focus:!shadow-0 focus:!border-gray-100"
                                    readOnly
                                  />
                                  {errors.timeEstimate &&
                                    touched.timeEstimate && (
                                      <div className="text-primary-100 text-200 font-normal mt-1">
                                        {errors.timeEstimate}
                                      </div>
                                    )}
                                </>
                              )}
                            />
                          </div>
                          <span className="tooltiptext">
                            Time estimates can only be set by administrators.
                          </span>
                        </div>
                      </CustomRightToolTip>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-between items-center w-full">
                  <div className="flex justify-start gap-5">
                    <Button
                      label="Add Task"
                      // onClick={() => submitForm()}
                      variant="primary"
                      size="large"
                      isDisabled={
                        isSubmitting ||
                        !isValid ||
                        !dirty ||
                        isUniqueTitle === true
                      }
                    />
                    <Button
                      label="Discard Changes"
                      onClick={() => setAddTask(false)}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </div>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddProjectTaskModal;
