import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/rootReducer";
import appReducer from "./services/reducers/appReducer";
import adminReducer from "./services/reducers/adminReducer";
import teamsReducer from "./services/reducers/teamsReducer";
import leaveReducer from "./services/reducers/leaveReducer";

export const store = configureStore({
    reducer: {
        app: appReducer,
        admin:adminReducer,
        teamDashboard:teamsReducer,
        leave:leaveReducer
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;