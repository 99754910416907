import React, { useEffect, useState, useRef } from "react";
import Icons from "../Icons/Icon";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  placement?: "right" | "center" | "left";
  size?: "small" | "medium" | "large" | "normal" | "extramedium";
  children: React.ReactNode;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  showCloseButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  placement = "center",
  size = "medium",
  children,
  showCloseButton = true,
  className,
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const backgroundRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosed(true);
      onClose();
      document.body.classList.remove('modal-open');
    }, 500);
  };

  useEffect(() => {
    if (isOpen) {
      setIsClosed(false);
      document.body.classList.add('modal-open');
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          handleClose();
        }
      };
      document.addEventListener("keydown", handleEscape);
      return () => {
        document.removeEventListener("keydown", handleEscape);
        document.body.classList.remove('modal-open');
      };
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  if (isClosed) {
    return null;
  }

  if (!isOpen && !isClosing) {
    return null;
  }

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === backgroundRef.current) {
      handleClose();
    }
  };

  const handleCrossIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClose();
  };

  return (
    <div
      className={`fixed inset-0 w-full h-full flex z-[9999] 
      ${placement === "center" ? "items-center justify-center" : ""}
      ${placement === "right" ? "justify-end" : ""}
      ${className ? className : ""}
      `}
      ref={backgroundRef}
      onClick={handleBackgroundClick}
    >
      <div className="bg-black bg-opacity-50 absolute inset-0 w-full h-full"></div>
      <div
        className={`bg-white max-w-[100%] overflow-auto relative shadow-100 z-10 flex flex-col transition-all duration-200 delay-200
        ${placement === "center" ? "rounded-md !max-w-[90%] max-h-[90%]" : "h-full max-h-[100%]"}
        ${size === "small" ? "w-[30rem]" : ""}
        ${size === "normal" ? "w-[40rem]" : ""}
        ${size === "medium" ? "w-[61.88rem]" : ""}
        ${size === "large" ? "w-[90rem]" : ""}
        ${size === "extramedium" ? "w-[75rem]" : ""}
        ${isClosing ? "opacity-0" : ""}
        ${isClosing && placement === "center" ? "-translate-y-full" : ""}
        ${isClosing && placement === "right" ? "translate-x-full" : ""}
        ${isClosing && placement === "left" ? "-translate-x-full" : ""}
        `}
      >
        {showCloseButton && ( // Conditionally render close button
          <button
            title="close"
            onClick={handleCrossIconClick}
            className="absolute right-[1.5rem] top-4 p-4"
          >
            <Icons name="cross" variant="stroke" size={16} />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

interface ModalHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, className }) => {
  return (
    <div className={`pt-5 min-h-[5rem] pb-5 px-20 border-b border-gray-100 flex justify-start items-center ${className ? className : ""}`}>
      {children}
    </div>
  );
};

interface ModalBodyProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, className }) => {
  return <div className={`flex-grow overflow-auto ${className ? className : ""}`}>{children}</div>;
};

interface ModalFooterProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, className }) => {
  return <div className={`pt-5 min-h-[5rem] pb-5 px-20 border-t border-gray-100 flex justify-start items-center ${className ? className : ""}`}>{children}</div>;
};
