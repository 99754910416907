import React from "react";
import emptyOtherImage from "../../images/emptyOther.png";
import Typography from "../../components/Typography/Typography";

const TimeTrackerMonthlyEmpty = () => {
  return (
    <div className="flex justify-center items-center mt-8 h-[calc(100%-9.5rem)]">
      <div>
        <div className="flex justify-center items-center">
          <img
            src={emptyOtherImage}
            alt="emptyOtherImage"
            className="w-[6.563rem] h-[6.563rem]"
          />
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          No Tasks Yet. Begin by Adding <br></br> Your First Task to Start
          Tracking.
        </Typography>
      </div>
    </div>
  );
};

export default TimeTrackerMonthlyEmpty;
