import React, { useEffect, useRef, useState } from "react";
import EditProjectTaskModal from "./EditProjectTaskModal";
import {
  closeTaskAsync,
  deleteTaskAsync,
  getTaskAsync,
  getTaskByIdAsync,
} from "../../../services/reducers/adminReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ModalDelete from "../../TimeTracker/ModalDeletePopup";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Notification from "../../../components/Notification/Notification";
import Icons from "../../../components/Icons/Icon";
interface ActionCustomDropdownProps {
  onSelect: (action: string, id?: string) => void;
  id?: string;
  userList?: any;
  projectData?: any;
  taskList?: any;
  getTask?: () => void;
  projectId?: string;
  listTypeValue?: string;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedMember?: any;
  selectedMember?: any;
  fetchUserListCustomRequest?: boolean;
  userProfile?: any;
}

const ActionCustomDropdown: React.FC<ActionCustomDropdownProps> = ({
  onSelect,
  id,
  userList,
  projectData,
  taskList,
  getTask,
  projectId,
  listTypeValue,
  searchString,
  setSearchString,
  setSelectedMember,
  selectedMember,
  fetchUserListCustomRequest,
  userProfile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditTaskModalOpen, setEditTaskModalOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [taskData, setTaskData] = useState<any>(null);
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isConfirmationVisible1, setConfirmationVisible1] = useState(false);
  const [type, setType] = useState("");
  const { taskLists } = useAppSelector((state: any) => state.admin);
  const navigate = useNavigate();
  const [isConfirmationVisible2, setConfirmationVisible2] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setEditTaskModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (action: string) => {
    if (!id) {
      return;
    }
    onSelect(action, id);
    setIsOpen(false);
    setTaskId(id);

    if (
      action === "editTask" ||
      action === "closeTask" ||
      action === "deleteTask" ||
      action === "copyTask"
    ) {
      dispatch(getTaskByIdAsync(id)).then((res) => {
        if (res.payload) {
          const getData = res.payload?.records[0];
          setTaskData(res.payload?.records[0]);
          handleAction(action, getData);
        }
      });
    }
  };

  const handleAction = (action: string, getData: any) => {
    onSelect(action, id || "");
    setIsOpen(false);
    setTaskId(id || "");

    if (action === "editTask") {
      setType("edit");
      setEditTaskModalOpen(true);
    } else if (action === "closeTask") {
      setConfirmationVisible1(true);
    } else if (action === "deleteTask") {
      if (getData?.isTimeLoged === false) {
         setConfirmationVisible(true);
      } else {
        toast.error(
          `Delete failed. Time entries logged for task.`,
          TOASTIFY_CONF
        );
        // setConfirmationVisible2(true);
      }
    } else if (action === "copyTask") {
      setType("clone");
      setEditTaskModalOpen(true);
    } else {
      setEditTaskModalOpen(false);
    }
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icons name="dot-action" variant="stroke" size={24} className="[&>svg]:rotate-90" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right top-4 absolute right-0 mt-2 w-[9rem] shadow-lg bg-white border border-gray-100 rounded-md rounded-t-0 shadow-200 z-10">
          <div className="">
            {listTypeValue === "all" && (
              <>
                <button
                  className="w-full flex items-center px-2.5 py-2 gap-2.5 text-gray-300 text-300 font-normal hover:bg-gray-200"
                  onClick={() => handleSelect("editTask")}
                >
                  <Icons name="edit-2" size={20} />
                  Edit Task
                </button>

                <button
                  className="w-full flex items-center px-2.5 py-2 gap-2.5 text-gray-300 text-300 font-normal hover:bg-gray-200"
                  onClick={() => handleSelect("closeTask")}
                >
                  <Icons name="round-cross" size={20} />
                  Close Task
                </button>
              </>
            )}
            <button
              className="w-full flex items-center px-2.5 py-2 gap-2.5 text-gray-300 text-300 font-normal hover:bg-gray-200"
              onClick={() => handleSelect("copyTask")}
            >
              <Icons name="copy" size={20} />
              Copy Task
            </button>
            {listTypeValue === "all" &&
              userProfile?.roleName !== "Employee" && (
                <button
                  className="w-full flex items-center px-2.5 py-2 gap-2.5 text-gray-300 text-300 font-normal rounded-b-md hover:bg-gray-200"
                  onClick={() => handleSelect("deleteTask")}
                >
                  <Icons name="delete-2" size={20} />
                  Delete Task
                </button>
              )}
          </div>
        </div>
      )}
      
      {isEditTaskModalOpen && (
        <EditProjectTaskModal
          onClose={() => setEditTaskModalOpen(false)}
          setEditTask={setEditTaskModalOpen}
          userList={userList}
          id={taskId}
          taskData={taskData}
          type={type}
          getTask={getTask}
          searchString={searchString}
          setSearchString={setSearchString}
          listTypeValue={listTypeValue}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          fetchUserListCustomRequest={fetchUserListCustomRequest}
          projectId={projectId}
          userProfile={userProfile}
        />
      )}

      {isConfirmationVisible && (
        <ModalDelete
          onCancel={() => {
            setConfirmationVisible(false);
            setTaskId("");
          }}
          onConfirm={(requestType) => {
            if (requestType === "Yes") {
              dispatch(deleteTaskAsync(taskId)).then((res) => {
                toast.success(`Task Deleted Successfully!`, TOASTIFY_CONF);
                getTask && getTask();
              });
            }
            setConfirmationVisible(false);
            setTaskId("");
          }}
          chldLable="Are you sure you want to delete this Task? "
        />
      )}

      {isConfirmationVisible1 && (
        <ModalDelete
          onCancel={() => {
            setConfirmationVisible1(false);
            setTaskId("");
          }}
          onConfirm={(requestType) => {
            if (requestType === "Yes") {
              dispatch(closeTaskAsync(taskId)).then((res) => {
                toast.success(`Task Closed Successfully!`, TOASTIFY_CONF);
                getTask && getTask();
              });
            }
            setConfirmationVisible1(false);
            setTaskId("");
          }}
          chldLable="Are you sure you want to close this Task? "
        />
      )}

      {isConfirmationVisible2 && taskData?.isTimeLoged === true && (
        <Notification
          type="warning"
          message="Delete failed. Time entries logged for task."
          onClose={() => {
            setConfirmationVisible2(false);
          }}
        />
      )}
    </div>
  );
};

export default ActionCustomDropdown;
